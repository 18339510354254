import { ApolloError } from '@apollo/client';
import useModal from '@area2k/use-modal';
import {
  faDownload,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import InvoiceDetailsPdf from '../../InvoiceDetailsPdf';
import InvoicePayCheckoutModal from '../modals/InvoicePayCheckoutModal';
import ProcessingPaymentIntentModal from '../modals/ProcessingPaymentIntentModal';
import SuccessInvoicePayModal from '../modals/SuccessInvoicePayModal';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Body, Small } from '@/components/Typography';
import { UNKNOWN_ERROR_TEXT } from '@/constants/text';
import Icon from '@/elements/Icon';
import Link from '@/elements/Link';
import {
  GetInvoiceDetailsRevampQueryResult,
  useCreatePaymentIntentMutation,
  useInvoicePayMutation,
} from '@/graphql';
import { Maybe } from '@/types';
import { GetInvoiceQuery, Scalars } from '@/types/graphql';
import { centsToCurrency } from '@/util/number';
import {
  checkIfCurrentPaymentMethodIdIsBankAccount,
  checkIfCurrentPaymentMethodIdIsTestAccountToFail,
} from '@/util/payments';
import { capitalizeFirstLetter } from '@/util/text';

interface Props {
  showPayButton: Scalars['Boolean'];
  invoice: GetInvoiceQuery['invoice'];
  refetch: GetInvoiceDetailsRevampQueryResult['refetch'];
  adustUI?: boolean;
  handleSuccess?: (isBankPayment: boolean) => void;
  handleFailPayment?: () => void;
  invoiceItems?: any;
  paymentMethod?: string;
}

type InvoicePayCheckoutModalProps = {
  clientSecret: Scalars['String'];
  paymentMethodId: Scalars['String'];
};

const InvoicePayCard = ({
  showPayButton,
  invoice,
  invoiceItems,
  paymentMethod = '',
  refetch,
  adustUI = false,
  handleSuccess,
  handleFailPayment,
}: Props) => {
  const [checkoutModalProps, setCheckoutModalProps] =
    useState<Maybe<InvoicePayCheckoutModalProps>>(null);
  const [error, setError] =
    useState<Maybe<{ title: Scalars['String']; message?: Scalars['String'] }>>(
      null,
    );
  const navigate = useNavigate();
  const { search } = useLocation();

  const [payInvoice, { loading: payInvoiceIsLoading }] = useInvoicePayMutation({
    variables: { invoiceId: invoice.id },
    errorPolicy: 'all',

    update: (cache) => {
      cache.modify({
        id: cache.identify(invoice),
        fields: {
          status() {},
        },
      });
    },
  });

  const [createPaymentIntent, { loading: createPaymentIntentIsLoading }] =
    useCreatePaymentIntentMutation({
      variables: { invoiceId: invoice.id },
    });
  const [showInvoicePayCheckoutModal, hideInvoicePayCheckoutModal] = useModal(
    ({ clientSecret, paymentMethodId }: InvoicePayCheckoutModalProps) => {
      return (
        <InvoicePayCheckoutModal
          clientSecret={clientSecret}
          hideModal={hideInvoicePayCheckoutModal}
          invoice={invoice}
          paymentMethodId={paymentMethodId}
          onCompleteCheckout={async () => {
            await refetch();
            showProcessingPaymentIntentModal();
          }}
        />
      );
    },
    [checkoutModalProps, invoice],
  );
  const [showProcessingPaymentIntentModal, hideProcessingPaymentIntentModal] =
    useModal(() => {
      return (
        <ProcessingPaymentIntentModal
          hideModal={() => {
            hideProcessingPaymentIntentModal();
            if (handleSuccess) {
              handleSuccess(true);
            } else {
              navigate('../' + search);
            }
          }}
        />
      );
    }, []);
  const [showSuccesInvoicePayModal, hideSuccesInvoicePayModal] =
    useModal(() => {
      return (
        <SuccessInvoicePayModal
          hideModal={() => {
            hideSuccesInvoicePayModal();
            navigate('../' + search);
          }}
          invoice={invoice}
        />
      );
    }, []);

  const cardTitle = `$${centsToCurrency(Number(invoice.amountDue))}`;
  const cardFilds = {
    to: invoice.defaultContactEmail,
    from: 'Gravy Staff Inc',
    invoiceNumber: `#${invoice.number}`,
    memo: invoice.description,
  };
  const handleOnClick = async () => {
    setError(null);
    try {
      let currentPaymentMethodId = invoice?.account?.currentPaymentMethod;
      if (!adustUI) {
        const { data: refetchedData } = await refetch({
          invoiceId: invoice.id,
        });
        currentPaymentMethodId =
          refetchedData.listStripeInvoiceDetail.invoice.account
            .currentPaymentMethod;
      }

      if (
        checkIfCurrentPaymentMethodIdIsTestAccountToFail(
          currentPaymentMethodId,
        ) ||
        checkIfCurrentPaymentMethodIdIsBankAccount(currentPaymentMethodId)
      ) {
        const { data } = await createPaymentIntent();

        setCheckoutModalProps({
          clientSecret: data?.paymentIntentCreate.clientSecret ?? '',
          paymentMethodId: currentPaymentMethodId ?? '',
        });
        return;
      }

      const payInvoiceData = await payInvoice();

      if (
        payInvoiceData?.errors?.length &&
        payInvoiceData?.errors[0]?.message
      ) {
        throw new Error(payInvoiceData.errors[0].message);
      } else if (handleSuccess) {
        handleSuccess(false);
      } else {
        showSuccesInvoicePayModal();
      }
    } catch (error) {
      setCheckoutModalProps(null);
      handleFailPayment?.();
      setError((prevValues) => {
        const defaultError = {
          ...prevValues,
          title: 'An error has occurred',
          message: UNKNOWN_ERROR_TEXT,
        };

        if (error instanceof ApolloError) {
          const gqlError = error.graphQLErrors[0];

          if (gqlError) {
            return {
              ...prevValues,
              title: gqlError.message,
              message: undefined,
            };
          }
        }

        return defaultError;
      });
    }
  };

  useEffect(() => {
    if (checkoutModalProps) {
      showInvoicePayCheckoutModal({
        clientSecret: checkoutModalProps.clientSecret,
        paymentMethodId: checkoutModalProps.paymentMethodId,
      });
    }
  }, [checkoutModalProps]);

  return (
    <>
      {error && (
        <Alert
          description={error.message}
          icon={faExclamationTriangle}
          status="danger"
          title={error.title}
        />
      )}
      {adustUI ? (
        <Button
          a11yLabel="Pay Now"
          id="pay-now-btn"
          isLoading={payInvoiceIsLoading || createPaymentIntentIsLoading}
          style={{ width: '100%' }}
          onClick={handleOnClick}
        />
      ) : (
        <Card actions={undefined} title={cardTitle}>
          <PDFDownloadLink
            document={
              <InvoiceDetailsPdf
                invoice={invoice}
                invoiceItems={invoiceItems}
                paymentMethod={paymentMethod}
              />
            }
            fileName={`invoice-${invoice.number}.pdf`}
          >
            {() => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  paddingRight: '22px',
                }}
              >
                <Icon
                  css={{ top: '-17px' }}
                  icon={faDownload}
                  size={'lg'}
                  theme={'theme'}
                />
              </div>
            )}
          </PDFDownloadLink>

          <Card.Section>
            <Stack align="start">
              <Stack vertical>
                {Object.entries(cardFilds).map(([key, value], index) =>
                  value ? (
                    <Stack key={`invoice-pay-card-field-${index}`}>
                      <Small>{capitalizeFirstLetter(key)}</Small>
                      <Body>{value}</Body>
                    </Stack>
                  ) : null,
                )}
              </Stack>
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack justify="apart">
              <Link to={`../${search}`}>View Invoice Details</Link>
              {showPayButton && (
                <Button
                  a11yLabel="Pay Invoice"
                  isLoading={
                    payInvoiceIsLoading || createPaymentIntentIsLoading
                  }
                  label="Pay Invoice"
                  onClick={handleOnClick}
                />
              )}
            </Stack>
          </Card.Section>
        </Card>
      )}
    </>
  );
};

export default InvoicePayCard;
