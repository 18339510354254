import styled from '@/styles';

export const Container = styled('div', {
  display: 'flex',
  backgroundColor: 'white',
  padding: '10px',
  cursor: 'pointer',
  width: '100%',
  borderBottom: '1px solid #ECECEC',
  '@media (max-width: 559px)': {
    padding: '16px 9px',
  },
});

export const LeftContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  height: '24px',
  width: '10%',
  flexShrink: 0,
  '@media (max-width: 559px)': {},
});

export const MidContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
  padding: '0px 10px',
  gap: '5px',
  '@media (max-width: 559px)': {},
});

export const UpperContainer = styled('div', {
  display: 'flex',
  width: '100%',
  height: '24px',
  alignItems: 'center',
  gap: '10px',
});

export const RightContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  height: '24px',
  width: '10%',
  '@media (max-width: 559px)': {},
});
export const RedDot = styled('div', {
  backgroundColor: '$dangerDefault',
  width: '8px',
  height: '8px',
  borderRadius: '9999px',
  flexShrink: 0,
});

export const PlaceholderDot = styled('div', {
  width: '8px',
  flexShrink: 0,
  height: '8px',
});

export const BlueDot = styled('div', {
  backgroundColor: '#296bec',
  width: '8px',
  height: '8px',
  borderRadius: '9999px',
  flexShrink: 0,
});

export const RoundIconWrapper = styled('div', {
  backgroundColor: '#E0EBFF',
  width: '24px',
  height: '24px',
  borderRadius: '9999px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexShrink: 0,
});

export const Title = styled('p', {
  color: '#302F2A',
  'font-size': '12px',
  'font-weight': '700',
  maxWidth: '87%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '@media (max-width: 559px)': {
    color: '#262626',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: 'normal',
  },
});

export const Subtitle = styled('p', {
  color: '#302F2A',
  fontSize: '12px',
  fontWeight: '400',
  '@media (max-width: 559px)': {
    color: '#334155',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
});

export const DateText = styled('p', {
  color: '#979694',
  fontSize: '11px',
  fontWeight: '400',
  '@media (max-width: 559px)': {
    color: '#475569',
    fontSize: '12px',
    fontWeight: '400px',
    lineHeight: '18px',
  },
});

export const IconStyle = {
  fontSize: '14px',
};

export const BadgeStyle = {
  borderRadius: '8px',
  marginLeft: '5px',
};
