import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { ComponentProps, FC } from 'react';

import Card from '@/components/Card';
import IconicButton from '@/components/IconicButton';
import SingleColumnLayout from '@/components/SingleColumnLayout';
import { MIN_WORDS_TO_CAPITALIZE } from '@/constants/general';
import Overlay from '@/elements/Overlay';
import useClickout from '@/hooks/useClickout';
import useDisableBodyScroll from '@/hooks/useDisableBodyScroll';
import { capitalizeText } from '@/util/text';

type Props = {
  disableClickout?: boolean;
  size?: ComponentProps<typeof SingleColumnLayout>['size'];
  title?: string;
  onRequestClose?: () => void;
  wrapperBackground?: boolean;
  modalPadding?: string;
  layoutCss?: Record<string, string | number>;
  modalHeader?: React.ReactNode;
  headerNobg?: boolean;
  headerSpacing?: boolean;
  displayFlex?: boolean;
  noHeader?: boolean;
};

const Modal: FC<Props> = ({
  children,
  disableClickout = false,
  size = 'md',
  title,
  onRequestClose,
  wrapperBackground = false,
  modalPadding = '72px 16px',
  layoutCss,
  modalHeader,
  headerNobg = false,
  noHeader = false,
  headerSpacing = false,
  displayFlex = false,
}) => {
  useDisableBodyScroll();

  const clickoutRef = useClickout<HTMLDivElement>(onRequestClose, {
    debugName: `Modal(${title})`,
    isActive: !disableClickout,
  });

  const titleWords = title?.split(' ');

  return (
    <Overlay
      aria-modal
      aria-label={title}
      data-testid="modal"
      role="dialog"
      onClick={() => {
        if (disableClickout === true && onRequestClose) {
          onRequestClose();
        }
      }}
    >
      <div
        style={
          displayFlex
            ? {
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
            : { padding: modalPadding }
        }
      >
        <SingleColumnLayout css={layoutCss} size={size}>
          <Card
            ref={clickoutRef}
            css={{ boxShadow: '$2' }}
            changeStyle={{ borderRadius: '10px' }}
            headerNobg={headerNobg}
            headerSpacing={headerSpacing}
            id="modal"
            onClick={(e) => {
              if (disableClickout === true) {
                e.stopPropagation();
              }
            }}
          >
            {modalHeader}
            {!modalHeader && !noHeader && (
              <Card.Header
                titleColor="themeDefault"
                headerNobg={headerNobg}
                headerSpacing={headerSpacing}
                justify={!title ? 'end' : 'apart'}
                title={
                  titleWords && titleWords.length < MIN_WORDS_TO_CAPITALIZE
                    ? capitalizeText(title!)
                    : title
                }
                wrapperBackground={wrapperBackground}
              >
                {onRequestClose && (
                  <IconicButton
                    a11yLabel="Close Modal"
                    appearance="clear"
                    data-testid="close-modal-btn"
                    icon={faTimes}
                    id="close-modal-btn"
                    size="sm"
                    type="button"
                    onClick={onRequestClose}
                  />
                )}
              </Card.Header>
            )}

            {children}
          </Card>
        </SingleColumnLayout>
      </div>
    </Overlay>
  );
};

export default Modal;
