import { FieldCallback, useField } from '@area2k/use-form';
import { ComponentProps } from 'react';

import FormElement from '@/components/FormElement';
import TextSelect from '@/components/TextSelect';

type TextSelectProps = Omit<
  ComponentProps<typeof TextSelect>,
  'id' | 'status' | 'value' | 'onChange'
>;
export type TextSelectOption = { label: string; value: string };

type Props = TextSelectProps & {
  fieldId: string;
  label?: string;
  marginBottom?: boolean;
  options: TextSelectOption[];
  placeholder?: string;
  callback?: FieldCallback<string>;
  defaultValue?: string;
  required?: boolean;
  showRequiredIndicator?: boolean;
};

const TextSelectField = ({
  fieldId,
  label,
  options,
  placeholder,
  defaultValue,
  callback = () => {},
  required = false,
  showRequiredIndicator = false,
  ...props
}: Props) => {
  const { value, setValue } = useField<string>(fieldId, callback);
  return (
    <FormElement
      htmlFor={fieldId}
      label={label}
      required={required}
      showRequiredIndicator={showRequiredIndicator}
    >
      <TextSelect
        {...props}
        id={fieldId}
        status={value === '' ? 'empty' : 'selected'}
        value={value || defaultValue}
        onChange={(ev) => setValue(ev.currentTarget.value)}
      >
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextSelect>
    </FormElement>
  );
};

export default TextSelectField;
