import { Link } from 'react-router-dom';

import { IS_QA } from '@/constants/env';
import styled from '@/styles';

export const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  height: 48,

  backgroundColor: IS_QA ? '$dangerDefault' : '#1a1a1a',
  boxShadow: '0 0 2px 2px $colors$neutralA12',
});

export const DropdownWrapper = styled('div', {
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
});

export const BellWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

export const RedDot = styled('div', {
  position: 'absolute',
  backgroundColor: '$dangerDefault',
  width: '8px',
  height: '8px',
  borderRadius: '9999px',
  top: '10px',
  right: '0px',
});

export const AvatarWrapper = styled('div', {});

export const Branding = styled(Link, {
  alignItems: 'center',
  display: 'flex',
  height: 48,
  justifyContent: 'center',
  width: 256,

  backgroundColor: 'transparent',
  color: 'white',
  outline: 'none',

  textDecoration: 'none',

  '&:hover, &:focus': {
    backgroundColor: '$whiteA8',
  },

  '&:focus-visible': {
    boxShadow: 'inset 0 0 1px 3px $colors$whiteA32',
  },

  '@touchable': {
    padding: ' 0 20px 0 0',
    width: 210,
  },
  '@phoneOnly': {
    display: 'none',
  },
  '@smallDesktop': {
    borderRight: '1px solid $colors$whiteA16',
  },
});

export const Profile = styled('button', {
  all: 'unset',
  alignItems: 'center',
  display: 'flex',
  height: 48,
  justifyContent: 'space-between',
  padding: '0 20px',

  backgroundColor: 'transparent',
  color: 'white',
  cursor: 'pointer',
  outline: 'none',

  textDecoration: 'none',

  '@phoneOnly': {
    gap: 10,
  },

  '&:hover': {
    backgroundColor: '$whiteA8',
  },
});

export const ProfileField = styled('div', {
  width: '128px',

  overflow: 'hidden',

  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  variants: {
    light: {
      true: {
        color: '$whiteA76',

        fontSize: '$sm',
        lineHeight: '14px',
      },
      false: {
        color: 'white',

        fontSize: '$md',
        lineHeight: '16px',
      },
    },
  },
});

export const BurgerMenu = styled('div', {
  display: 'none',
  backgroundColor: 'transparent',
  color: 'white',
  padding: '0 20px',

  '@touchable': {
    display: 'flex',
  },

  variants: {
    show: {
      true: {
        margin: '0 2.2px',
      },
    },
  },
});

export const NameContainer = styled('div', {
  display: 'flex',
  '@phoneOnly': {
    display: 'none',
  },
});

Wrapper.displayName = 'stitches(Navbar.Wrapper)';
Branding.displayName = 'stitches(Navbar.Branding)';
Profile.displayName = 'stitches(Navbar.Profile)';
