import styled from '@/styles';

export const TableBold = styled('p', {
  color: `#262626`,
  fontSize: '12px',
  fontWeight: '600',
  textTransform: 'capitalize',
  width: '100%',
  marginBottom: '5px',
});

export const TableDesc = styled('p', {
  color: '#7A7A7A',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '14px',
  width: '100%',
});

export const TableLink = styled('a', {
  color: '#4785FF',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '24px',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  overflow: 'hidden',
  wordBreak: 'break-all',
  whiteSpace: 'nowrap',
  width: '240px',
});

export const IconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  flex: 1,
});
