import { useReactiveVar } from '@apollo/client';
import { Keyboard } from '@capacitor/keyboard';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Role } from '../PrivateRoute';

import BottomNav from './BottomNav';
import MessageContainer from './MessageContainer';
import UserDefaultPubNubChannel from './UserDefaultPubNubChannel';

import AppFooter from '@/components/AppFooter';
import AppFrame from '@/components/AppFrame';
import AppNavbar from '@/components/AppNavbar';
import LoadingState from '@/components/LoadingState';
import Config from '@/config';
import { V3Endpoints } from '@/constants/urls';
import { useGetAgencyByKeyQuery } from '@/graphql';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import useMediaQuery from '@/hooks/useMediaQuery';
import Sidebar from '@/routes/Agency/Sidebar';
import {
  setKeyboardVisible,
  setPendingCustomers,
  setSidebarVisibility,
} from '@/store/slices/layoutSlice';
import {
  currentAgencyVar,
  currentTenantVar,
  currentAdminVar,
} from '@/util/apollo/cache';
import axiosClient from '@/util/axios/axiosClient';
import { CustomerCountResponse } from '@/util/axios/types';
import { isWeb } from '@/util/platform';

const Agency = () => {
  const dispatch = useAppDispatch();
  const currentTenant = useReactiveVar(currentTenantVar);
  const currentAgency = useReactiveVar(currentAgencyVar);
  const currentAdmin = useReactiveVar(currentAdminVar);
  const isSmallDesktop = useMediaQuery('(min-width: 900px)');

  const query = useGetAgencyByKeyQuery({
    variables: { tenantId: currentTenant!.id, key: 'gravy-dmv' },
  });

  const keyboardVisible = useAppSelector(
    (state) => state.layout.keyboardVisible,
  );
  useEffect(() => {
    if (query.data) {
      currentAgencyVar(query.data.agencyByKey);
    }
  }, [query.data]);

  const [pubnub, setPubnub] = useState();

  useEffect(() => {
    if (currentAdmin && !pubnub) {
      setPubnub(
        new PubNub({
          publishKey: Config.PUBNUB_PUBLISH_KEY,
          subscribeKey: Config.PUBNUB_SUBSCRIBE_KEY,
          userId: `${currentAdmin?.user.id}`,
          restore: true,
        }),
      );
    }
  }, [currentAdmin]);

  useEffect(() => {
    if (isSmallDesktop) {
      dispatch(setSidebarVisibility(true));
    } else {
      dispatch(setSidebarVisibility(false));
    }
  }, [isSmallDesktop]);

  useEffect(() => {
    if (!isWeb()) {
      dispatch(setKeyboardVisible(false));
      Keyboard.addListener('keyboardWillShow', () => {
        dispatch(setKeyboardVisible(true));
      });

      Keyboard.addListener('keyboardWillHide', () => {
        dispatch(setKeyboardVisible(false));
      });

      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, []);

  useEffect(() => {
    if (currentAdmin && currentAdmin!.role === Role.TENANT_ADMIN) {
      axiosClient
        .get<CustomerCountResponse>(V3Endpoints.GET_CUSTOMERS_COUNT, {
          params: { status: 0 },
        })
        .then(({ data }) => {
          if (data && data.count) {
            dispatch(setPendingCustomers(true));
          } else {
            dispatch(setPendingCustomers(false));
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(setPendingCustomers(false));
        });
    }
  }, [currentAdmin]);

  return pubnub ? (
    <PubNubProvider client={pubnub}>
      <UserDefaultPubNubChannel>
        <AppFrame
          BottomNav={!isWeb() && !keyboardVisible && <BottomNav />}
          Footer={<AppFooter />}
          Navbar={<AppNavbar />}
          Sidebar={<Sidebar />}
          isSmallDesktop={isSmallDesktop}
        >
          {currentAgency ? (
            <>
              <MessageContainer />
              <Outlet />
            </>
          ) : (
            <LoadingState text="Loading your agency's data..." />
          )}
        </AppFrame>
      </UserDefaultPubNubChannel>
    </PubNubProvider>
  ) : (
    <LoadingState text="Loading..." />
  );
};

export default Agency;
