import { Stack } from '@mui/material';

import InvoicePayCard from '../../InvoicePay/InvoicePayCard';

import { CreditCardIcon } from '@/assets/icons';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Text from '@/elements/Text';
import { GetInvoiceDetailsRevampQueryResult } from '@/graphql';
import {
  GetAccountPaymentMethodsQuery,
  GetInvoiceDetailsRevampQuery,
} from '@/types/graphql';
import { centsToCurrency } from '@/util/number';
import {
  checkIfCardIsCurrentPaymentMethod,
  formatCurrentPaymentMethod,
} from '@/util/payments';

type Props = {
  invoice: GetInvoiceDetailsRevampQuery['listStripeInvoiceDetail']['invoice'];
  hideModal: () => void;
  accountPaymentMethods: GetAccountPaymentMethodsQuery['accountPaymentMethods'];
  refetchInv: GetInvoiceDetailsRevampQueryResult['refetch'];
  handleSuccessPayment: (isBankPayment: boolean) => void;
  handleFailPayment: () => void;
};

const ConfirmPaymentModal = ({
  invoice,
  hideModal,
  refetchInv,
  accountPaymentMethods,
  handleSuccessPayment,
  handleFailPayment,
}: Props) => {
  const invNumber = `${invoice.id}-${invoice.number}`;
  const paymentMethod = accountPaymentMethods
    ? formatCurrentPaymentMethod(accountPaymentMethods)
    : '';
  const isCard = checkIfCardIsCurrentPaymentMethod(
    accountPaymentMethods.cards,
    invoice.account.currentPaymentMethod,
  );

  return (
    <Modal
      disableClickout
      size="xs"
      title="Confirm payment"
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Text>{`Are you sure you want to pay to ${invNumber}?`}</Text>
        <Stack gap={1} sx={{ my: 2.5 }}>
          <Stack direction={'row'} gap={6}>
            <Text color={'lighter'}>Amount Due:</Text>
            <Text weight={'semibold'}>
              ${centsToCurrency(Number(invoice.amountDue))}
            </Text>
          </Stack>
          <Stack direction={'row'} gap={2.2}>
            <Text color={'lighter'}>Payment Method:</Text>
            <Stack direction={'row'} gap={0.8}>
              {isCard && <CreditCardIcon />}
              <Text css={{ textTransform: 'capitalize' }} weight={'semibold'}>
                {paymentMethod}
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <div>
          <InvoicePayCard
            adustUI={true}
            handleFailPayment={handleFailPayment}
            handleSuccess={handleSuccessPayment}
            invoice={invoice}
            refetch={refetchInv as any}
            showPayButton={true}
          />
        </div>
      </Card.Section>
    </Modal>
  );
};

export default ConfirmPaymentModal;
