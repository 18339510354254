import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faBars,
  faChevronDown,
  faSignOutAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventEmitter from 'reactjs-eventemitter';
import DropdownMenu from '../DropdownMenu';
import TextStack from '../TextStack';
import SignOutModal from './modals/SignOutModal';
import {
  AvatarWrapper,
  BellWrapper,
  Branding,
  BurgerMenu,
  DropdownWrapper,
  NameContainer,
  Profile,
  ProfileField,
  RedDot,
  Wrapper,
} from './styles';

import Avatar from '@/components/Avatar';
import Stack from '@/components/Stack';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import {
  useDeviceLogoutMutation,
  useGetCurrentActorLazyQuery,
  useGetNotificationsLazyQuery,
} from '@/graphql';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import useFilters from '@/hooks/useFilters';
import useMediaQuery from '@/hooks/useMediaQuery';
import useTenantAdminProfile from '@/hooks/useOpenProfile';
import { Role } from '@/routes/PrivateRoute';
import ROUTES from '@/routes/routes';
import {
  selectSidebarActive,
  setRefetchDashboardData,
  toggleSidebar,
} from '@/store/slices/layoutSlice';
import { GenericAction } from '@/types';
import {
  CurrentTenantAdminFragment,
  Notification,
  NotificationFilterSetInput,
} from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { currentAdminVar, currentTenantVar } from '@/util/apollo/cache';
import { EventEmitterTopics } from '@/util/constants';
import { isWeb } from '@/util/platform';
import { useFeatureValue } from '@growthbook/growthbook-react';
import AppButton from '../AppButtton';
import NotificationComponent from '../NotificationPopup';

const AppNavbar = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [eventEmitterSubscribed, setEventEmitterSubscribed] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const notificationFilters = useFilters<NotificationFilterSetInput>({
    isPublished: { value: true },
  });
  const [showRedDot, setShowRedDot] = useState(false);
  const ReleaseNotesAdmin = useFeatureValue(
    FEATURE_TOGGLE.ReleaseNotesAdmin,
    false,
  );

  const [getNotification, { loading }] = useGetNotificationsLazyQuery({
    variables: {
      page: 1,
      perPage: 10,
      filters: notificationFilters.filters,
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data.notifications.items) {
        setPage(1);
        setMaxPage(data.notifications.pageInfo.totalPages);
        setNotifications(data.notifications.items);
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const loadMoreNotifications = () => {
    if (page + 1 <= maxPage && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const [getCurrentWorker] = useGetCurrentActorLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setShowRedDot(
        data?.currentActor?.user?.notificationStats?.release_notes > 0 || false,
      );
    },
    onError(error) {
      console.error(error);
    },
  });

  const reloadData = async () => {
    await getNotification();
    await getCurrentWorker();
  };

  useEffect(() => {
    try {
      if (!eventEmitterSubscribed) {
        EventEmitter.subscribe(
          EventEmitterTopics.RELEASE_NOTES_PUBLISHED,
          () => {
            reloadData();
          },
        );
        setEventEmitterSubscribed(true);
      }
    } catch (e) {
      console.error('Error in subscribing to event emitter', e);
    }
  }, []);

  useEffect(() => {
    if (page > 1) {
      getNotification({
        variables: {
          page: page,
        },
        onCompleted(data) {
          if (data.notifications.items) {
            setMaxPage(data.notifications.pageInfo.totalPages);
            setNotifications((prevNotifications) => [
              ...prevNotifications,
              ...data.notifications.items,
            ]);
          }
        },
        onError(error) {
          console.error('Error fetching notifications:', error);
        },
      });
    } else {
      reloadData().catch((error) => {
        console.error('Error reloading data:', error);
      });
    }
  }, [page]);

  const showSidebar = useAppSelector((state) => selectSidebarActive(state));
  const openAppLinkMobile = useFeatureValue(FEATURE_TOGGLE.OpenAppLink, false);

  const dispatch = useAppDispatch();

  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const currentTenant = useReactiveVar(currentTenantVar);
  const currentAdmin = useReactiveVar(currentAdminVar);

  const { resetGoogleAnalytics } = useAnalytics();
  const [openNotification, setOpenNotification] = useState(false);

  const showNotification =
    ReleaseNotesAdmin &&
    ((currentAdmin?.role === Role.CUSTOMER_ADMIN &&
      currentAdmin.customer?.status > 0) ||
      currentAdmin?.role === Role.TENANT_ADMIN);

  const [deviceLogout] = useDeviceLogoutMutation();
  const [showSignOutModal, hideSignOutModal] = useModal(
    () => (
      <SignOutModal
        onConfirm={async () => {
          window?.HubSpotConversations?.widget?.remove();
          await deviceLogout();
          resetGoogleAnalytics();
          navigate(ROUTES.signOut);
        }}
        onHide={hideSignOutModal}
      />
    ),
    [],
  );

  const { showProfile } = useTenantAdminProfile(
    currentAdmin as CurrentTenantAdminFragment,
  );

  const hideRedDot = () => {
    setShowRedDot(false);
  };

  const readNotification = async (id: string) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => {
        if (notification.id === id) {
          getCurrentWorker();
          return { ...notification, isRead: true };
        }
        return notification;
      }),
    );
  };

  const dropdownActions = useMemo((): GenericAction[][] => {
    const signOutAction: GenericAction = {
      a11yLabel: 'Sign out',
      icon: faSignOutAlt,
      label: 'Sign out',
      onAction: showSignOutModal,
      id: 'sign-out',
    };

    const goToMyCustomerProfileAction: GenericAction = {
      a11yLabel: 'My profile',
      icon: faUser,
      label: 'My profile',
      onAction: () => navigate(ROUTES.myProfile),
    };

    const goToMyTenantProfileAction: GenericAction = {
      a11yLabel: 'My profile',
      icon: faUser,
      label: 'My profile',
      onAction: showProfile,
    };

    const tenantAdminActions: GenericAction[] = [
      goToMyTenantProfileAction,
      signOutAction,
    ];

    const customerAdminActions: GenericAction[] = [
      goToMyCustomerProfileAction,
      signOutAction,
    ];

    return [
      currentAdmin?.role === Role.TENANT_ADMIN
        ? tenantAdminActions
        : customerAdminActions,
    ];
  }, [currentAdmin]);

  const toggleNotification = () => {
    setOpenNotification(!openNotification);
  };

  return (
    <Wrapper>
      <BurgerMenu show={showSidebar} onClick={() => dispatch(toggleSidebar())}>
        <Icon icon={showSidebar ? faTimes : faBars} size="2x" />
      </BurgerMenu>
      {openAppLinkMobile && isWeb() && phoneOnly && <AppButton />}
      <Branding
        to={ROUTES.basePath}
        onClick={() => {
          if (window.location.pathname !== ROUTES.basePath) {
            dispatch(setRefetchDashboardData(true));
          }
        }}
      >
        {currentTenant ? (
          currentTenant.logoUrl ? (
            <img
              src={currentTenant.logoUrl}
              style={{ width: '100%', height: 36 }}
            />
          ) : (
            <Text color="inherit" size="lg" weight="semibold">
              {currentTenant.name}
            </Text>
          )
        ) : null}
      </Branding>

      <DropdownWrapper>
        {showNotification && (
          <BellWrapper onClick={toggleNotification}>
            <NotificationsOutlinedIcon fontSize="medium" htmlColor="#FFF" />
            {showRedDot && <RedDot />}
          </BellWrapper>
        )}
        <DropdownMenu
          actions={dropdownActions}
          justify="right"
          margin="rightLg"
          size={phoneOnly ? 'sm' : 'md'}
        >
          <Profile id="profile-dropdown">
            <Stack gap={20}>
              <AvatarWrapper>
                <Avatar
                  color
                  firstName={currentAdmin?.user.firstName}
                  size="sm"
                  src={currentAdmin?.avatarUrl}
                />
              </AvatarWrapper>
              <NameContainer>
                <TextStack spacing="tight">
                  <ProfileField>
                    {currentAdmin
                      ? `${currentAdmin.user.firstName} ${currentAdmin.user.lastName}`
                      : '...'}
                  </ProfileField>
                  <ProfileField light>
                    {currentAdmin ? currentAdmin.user.email : '...'}
                  </ProfileField>
                </TextStack>
              </NameContainer>
            </Stack>
            <Icon fixedWidth icon={faChevronDown} />
          </Profile>
        </DropdownMenu>
      </DropdownWrapper>
      <NotificationComponent
        loadMoreNotifications={loadMoreNotifications}
        hideRedDot={hideRedDot}
        data={notifications}
        open={openNotification}
        loading={loading}
        enableReadAll={showRedDot}
        readNotification={readNotification}
        toggleNotification={toggleNotification}
      />
    </Wrapper>
  );
};

export default AppNavbar;
