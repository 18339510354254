const vh = Math.max(
  document.documentElement.clientHeight || 0,
  window.innerHeight || 0
);

const vw = Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
);

export { vh, vw };
