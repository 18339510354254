import Badge from '@/components/Badge';
import Icon from '@/elements/Icon';
import { useReadNotificationMutation } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Notification, NotificationStatsTypeEnum } from '@/types/graphql';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import { formatDistanceToNow, parseISO } from 'date-fns';
import {
  BadgeStyle,
  BlueDot,
  Container,
  DateText,
  IconStyle,
  LeftContainer,
  MidContainer,
  PlaceholderDot,
  RedDot,
  RightContainer,
  RoundIconWrapper,
  Subtitle,
  Title,
  UpperContainer,
} from './styles';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';

interface NotificationListItemProps {
  item: Notification;
  hideRedDot: boolean;
  updateNotification: (id: string) => void;
}

const getTimeAgo = (dateString: string) => {
  const date = parseISO(dateString);
  const now = new Date();
  const diffInMinutes = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60),
  );
  if (diffInMinutes <= 0) {
    return `Few seconds ago`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes ago`;
  } else if (diffInMinutes < 1440) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours} hours ago`;
  } else if (diffInMinutes < 2880) {
    return 'Yesterday';
  } else {
    return formatDistanceToNow(date, { addSuffix: true });
  }
};

const NotificationListItem: React.FC<NotificationListItemProps> = ({
  item,
  hideRedDot,
  updateNotification,
}) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { logEvent } = useAnalytics();

  const [readNotificationMutation] = useReadNotificationMutation();
  const readNotification = (item) => {
    if (item.isRead) return;
    readNotificationMutation({
      variables: {
        notificationId: item.id,
        notificationStatsType: NotificationStatsTypeEnum.RELEASE_NOTES,
      },
      onCompleted: () => {
        updateNotification(item.id);
      },
    });
  };

  return (
    <Container
      onClick={() => {
        logEvent(GAEvent.NotificationClicked);
        readNotification(item);
        if (item.link) {
          logEvent(GAEvent.NotificationLinkClicked);
          window.open(item.link, '_blank');
        }
      }}
    >
      <LeftContainer>
        {!hideRedDot && !item.isRead ? (
          phoneOnly ? (
            <BlueDot />
          ) : (
            <RedDot />
          )
        ) : (
          <PlaceholderDot />
        )}
        <RoundIconWrapper>
          <RocketLaunchOutlinedIcon htmlColor="#3A5FA0" style={IconStyle} />
        </RoundIconWrapper>
      </LeftContainer>
      <MidContainer>
        <UpperContainer>
          <Title>{item.title}</Title>
          {!hideRedDot && !item.isRead && (
            <Badge label="New" css={BadgeStyle} status="successGreen" />
          )}
        </UpperContainer>
        <Subtitle>{item.message}</Subtitle>
        <DateText>{getTimeAgo(item.publishAt)}</DateText>
      </MidContainer>
      <RightContainer>
        {item.link && <Icon icon={faExternalLink} theme={'success'} />}
      </RightContainer>
    </Container>
  );
};
export default NotificationListItem;
