import * as Types from '@/types/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const AddressItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pay' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const AccountItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPaymentMethod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const AdminItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const WorkerRelationshipItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerRelationshipItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkerRelationship' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CustomerItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Customer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTaxType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ltaAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkInRadiusFeet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'admins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerRelationshipItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPaymentMethod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerRelationshipItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkerRelationship' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pay' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const AccountFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomerItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cutOff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyCutOff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingWeek' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consolidateUnprocessedCharges' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'groupBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPaymentMethod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerRelationshipItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkerRelationship' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pay' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Customer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTaxType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ltaAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkInRadiusFeet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'admins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerRelationshipItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const TenantAdminItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantAdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SimpleCommentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleComment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Comment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TenantAdmin' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TenantAdminItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CustomerAdmin' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantAdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CurrentAgencyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentAgency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Agency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkinWindow' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxCheckinDistance' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CurrentCustomerAdminFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentCustomerAdmin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationStats' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboardingStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ltaAllowed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const UserItemFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserItemFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationStats' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CurrentTenantAdminFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentTenantAdmin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pendingCustomers' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserItemFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserItemFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationStats' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CurrentTenantFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentTenant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Tenant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobWorkers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'showed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalStatus' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SimpleTotalsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleTotals' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollTotals' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyTaxes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyBenefits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postTaxDeductions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'liability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cashRequirement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeGross' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeTaxes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeBenefits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeNet' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employeeReimbursements' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SimpleContractorPaymentsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleContractorPayments' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContractorPayment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netPay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractorPaymentItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'earningType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedByType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tipAmount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SimpleEarningsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleEarnings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItemEarning' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'earningType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedByType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tipAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SimpleTaxesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleTaxes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItemTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkTaxId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SimplePayrollItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimplePayrollItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'earnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SimpleEarnings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'netPay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SimpleTaxes' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deductions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkPtdId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ptdType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleEarnings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItemEarning' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'earningType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedByType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tipAmount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleTaxes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItemTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkTaxId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SimpleTagFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleTag' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Tag' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const WorkerItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Worker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsRatingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shiftsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeCertificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const JobOfferItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Worker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsRatingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shiftsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeCertificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const JobWorkerItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobWorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobWorker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dropReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'droppedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShowMarkedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'noShowMarkedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Worker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsRatingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shiftsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeCertificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const TimesheetItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimesheetItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Timesheet' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tipAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBreakMinutes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedCheckinAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedCheckoutAt' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedMinutes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netApprovedTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkinAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetComments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'actorType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'actor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedByType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkinCoords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'checkoutAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkoutCoords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportedCheckinAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportedCheckoutAt' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportedBreakMinutes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reportComment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobWorker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'middleName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shift' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderWorkerItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderWorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Worker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsRatingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shiftsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const WorkerJobWorkersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerJobWorkers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobWorkerPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancelled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine1' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timezone' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logoUrl' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstShiftStartAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastShiftEndAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const WorkerSkillFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerSkill' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const WorkerCoordsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerCoords' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Point' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const DeviceLogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeviceLogout' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'deviceLogout' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeviceLogoutMutationFn = Apollo.MutationFunction<
  Types.DeviceLogoutMutation,
  Types.DeviceLogoutMutationVariables
>;

/**
 * __useDeviceLogoutMutation__
 *
 * To run a mutation, you first call `useDeviceLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceLogoutMutation, { data, loading, error }] = useDeviceLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeviceLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeviceLogoutMutation,
    Types.DeviceLogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeviceLogoutMutation,
    Types.DeviceLogoutMutationVariables
  >(DeviceLogoutDocument, options);
}
export type DeviceLogoutMutationHookResult = ReturnType<
  typeof useDeviceLogoutMutation
>;
export type DeviceLogoutMutationResult =
  Apollo.MutationResult<Types.DeviceLogoutMutation>;
export type DeviceLogoutMutationOptions = Apollo.BaseMutationOptions<
  Types.DeviceLogoutMutation,
  Types.DeviceLogoutMutationVariables
>;
export const RemoveWorkerReferenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveWorkerReference' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerReferenceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerRemoveReference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerReferenceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerReferenceId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RemoveWorkerReferenceMutationFn = Apollo.MutationFunction<
  Types.RemoveWorkerReferenceMutation,
  Types.RemoveWorkerReferenceMutationVariables
>;

/**
 * __useRemoveWorkerReferenceMutation__
 *
 * To run a mutation, you first call `useRemoveWorkerReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkerReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkerReferenceMutation, { data, loading, error }] = useRemoveWorkerReferenceMutation({
 *   variables: {
 *      workerReferenceId: // value for 'workerReferenceId'
 *   },
 * });
 */
export function useRemoveWorkerReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RemoveWorkerReferenceMutation,
    Types.RemoveWorkerReferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RemoveWorkerReferenceMutation,
    Types.RemoveWorkerReferenceMutationVariables
  >(RemoveWorkerReferenceDocument, options);
}
export type RemoveWorkerReferenceMutationHookResult = ReturnType<
  typeof useRemoveWorkerReferenceMutation
>;
export type RemoveWorkerReferenceMutationResult =
  Apollo.MutationResult<Types.RemoveWorkerReferenceMutation>;
export type RemoveWorkerReferenceMutationOptions = Apollo.BaseMutationOptions<
  Types.RemoveWorkerReferenceMutation,
  Types.RemoveWorkerReferenceMutationVariables
>;
export const ReadNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'readNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notificationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notificationStatsType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationStatsTypeEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationStatsReset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notificationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationStatsType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notificationStatsType' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ReadNotificationMutationFn = Apollo.MutationFunction<
  Types.ReadNotificationMutation,
  Types.ReadNotificationMutationVariables
>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *      notificationStatsType: // value for 'notificationStatsType'
 *   },
 * });
 */
export function useReadNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ReadNotificationMutation,
    Types.ReadNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ReadNotificationMutation,
    Types.ReadNotificationMutationVariables
  >(ReadNotificationDocument, options);
}
export type ReadNotificationMutationHookResult = ReturnType<
  typeof useReadNotificationMutation
>;
export type ReadNotificationMutationResult =
  Apollo.MutationResult<Types.ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<
  Types.ReadNotificationMutation,
  Types.ReadNotificationMutationVariables
>;
export const NotificationStatsResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'notificationStatsReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notificationStatsType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationStatsTypeEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationStatsReset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationStatsType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notificationStatsType' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type NotificationStatsResetMutationFn = Apollo.MutationFunction<
  Types.NotificationStatsResetMutation,
  Types.NotificationStatsResetMutationVariables
>;

/**
 * __useNotificationStatsResetMutation__
 *
 * To run a mutation, you first call `useNotificationStatsResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationStatsResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationStatsResetMutation, { data, loading, error }] = useNotificationStatsResetMutation({
 *   variables: {
 *      notificationStatsType: // value for 'notificationStatsType'
 *   },
 * });
 */
export function useNotificationStatsResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.NotificationStatsResetMutation,
    Types.NotificationStatsResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.NotificationStatsResetMutation,
    Types.NotificationStatsResetMutationVariables
  >(NotificationStatsResetDocument, options);
}
export type NotificationStatsResetMutationHookResult = ReturnType<
  typeof useNotificationStatsResetMutation
>;
export type NotificationStatsResetMutationResult =
  Apollo.MutationResult<Types.NotificationStatsResetMutation>;
export type NotificationStatsResetMutationOptions = Apollo.BaseMutationOptions<
  Types.NotificationStatsResetMutation,
  Types.NotificationStatsResetMutationVariables
>;
export const GetPlaidLinkTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlaidLinkToken' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'plaidLinkToken' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetPlaidLinkTokenQuery__
 *
 * To run a query within a React component, call `useGetPlaidLinkTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaidLinkTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaidLinkTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlaidLinkTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetPlaidLinkTokenQuery,
    Types.GetPlaidLinkTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetPlaidLinkTokenQuery,
    Types.GetPlaidLinkTokenQueryVariables
  >(GetPlaidLinkTokenDocument, options);
}
export function useGetPlaidLinkTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetPlaidLinkTokenQuery,
    Types.GetPlaidLinkTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetPlaidLinkTokenQuery,
    Types.GetPlaidLinkTokenQueryVariables
  >(GetPlaidLinkTokenDocument, options);
}
export function useGetPlaidLinkTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetPlaidLinkTokenQuery,
    Types.GetPlaidLinkTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetPlaidLinkTokenQuery,
    Types.GetPlaidLinkTokenQueryVariables
  >(GetPlaidLinkTokenDocument, options);
}
export type GetPlaidLinkTokenQueryHookResult = ReturnType<
  typeof useGetPlaidLinkTokenQuery
>;
export type GetPlaidLinkTokenLazyQueryHookResult = ReturnType<
  typeof useGetPlaidLinkTokenLazyQuery
>;
export type GetPlaidLinkTokenSuspenseQueryHookResult = ReturnType<
  typeof useGetPlaidLinkTokenSuspenseQuery
>;
export type GetPlaidLinkTokenQueryResult = Apollo.QueryResult<
  Types.GetPlaidLinkTokenQuery,
  Types.GetPlaidLinkTokenQueryVariables
>;
export const GetWorkerForJobTenantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkerForJobTenant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WorkerFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onboardingStatus' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'workerJurisdictions',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'readyToWork' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetWorkerForJobTenantQuery__
 *
 * To run a query within a React component, call `useGetWorkerForJobTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerForJobTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerForJobTenantQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetWorkerForJobTenantQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetWorkerForJobTenantQuery,
    Types.GetWorkerForJobTenantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetWorkerForJobTenantQuery,
    Types.GetWorkerForJobTenantQueryVariables
  >(GetWorkerForJobTenantDocument, options);
}
export function useGetWorkerForJobTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetWorkerForJobTenantQuery,
    Types.GetWorkerForJobTenantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetWorkerForJobTenantQuery,
    Types.GetWorkerForJobTenantQueryVariables
  >(GetWorkerForJobTenantDocument, options);
}
export function useGetWorkerForJobTenantSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetWorkerForJobTenantQuery,
    Types.GetWorkerForJobTenantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetWorkerForJobTenantQuery,
    Types.GetWorkerForJobTenantQueryVariables
  >(GetWorkerForJobTenantDocument, options);
}
export type GetWorkerForJobTenantQueryHookResult = ReturnType<
  typeof useGetWorkerForJobTenantQuery
>;
export type GetWorkerForJobTenantLazyQueryHookResult = ReturnType<
  typeof useGetWorkerForJobTenantLazyQuery
>;
export type GetWorkerForJobTenantSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkerForJobTenantSuspenseQuery
>;
export type GetWorkerForJobTenantQueryResult = Apollo.QueryResult<
  Types.GetWorkerForJobTenantQuery,
  Types.GetWorkerForJobTenantQueryVariables
>;
export const GetWorkersForJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkersForJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WorkerFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobHireableWorkers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboardingStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workerJurisdictions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'readyToWork' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetWorkersForJobQuery__
 *
 * To run a query within a React component, call `useGetWorkersForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkersForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkersForJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetWorkersForJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetWorkersForJobQuery,
    Types.GetWorkersForJobQueryVariables
  > &
    (
      | { variables: Types.GetWorkersForJobQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetWorkersForJobQuery,
    Types.GetWorkersForJobQueryVariables
  >(GetWorkersForJobDocument, options);
}
export function useGetWorkersForJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetWorkersForJobQuery,
    Types.GetWorkersForJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetWorkersForJobQuery,
    Types.GetWorkersForJobQueryVariables
  >(GetWorkersForJobDocument, options);
}
export function useGetWorkersForJobSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetWorkersForJobQuery,
    Types.GetWorkersForJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetWorkersForJobQuery,
    Types.GetWorkersForJobQueryVariables
  >(GetWorkersForJobDocument, options);
}
export type GetWorkersForJobQueryHookResult = ReturnType<
  typeof useGetWorkersForJobQuery
>;
export type GetWorkersForJobLazyQueryHookResult = ReturnType<
  typeof useGetWorkersForJobLazyQuery
>;
export type GetWorkersForJobSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkersForJobSuspenseQuery
>;
export type GetWorkersForJobQueryResult = Apollo.QueryResult<
  Types.GetWorkersForJobQuery,
  Types.GetWorkersForJobQueryVariables
>;
export const ListWorkersForAutocompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListWorkersForAutocomplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WorkerFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onboardingStatus' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'workerJurisdictions',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'readyToWork' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'phoneNumber',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListWorkersForAutocompleteQuery__
 *
 * To run a query within a React component, call `useListWorkersForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkersForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkersForAutocompleteQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListWorkersForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListWorkersForAutocompleteQuery,
    Types.ListWorkersForAutocompleteQueryVariables
  > &
    (
      | {
          variables: Types.ListWorkersForAutocompleteQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListWorkersForAutocompleteQuery,
    Types.ListWorkersForAutocompleteQueryVariables
  >(ListWorkersForAutocompleteDocument, options);
}
export function useListWorkersForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListWorkersForAutocompleteQuery,
    Types.ListWorkersForAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListWorkersForAutocompleteQuery,
    Types.ListWorkersForAutocompleteQueryVariables
  >(ListWorkersForAutocompleteDocument, options);
}
export function useListWorkersForAutocompleteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListWorkersForAutocompleteQuery,
    Types.ListWorkersForAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListWorkersForAutocompleteQuery,
    Types.ListWorkersForAutocompleteQueryVariables
  >(ListWorkersForAutocompleteDocument, options);
}
export type ListWorkersForAutocompleteQueryHookResult = ReturnType<
  typeof useListWorkersForAutocompleteQuery
>;
export type ListWorkersForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useListWorkersForAutocompleteLazyQuery
>;
export type ListWorkersForAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useListWorkersForAutocompleteSuspenseQuery
>;
export type ListWorkersForAutocompleteQueryResult = Apollo.QueryResult<
  Types.ListWorkersForAutocompleteQuery,
  Types.ListWorkersForAutocompleteQueryVariables
>;
export const AddCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commentableId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commentableType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CommentableEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'body' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentableAddComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'commentableId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'commentableId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'commentableType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'commentableType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'body' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'body' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SimpleComment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleComment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Comment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TenantAdmin' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TenantAdminItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CustomerAdmin' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantAdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AddCommentMutationFn = Apollo.MutationFunction<
  Types.AddCommentMutation,
  Types.AddCommentMutationVariables
>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      commentableId: // value for 'commentableId'
 *      commentableType: // value for 'commentableType'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAddCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddCommentMutation,
    Types.AddCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddCommentMutation,
    Types.AddCommentMutationVariables
  >(AddCommentDocument, options);
}
export type AddCommentMutationHookResult = ReturnType<
  typeof useAddCommentMutation
>;
export type AddCommentMutationResult =
  Apollo.MutationResult<Types.AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<
  Types.AddCommentMutation,
  Types.AddCommentMutationVariables
>;
export const GetUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filename' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filename' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: { kind: 'EnumValue', value: 'AVATAR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadUrlQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGetUploadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetUploadUrlQuery,
    Types.GetUploadUrlQueryVariables
  > &
    (
      | { variables: Types.GetUploadUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetUploadUrlQuery,
    Types.GetUploadUrlQueryVariables
  >(GetUploadUrlDocument, options);
}
export function useGetUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetUploadUrlQuery,
    Types.GetUploadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetUploadUrlQuery,
    Types.GetUploadUrlQueryVariables
  >(GetUploadUrlDocument, options);
}
export function useGetUploadUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetUploadUrlQuery,
    Types.GetUploadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetUploadUrlQuery,
    Types.GetUploadUrlQueryVariables
  >(GetUploadUrlDocument, options);
}
export type GetUploadUrlQueryHookResult = ReturnType<
  typeof useGetUploadUrlQuery
>;
export type GetUploadUrlLazyQueryHookResult = ReturnType<
  typeof useGetUploadUrlLazyQuery
>;
export type GetUploadUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetUploadUrlSuspenseQuery
>;
export type GetUploadUrlQueryResult = Apollo.QueryResult<
  Types.GetUploadUrlQuery,
  Types.GetUploadUrlQueryVariables
>;
export const UserChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserChangePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userChangePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'currentPassword' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newPassword' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UserChangePasswordMutationFn = Apollo.MutationFunction<
  Types.UserChangePasswordMutation,
  Types.UserChangePasswordMutationVariables
>;

/**
 * __useUserChangePasswordMutation__
 *
 * To run a mutation, you first call `useUserChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userChangePasswordMutation, { data, loading, error }] = useUserChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUserChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UserChangePasswordMutation,
    Types.UserChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UserChangePasswordMutation,
    Types.UserChangePasswordMutationVariables
  >(UserChangePasswordDocument, options);
}
export type UserChangePasswordMutationHookResult = ReturnType<
  typeof useUserChangePasswordMutation
>;
export type UserChangePasswordMutationResult =
  Apollo.MutationResult<Types.UserChangePasswordMutation>;
export type UserChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.UserChangePasswordMutation,
  Types.UserChangePasswordMutationVariables
>;
export const ListAgenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAgencies' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agencies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CurrentAgency' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentAgency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Agency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkinWindow' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxCheckinDistance' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListAgenciesQuery__
 *
 * To run a query within a React component, call `useListAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAgenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListAgenciesQuery,
    Types.ListAgenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListAgenciesQuery,
    Types.ListAgenciesQueryVariables
  >(ListAgenciesDocument, options);
}
export function useListAgenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListAgenciesQuery,
    Types.ListAgenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListAgenciesQuery,
    Types.ListAgenciesQueryVariables
  >(ListAgenciesDocument, options);
}
export function useListAgenciesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListAgenciesQuery,
    Types.ListAgenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListAgenciesQuery,
    Types.ListAgenciesQueryVariables
  >(ListAgenciesDocument, options);
}
export type ListAgenciesQueryHookResult = ReturnType<
  typeof useListAgenciesQuery
>;
export type ListAgenciesLazyQueryHookResult = ReturnType<
  typeof useListAgenciesLazyQuery
>;
export type ListAgenciesSuspenseQueryHookResult = ReturnType<
  typeof useListAgenciesSuspenseQuery
>;
export type ListAgenciesQueryResult = Apollo.QueryResult<
  Types.ListAgenciesQuery,
  Types.ListAgenciesQueryVariables
>;
export const ListCustomerAdminsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListCustomerAdmins' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admins' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListCustomerAdminsQuery__
 *
 * To run a query within a React component, call `useListCustomerAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerAdminsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useListCustomerAdminsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListCustomerAdminsQuery,
    Types.ListCustomerAdminsQueryVariables
  > &
    (
      | { variables: Types.ListCustomerAdminsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListCustomerAdminsQuery,
    Types.ListCustomerAdminsQueryVariables
  >(ListCustomerAdminsDocument, options);
}
export function useListCustomerAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListCustomerAdminsQuery,
    Types.ListCustomerAdminsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListCustomerAdminsQuery,
    Types.ListCustomerAdminsQueryVariables
  >(ListCustomerAdminsDocument, options);
}
export function useListCustomerAdminsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListCustomerAdminsQuery,
    Types.ListCustomerAdminsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListCustomerAdminsQuery,
    Types.ListCustomerAdminsQueryVariables
  >(ListCustomerAdminsDocument, options);
}
export type ListCustomerAdminsQueryHookResult = ReturnType<
  typeof useListCustomerAdminsQuery
>;
export type ListCustomerAdminsLazyQueryHookResult = ReturnType<
  typeof useListCustomerAdminsLazyQuery
>;
export type ListCustomerAdminsSuspenseQueryHookResult = ReturnType<
  typeof useListCustomerAdminsSuspenseQuery
>;
export type ListCustomerAdminsQueryResult = Apollo.QueryResult<
  Types.ListCustomerAdminsQuery,
  Types.ListCustomerAdminsQueryVariables
>;
export const DisableAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DisableAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'active' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountUpdateActive' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'active' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'active' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DisableAccountMutationFn = Apollo.MutationFunction<
  Types.DisableAccountMutation,
  Types.DisableAccountMutationVariables
>;

/**
 * __useDisableAccountMutation__
 *
 * To run a mutation, you first call `useDisableAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAccountMutation, { data, loading, error }] = useDisableAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useDisableAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DisableAccountMutation,
    Types.DisableAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DisableAccountMutation,
    Types.DisableAccountMutationVariables
  >(DisableAccountDocument, options);
}
export type DisableAccountMutationHookResult = ReturnType<
  typeof useDisableAccountMutation
>;
export type DisableAccountMutationResult =
  Apollo.MutationResult<Types.DisableAccountMutation>;
export type DisableAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.DisableAccountMutation,
  Types.DisableAccountMutationVariables
>;
export const GetAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomerItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cutOff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyCutOff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingWeek' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consolidateUnprocessedCharges' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'groupBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPaymentMethod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerRelationshipItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkerRelationship' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pay' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Customer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTaxType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ltaAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkInRadiusFeet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'admins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerRelationshipItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetAccountQuery,
    Types.GetAccountQueryVariables
  > &
    (
      | { variables: Types.GetAccountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetAccountQuery, Types.GetAccountQueryVariables>(
    GetAccountDocument,
    options,
  );
}
export function useGetAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetAccountQuery,
    Types.GetAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetAccountQuery,
    Types.GetAccountQueryVariables
  >(GetAccountDocument, options);
}
export function useGetAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetAccountQuery,
    Types.GetAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetAccountQuery,
    Types.GetAccountQueryVariables
  >(GetAccountDocument, options);
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<
  typeof useGetAccountLazyQuery
>;
export type GetAccountSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountSuspenseQuery
>;
export type GetAccountQueryResult = Apollo.QueryResult<
  Types.GetAccountQuery,
  Types.GetAccountQueryVariables
>;
export const GetAccountOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'OrderListItem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobWorkers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'showed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalStatus' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetAccountOrdersQuery__
 *
 * To run a query within a React component, call `useGetAccountOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountOrdersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetAccountOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetAccountOrdersQuery,
    Types.GetAccountOrdersQueryVariables
  > &
    (
      | { variables: Types.GetAccountOrdersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetAccountOrdersQuery,
    Types.GetAccountOrdersQueryVariables
  >(GetAccountOrdersDocument, options);
}
export function useGetAccountOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetAccountOrdersQuery,
    Types.GetAccountOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetAccountOrdersQuery,
    Types.GetAccountOrdersQueryVariables
  >(GetAccountOrdersDocument, options);
}
export function useGetAccountOrdersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetAccountOrdersQuery,
    Types.GetAccountOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetAccountOrdersQuery,
    Types.GetAccountOrdersQueryVariables
  >(GetAccountOrdersDocument, options);
}
export type GetAccountOrdersQueryHookResult = ReturnType<
  typeof useGetAccountOrdersQuery
>;
export type GetAccountOrdersLazyQueryHookResult = ReturnType<
  typeof useGetAccountOrdersLazyQuery
>;
export type GetAccountOrdersSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountOrdersSuspenseQuery
>;
export type GetAccountOrdersQueryResult = Apollo.QueryResult<
  Types.GetAccountOrdersQuery,
  Types.GetAccountOrdersQueryVariables
>;
export const AttachCardToAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AttachCardToAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'account' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentMethodId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAttachCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'account' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentMethodId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentMethodId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'attach' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AttachCardToAccountMutationFn = Apollo.MutationFunction<
  Types.AttachCardToAccountMutation,
  Types.AttachCardToAccountMutationVariables
>;

/**
 * __useAttachCardToAccountMutation__
 *
 * To run a mutation, you first call `useAttachCardToAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachCardToAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachCardToAccountMutation, { data, loading, error }] = useAttachCardToAccountMutation({
 *   variables: {
 *      account: // value for 'account'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useAttachCardToAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AttachCardToAccountMutation,
    Types.AttachCardToAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AttachCardToAccountMutation,
    Types.AttachCardToAccountMutationVariables
  >(AttachCardToAccountDocument, options);
}
export type AttachCardToAccountMutationHookResult = ReturnType<
  typeof useAttachCardToAccountMutation
>;
export type AttachCardToAccountMutationResult =
  Apollo.MutationResult<Types.AttachCardToAccountMutation>;
export type AttachCardToAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.AttachCardToAccountMutation,
  Types.AttachCardToAccountMutationVariables
>;
export const DettachPaymentMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DettachPaymentMethod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'account' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentMethodType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PaymentMethodEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentMethodId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountDetachPaymentMethod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'account' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentMethodType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentMethodType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentMethodId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentMethodId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'detach' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DettachPaymentMethodMutationFn = Apollo.MutationFunction<
  Types.DettachPaymentMethodMutation,
  Types.DettachPaymentMethodMutationVariables
>;

/**
 * __useDettachPaymentMethodMutation__
 *
 * To run a mutation, you first call `useDettachPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDettachPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dettachPaymentMethodMutation, { data, loading, error }] = useDettachPaymentMethodMutation({
 *   variables: {
 *      account: // value for 'account'
 *      paymentMethodType: // value for 'paymentMethodType'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useDettachPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DettachPaymentMethodMutation,
    Types.DettachPaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DettachPaymentMethodMutation,
    Types.DettachPaymentMethodMutationVariables
  >(DettachPaymentMethodDocument, options);
}
export type DettachPaymentMethodMutationHookResult = ReturnType<
  typeof useDettachPaymentMethodMutation
>;
export type DettachPaymentMethodMutationResult =
  Apollo.MutationResult<Types.DettachPaymentMethodMutation>;
export type DettachPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  Types.DettachPaymentMethodMutation,
  Types.DettachPaymentMethodMutationVariables
>;
export const GetAccountPaymentMethodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountPaymentMethods' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountPaymentMethods' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentPaymentMethod' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cards' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'card' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'brand' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'expMonth' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'expYear' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fingerprint' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'funding' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last4' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountHolderName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'routingNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountHolderType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bankName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fingerprint' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetAccountPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetAccountPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPaymentMethodsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetAccountPaymentMethodsQuery,
    Types.GetAccountPaymentMethodsQueryVariables
  > &
    (
      | {
          variables: Types.GetAccountPaymentMethodsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetAccountPaymentMethodsQuery,
    Types.GetAccountPaymentMethodsQueryVariables
  >(GetAccountPaymentMethodsDocument, options);
}
export function useGetAccountPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetAccountPaymentMethodsQuery,
    Types.GetAccountPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetAccountPaymentMethodsQuery,
    Types.GetAccountPaymentMethodsQueryVariables
  >(GetAccountPaymentMethodsDocument, options);
}
export function useGetAccountPaymentMethodsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetAccountPaymentMethodsQuery,
    Types.GetAccountPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetAccountPaymentMethodsQuery,
    Types.GetAccountPaymentMethodsQueryVariables
  >(GetAccountPaymentMethodsDocument, options);
}
export type GetAccountPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetAccountPaymentMethodsQuery
>;
export type GetAccountPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetAccountPaymentMethodsLazyQuery
>;
export type GetAccountPaymentMethodsSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountPaymentMethodsSuspenseQuery
>;
export type GetAccountPaymentMethodsQueryResult = Apollo.QueryResult<
  Types.GetAccountPaymentMethodsQuery,
  Types.GetAccountPaymentMethodsQueryVariables
>;
export const AttachBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AttachBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'account' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publicToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bankAccountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAttachBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'account' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publicToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publicToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bankAccountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bankAccountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'attach' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bankAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AttachBankAccountMutationFn = Apollo.MutationFunction<
  Types.AttachBankAccountMutation,
  Types.AttachBankAccountMutationVariables
>;

/**
 * __useAttachBankAccountMutation__
 *
 * To run a mutation, you first call `useAttachBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachBankAccountMutation, { data, loading, error }] = useAttachBankAccountMutation({
 *   variables: {
 *      account: // value for 'account'
 *      publicToken: // value for 'publicToken'
 *      bankAccountId: // value for 'bankAccountId'
 *   },
 * });
 */
export function useAttachBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AttachBankAccountMutation,
    Types.AttachBankAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AttachBankAccountMutation,
    Types.AttachBankAccountMutationVariables
  >(AttachBankAccountDocument, options);
}
export type AttachBankAccountMutationHookResult = ReturnType<
  typeof useAttachBankAccountMutation
>;
export type AttachBankAccountMutationResult =
  Apollo.MutationResult<Types.AttachBankAccountMutation>;
export type AttachBankAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.AttachBankAccountMutation,
  Types.AttachBankAccountMutationVariables
>;
export const SetCurrentPaymentMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetCurrentPaymentMethod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'account' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentMethodId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSetCurrentPaymentMethod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'account' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentMethodId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentMethodId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'transaction' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SetCurrentPaymentMethodMutationFn = Apollo.MutationFunction<
  Types.SetCurrentPaymentMethodMutation,
  Types.SetCurrentPaymentMethodMutationVariables
>;

/**
 * __useSetCurrentPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetCurrentPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentPaymentMethodMutation, { data, loading, error }] = useSetCurrentPaymentMethodMutation({
 *   variables: {
 *      account: // value for 'account'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useSetCurrentPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SetCurrentPaymentMethodMutation,
    Types.SetCurrentPaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SetCurrentPaymentMethodMutation,
    Types.SetCurrentPaymentMethodMutationVariables
  >(SetCurrentPaymentMethodDocument, options);
}
export type SetCurrentPaymentMethodMutationHookResult = ReturnType<
  typeof useSetCurrentPaymentMethodMutation
>;
export type SetCurrentPaymentMethodMutationResult =
  Apollo.MutationResult<Types.SetCurrentPaymentMethodMutation>;
export type SetCurrentPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  Types.SetCurrentPaymentMethodMutation,
  Types.SetCurrentPaymentMethodMutationVariables
>;
export const UpdateAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'defaultContactId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'markupPercent' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'defaultContactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'defaultContactId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'markupPercent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'markupPercent' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultContact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  Types.UpdateAccountMutation,
  Types.UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      defaultContactId: // value for 'defaultContactId'
 *      city: // value for 'city'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      zip: // value for 'zip'
 *      state: // value for 'state'
 *      markupPercent: // value for 'markupPercent'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateAccountMutation,
    Types.UpdateAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateAccountMutation,
    Types.UpdateAccountMutationVariables
  >(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<
  typeof useUpdateAccountMutation
>;
export type UpdateAccountMutationResult =
  Apollo.MutationResult<Types.UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateAccountMutation,
  Types.UpdateAccountMutationVariables
>;
export const UpdateAccountCutOffDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountCutOff' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'billingWeek' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'BillingWeekEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceCutOff' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InvoiceCutOffEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'monthlyCutOff' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'consolidateUnprocessedCharges' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InvoiceGroupByEnum' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountCutOffUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingWeek' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'billingWeek' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cutOff' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceCutOff' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'monthlyCutOff' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'monthlyCutOff' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consolidateUnprocessedCharges' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'consolidateUnprocessedCharges',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupBy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateAccountCutOffMutationFn = Apollo.MutationFunction<
  Types.UpdateAccountCutOffMutation,
  Types.UpdateAccountCutOffMutationVariables
>;

/**
 * __useUpdateAccountCutOffMutation__
 *
 * To run a mutation, you first call `useUpdateAccountCutOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountCutOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountCutOffMutation, { data, loading, error }] = useUpdateAccountCutOffMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      billingWeek: // value for 'billingWeek'
 *      invoiceCutOff: // value for 'invoiceCutOff'
 *      monthlyCutOff: // value for 'monthlyCutOff'
 *      consolidateUnprocessedCharges: // value for 'consolidateUnprocessedCharges'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useUpdateAccountCutOffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateAccountCutOffMutation,
    Types.UpdateAccountCutOffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateAccountCutOffMutation,
    Types.UpdateAccountCutOffMutationVariables
  >(UpdateAccountCutOffDocument, options);
}
export type UpdateAccountCutOffMutationHookResult = ReturnType<
  typeof useUpdateAccountCutOffMutation
>;
export type UpdateAccountCutOffMutationResult =
  Apollo.MutationResult<Types.UpdateAccountCutOffMutation>;
export type UpdateAccountCutOffMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateAccountCutOffMutation,
  Types.UpdateAccountCutOffMutationVariables
>;
export const AuthenticateChatServiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthenticateChatService' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chatServiceAuthenticate' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AuthenticateChatServiceMutationFn = Apollo.MutationFunction<
  Types.AuthenticateChatServiceMutation,
  Types.AuthenticateChatServiceMutationVariables
>;

/**
 * __useAuthenticateChatServiceMutation__
 *
 * To run a mutation, you first call `useAuthenticateChatServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateChatServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateChatServiceMutation, { data, loading, error }] = useAuthenticateChatServiceMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticateChatServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AuthenticateChatServiceMutation,
    Types.AuthenticateChatServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AuthenticateChatServiceMutation,
    Types.AuthenticateChatServiceMutationVariables
  >(AuthenticateChatServiceDocument, options);
}
export type AuthenticateChatServiceMutationHookResult = ReturnType<
  typeof useAuthenticateChatServiceMutation
>;
export type AuthenticateChatServiceMutationResult =
  Apollo.MutationResult<Types.AuthenticateChatServiceMutation>;
export type AuthenticateChatServiceMutationOptions = Apollo.BaseMutationOptions<
  Types.AuthenticateChatServiceMutation,
  Types.AuthenticateChatServiceMutationVariables
>;
export const UpdateUserProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'middleName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserRoleEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'middleName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'middleName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'roleId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  Types.UpdateUserProfileMutation,
  Types.UpdateUserProfileMutationVariables
>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      firstName: // value for 'firstName'
 *      middleName: // value for 'middleName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      password: // value for 'password'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateUserProfileMutation,
    Types.UpdateUserProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateUserProfileMutation,
    Types.UpdateUserProfileMutationVariables
  >(UpdateUserProfileDocument, options);
}
export type UpdateUserProfileMutationHookResult = ReturnType<
  typeof useUpdateUserProfileMutation
>;
export type UpdateUserProfileMutationResult =
  Apollo.MutationResult<Types.UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateUserProfileMutation,
  Types.UpdateUserProfileMutationVariables
>;
export const ChangeTenantAdminAvatarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeTenantAdminAvatar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'avatarKey' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantAdminId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantAdminChangeAvatar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantAdminId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tenantAdminId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'avatarKey' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'avatarKey' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantAdmin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ChangeTenantAdminAvatarMutationFn = Apollo.MutationFunction<
  Types.ChangeTenantAdminAvatarMutation,
  Types.ChangeTenantAdminAvatarMutationVariables
>;

/**
 * __useChangeTenantAdminAvatarMutation__
 *
 * To run a mutation, you first call `useChangeTenantAdminAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTenantAdminAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTenantAdminAvatarMutation, { data, loading, error }] = useChangeTenantAdminAvatarMutation({
 *   variables: {
 *      avatarKey: // value for 'avatarKey'
 *      tenantAdminId: // value for 'tenantAdminId'
 *   },
 * });
 */
export function useChangeTenantAdminAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ChangeTenantAdminAvatarMutation,
    Types.ChangeTenantAdminAvatarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ChangeTenantAdminAvatarMutation,
    Types.ChangeTenantAdminAvatarMutationVariables
  >(ChangeTenantAdminAvatarDocument, options);
}
export type ChangeTenantAdminAvatarMutationHookResult = ReturnType<
  typeof useChangeTenantAdminAvatarMutation
>;
export type ChangeTenantAdminAvatarMutationResult =
  Apollo.MutationResult<Types.ChangeTenantAdminAvatarMutation>;
export type ChangeTenantAdminAvatarMutationOptions = Apollo.BaseMutationOptions<
  Types.ChangeTenantAdminAvatarMutation,
  Types.ChangeTenantAdminAvatarMutationVariables
>;
export const ChangeStatusUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeStatusUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'active' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userChangeActive' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'active' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'active' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ChangeStatusUserMutationFn = Apollo.MutationFunction<
  Types.ChangeStatusUserMutation,
  Types.ChangeStatusUserMutationVariables
>;

/**
 * __useChangeStatusUserMutation__
 *
 * To run a mutation, you first call `useChangeStatusUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeStatusUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeStatusUserMutation, { data, loading, error }] = useChangeStatusUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useChangeStatusUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ChangeStatusUserMutation,
    Types.ChangeStatusUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ChangeStatusUserMutation,
    Types.ChangeStatusUserMutationVariables
  >(ChangeStatusUserDocument, options);
}
export type ChangeStatusUserMutationHookResult = ReturnType<
  typeof useChangeStatusUserMutation
>;
export type ChangeStatusUserMutationResult =
  Apollo.MutationResult<Types.ChangeStatusUserMutation>;
export type ChangeStatusUserMutationOptions = Apollo.BaseMutationOptions<
  Types.ChangeStatusUserMutation,
  Types.ChangeStatusUserMutationVariables
>;
export const CreateTenantAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTenantAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserRoleEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantAdminInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tenantId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'roleId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantAdmin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TenantAdminItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantAdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateTenantAdminMutationFn = Apollo.MutationFunction<
  Types.CreateTenantAdminMutation,
  Types.CreateTenantAdminMutationVariables
>;

/**
 * __useCreateTenantAdminMutation__
 *
 * To run a mutation, you first call `useCreateTenantAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantAdminMutation, { data, loading, error }] = useCreateTenantAdminMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      password: // value for 'password'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCreateTenantAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTenantAdminMutation,
    Types.CreateTenantAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateTenantAdminMutation,
    Types.CreateTenantAdminMutationVariables
  >(CreateTenantAdminDocument, options);
}
export type CreateTenantAdminMutationHookResult = ReturnType<
  typeof useCreateTenantAdminMutation
>;
export type CreateTenantAdminMutationResult =
  Apollo.MutationResult<Types.CreateTenantAdminMutation>;
export type CreateTenantAdminMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTenantAdminMutation,
  Types.CreateTenantAdminMutationVariables
>;
export const ListCustomerAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListCustomerAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CustomerAdminFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerAdmins' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'acceptedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logoUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'UserItemFields',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserItemFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationStats' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListCustomerAdminQuery__
 *
 * To run a query within a React component, call `useListCustomerAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerAdminQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useListCustomerAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListCustomerAdminQuery,
    Types.ListCustomerAdminQueryVariables
  > &
    (
      | { variables: Types.ListCustomerAdminQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListCustomerAdminQuery,
    Types.ListCustomerAdminQueryVariables
  >(ListCustomerAdminDocument, options);
}
export function useListCustomerAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListCustomerAdminQuery,
    Types.ListCustomerAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListCustomerAdminQuery,
    Types.ListCustomerAdminQueryVariables
  >(ListCustomerAdminDocument, options);
}
export function useListCustomerAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListCustomerAdminQuery,
    Types.ListCustomerAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListCustomerAdminQuery,
    Types.ListCustomerAdminQueryVariables
  >(ListCustomerAdminDocument, options);
}
export type ListCustomerAdminQueryHookResult = ReturnType<
  typeof useListCustomerAdminQuery
>;
export type ListCustomerAdminLazyQueryHookResult = ReturnType<
  typeof useListCustomerAdminLazyQuery
>;
export type ListCustomerAdminSuspenseQueryHookResult = ReturnType<
  typeof useListCustomerAdminSuspenseQuery
>;
export type ListCustomerAdminQueryResult = Apollo.QueryResult<
  Types.ListCustomerAdminQuery,
  Types.ListCustomerAdminQueryVariables
>;
export const ListTenantAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListTenantAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TenantAdminFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'admins' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'UserItemFields',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserItemFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationStats' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListTenantAdminQuery__
 *
 * To run a query within a React component, call `useListTenantAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTenantAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTenantAdminQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListTenantAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListTenantAdminQuery,
    Types.ListTenantAdminQueryVariables
  > &
    (
      | { variables: Types.ListTenantAdminQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListTenantAdminQuery,
    Types.ListTenantAdminQueryVariables
  >(ListTenantAdminDocument, options);
}
export function useListTenantAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListTenantAdminQuery,
    Types.ListTenantAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListTenantAdminQuery,
    Types.ListTenantAdminQueryVariables
  >(ListTenantAdminDocument, options);
}
export function useListTenantAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListTenantAdminQuery,
    Types.ListTenantAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListTenantAdminQuery,
    Types.ListTenantAdminQueryVariables
  >(ListTenantAdminDocument, options);
}
export type ListTenantAdminQueryHookResult = ReturnType<
  typeof useListTenantAdminQuery
>;
export type ListTenantAdminLazyQueryHookResult = ReturnType<
  typeof useListTenantAdminLazyQuery
>;
export type ListTenantAdminSuspenseQueryHookResult = ReturnType<
  typeof useListTenantAdminSuspenseQuery
>;
export type ListTenantAdminQueryResult = Apollo.QueryResult<
  Types.ListTenantAdminQuery,
  Types.ListTenantAdminQueryVariables
>;
export const AutocompleteCustomersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutocompleteCustomers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerStatus' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'NonNegativeInt' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rateType' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AccountRateTypeEnum' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'query' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'value' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'query' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'customerStatus' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobTaxType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ltaAllowed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'admins' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'user' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'firstName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'lastName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accounts' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filters' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'rateType',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'rateType',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'active' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'value',
                                              },
                                              value: {
                                                kind: 'BooleanValue',
                                                value: true,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'markupPercent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rateType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'logoUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'defaultContact',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatarUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'user' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'firstName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'lastName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'email',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'phoneNumber',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currentPaymentMethod',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addresses' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine1',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine2',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timezone' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zip' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coords' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'latitude',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longitude',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rates' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'pay' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'cost' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'account',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'address',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'active',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'skill',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useAutocompleteCustomersQuery__
 *
 * To run a query within a React component, call `useAutocompleteCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteCustomersQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      query: // value for 'query'
 *      customerStatus: // value for 'customerStatus'
 *      rateType: // value for 'rateType'
 *   },
 * });
 */
export function useAutocompleteCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AutocompleteCustomersQuery,
    Types.AutocompleteCustomersQueryVariables
  > &
    (
      | { variables: Types.AutocompleteCustomersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.AutocompleteCustomersQuery,
    Types.AutocompleteCustomersQueryVariables
  >(AutocompleteCustomersDocument, options);
}
export function useAutocompleteCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AutocompleteCustomersQuery,
    Types.AutocompleteCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.AutocompleteCustomersQuery,
    Types.AutocompleteCustomersQueryVariables
  >(AutocompleteCustomersDocument, options);
}
export function useAutocompleteCustomersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.AutocompleteCustomersQuery,
    Types.AutocompleteCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.AutocompleteCustomersQuery,
    Types.AutocompleteCustomersQueryVariables
  >(AutocompleteCustomersDocument, options);
}
export type AutocompleteCustomersQueryHookResult = ReturnType<
  typeof useAutocompleteCustomersQuery
>;
export type AutocompleteCustomersLazyQueryHookResult = ReturnType<
  typeof useAutocompleteCustomersLazyQuery
>;
export type AutocompleteCustomersSuspenseQueryHookResult = ReturnType<
  typeof useAutocompleteCustomersSuspenseQuery
>;
export type AutocompleteCustomersQueryResult = Apollo.QueryResult<
  Types.AutocompleteCustomersQuery,
  Types.AutocompleteCustomersQueryVariables
>;
export const ListUniformsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListUniforms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uniforms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultInstructions' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListUniformsQuery__
 *
 * To run a query within a React component, call `useListUniformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUniformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUniformsQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useListUniformsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListUniformsQuery,
    Types.ListUniformsQueryVariables
  > &
    (
      | { variables: Types.ListUniformsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListUniformsQuery,
    Types.ListUniformsQueryVariables
  >(ListUniformsDocument, options);
}
export function useListUniformsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListUniformsQuery,
    Types.ListUniformsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListUniformsQuery,
    Types.ListUniformsQueryVariables
  >(ListUniformsDocument, options);
}
export function useListUniformsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListUniformsQuery,
    Types.ListUniformsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListUniformsQuery,
    Types.ListUniformsQueryVariables
  >(ListUniformsDocument, options);
}
export type ListUniformsQueryHookResult = ReturnType<
  typeof useListUniformsQuery
>;
export type ListUniformsLazyQueryHookResult = ReturnType<
  typeof useListUniformsLazyQuery
>;
export type ListUniformsSuspenseQueryHookResult = ReturnType<
  typeof useListUniformsSuspenseQuery
>;
export type ListUniformsQueryResult = Apollo.QueryResult<
  Types.ListUniformsQuery,
  Types.ListUniformsQueryVariables
>;
export const GetRateRangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRateRange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rateRange' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetRateRangeQuery__
 *
 * To run a query within a React component, call `useGetRateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateRangeQuery({
 *   variables: {
 *      addressId: // value for 'addressId'
 *      skillId: // value for 'skillId'
 *   },
 * });
 */
export function useGetRateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetRateRangeQuery,
    Types.GetRateRangeQueryVariables
  > &
    (
      | { variables: Types.GetRateRangeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetRateRangeQuery,
    Types.GetRateRangeQueryVariables
  >(GetRateRangeDocument, options);
}
export function useGetRateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetRateRangeQuery,
    Types.GetRateRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetRateRangeQuery,
    Types.GetRateRangeQueryVariables
  >(GetRateRangeDocument, options);
}
export function useGetRateRangeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetRateRangeQuery,
    Types.GetRateRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetRateRangeQuery,
    Types.GetRateRangeQueryVariables
  >(GetRateRangeDocument, options);
}
export type GetRateRangeQueryHookResult = ReturnType<
  typeof useGetRateRangeQuery
>;
export type GetRateRangeLazyQueryHookResult = ReturnType<
  typeof useGetRateRangeLazyQuery
>;
export type GetRateRangeSuspenseQueryHookResult = ReturnType<
  typeof useGetRateRangeSuspenseQuery
>;
export type GetRateRangeQueryResult = Apollo.QueryResult<
  Types.GetRateRangeQuery,
  Types.GetRateRangeQueryVariables
>;
export const CreateGigOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGigOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'JobInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobsCount' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workersHired' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateGigOrderMutationFn = Apollo.MutationFunction<
  Types.CreateGigOrderMutation,
  Types.CreateGigOrderMutationVariables
>;

/**
 * __useCreateGigOrderMutation__
 *
 * To run a mutation, you first call `useCreateGigOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGigOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGigOrderMutation, { data, loading, error }] = useCreateGigOrderMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      jobs: // value for 'jobs'
 *   },
 * });
 */
export function useCreateGigOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateGigOrderMutation,
    Types.CreateGigOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateGigOrderMutation,
    Types.CreateGigOrderMutationVariables
  >(CreateGigOrderDocument, options);
}
export type CreateGigOrderMutationHookResult = ReturnType<
  typeof useCreateGigOrderMutation
>;
export type CreateGigOrderMutationResult =
  Apollo.MutationResult<Types.CreateGigOrderMutation>;
export type CreateGigOrderMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateGigOrderMutation,
  Types.CreateGigOrderMutationVariables
>;
export const CreateGroupJobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGroupJobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferGroupCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateGroupJobOfferMutationFn = Apollo.MutationFunction<
  Types.CreateGroupJobOfferMutation,
  Types.CreateGroupJobOfferMutationVariables
>;

/**
 * __useCreateGroupJobOfferMutation__
 *
 * To run a mutation, you first call `useCreateGroupJobOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupJobOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupJobOfferMutation, { data, loading, error }] = useCreateGroupJobOfferMutation({
 *   variables: {
 *      jobIds: // value for 'jobIds'
 *      workerIds: // value for 'workerIds'
 *   },
 * });
 */
export function useCreateGroupJobOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateGroupJobOfferMutation,
    Types.CreateGroupJobOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateGroupJobOfferMutation,
    Types.CreateGroupJobOfferMutationVariables
  >(CreateGroupJobOfferDocument, options);
}
export type CreateGroupJobOfferMutationHookResult = ReturnType<
  typeof useCreateGroupJobOfferMutation
>;
export type CreateGroupJobOfferMutationResult =
  Apollo.MutationResult<Types.CreateGroupJobOfferMutation>;
export type CreateGroupJobOfferMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateGroupJobOfferMutation,
  Types.CreateGroupJobOfferMutationVariables
>;
export const GetSurgeRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSurgeRates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601Date' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'surgeRatesFromDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fromDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetSurgeRatesQuery__
 *
 * To run a query within a React component, call `useGetSurgeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurgeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurgeRatesQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetSurgeRatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetSurgeRatesQuery,
    Types.GetSurgeRatesQueryVariables
  > &
    (
      | { variables: Types.GetSurgeRatesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetSurgeRatesQuery,
    Types.GetSurgeRatesQueryVariables
  >(GetSurgeRatesDocument, options);
}
export function useGetSurgeRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSurgeRatesQuery,
    Types.GetSurgeRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetSurgeRatesQuery,
    Types.GetSurgeRatesQueryVariables
  >(GetSurgeRatesDocument, options);
}
export function useGetSurgeRatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetSurgeRatesQuery,
    Types.GetSurgeRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetSurgeRatesQuery,
    Types.GetSurgeRatesQueryVariables
  >(GetSurgeRatesDocument, options);
}
export type GetSurgeRatesQueryHookResult = ReturnType<
  typeof useGetSurgeRatesQuery
>;
export type GetSurgeRatesLazyQueryHookResult = ReturnType<
  typeof useGetSurgeRatesLazyQuery
>;
export type GetSurgeRatesSuspenseQueryHookResult = ReturnType<
  typeof useGetSurgeRatesSuspenseQuery
>;
export type GetSurgeRatesQueryResult = Apollo.QueryResult<
  Types.GetSurgeRatesQuery,
  Types.GetSurgeRatesQueryVariables
>;
export const GetWorkerRelationshipsByCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkerRelationshipsByCustomer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WorkerRelationshipFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workerRelationships' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'worker' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'phoneNumber',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skills' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetWorkerRelationshipsByCustomerQuery__
 *
 * To run a query within a React component, call `useGetWorkerRelationshipsByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerRelationshipsByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerRelationshipsByCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetWorkerRelationshipsByCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetWorkerRelationshipsByCustomerQuery,
    Types.GetWorkerRelationshipsByCustomerQueryVariables
  > &
    (
      | {
          variables: Types.GetWorkerRelationshipsByCustomerQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetWorkerRelationshipsByCustomerQuery,
    Types.GetWorkerRelationshipsByCustomerQueryVariables
  >(GetWorkerRelationshipsByCustomerDocument, options);
}
export function useGetWorkerRelationshipsByCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetWorkerRelationshipsByCustomerQuery,
    Types.GetWorkerRelationshipsByCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetWorkerRelationshipsByCustomerQuery,
    Types.GetWorkerRelationshipsByCustomerQueryVariables
  >(GetWorkerRelationshipsByCustomerDocument, options);
}
export function useGetWorkerRelationshipsByCustomerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetWorkerRelationshipsByCustomerQuery,
    Types.GetWorkerRelationshipsByCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetWorkerRelationshipsByCustomerQuery,
    Types.GetWorkerRelationshipsByCustomerQueryVariables
  >(GetWorkerRelationshipsByCustomerDocument, options);
}
export type GetWorkerRelationshipsByCustomerQueryHookResult = ReturnType<
  typeof useGetWorkerRelationshipsByCustomerQuery
>;
export type GetWorkerRelationshipsByCustomerLazyQueryHookResult = ReturnType<
  typeof useGetWorkerRelationshipsByCustomerLazyQuery
>;
export type GetWorkerRelationshipsByCustomerSuspenseQueryHookResult =
  ReturnType<typeof useGetWorkerRelationshipsByCustomerSuspenseQuery>;
export type GetWorkerRelationshipsByCustomerQueryResult = Apollo.QueryResult<
  Types.GetWorkerRelationshipsByCustomerQuery,
  Types.GetWorkerRelationshipsByCustomerQueryVariables
>;
export const DefaultRateByCityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DefaultRateByCity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultRatesBySkill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'descriptionEn' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useDefaultRateByCityQuery__
 *
 * To run a query within a React component, call `useDefaultRateByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultRateByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultRateByCityQuery({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDefaultRateByCityQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DefaultRateByCityQuery,
    Types.DefaultRateByCityQueryVariables
  > &
    (
      | { variables: Types.DefaultRateByCityQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.DefaultRateByCityQuery,
    Types.DefaultRateByCityQueryVariables
  >(DefaultRateByCityDocument, options);
}
export function useDefaultRateByCityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DefaultRateByCityQuery,
    Types.DefaultRateByCityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.DefaultRateByCityQuery,
    Types.DefaultRateByCityQueryVariables
  >(DefaultRateByCityDocument, options);
}
export function useDefaultRateByCitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.DefaultRateByCityQuery,
    Types.DefaultRateByCityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.DefaultRateByCityQuery,
    Types.DefaultRateByCityQueryVariables
  >(DefaultRateByCityDocument, options);
}
export type DefaultRateByCityQueryHookResult = ReturnType<
  typeof useDefaultRateByCityQuery
>;
export type DefaultRateByCityLazyQueryHookResult = ReturnType<
  typeof useDefaultRateByCityLazyQuery
>;
export type DefaultRateByCitySuspenseQueryHookResult = ReturnType<
  typeof useDefaultRateByCitySuspenseQuery
>;
export type DefaultRateByCityQueryResult = Apollo.QueryResult<
  Types.DefaultRateByCityQuery,
  Types.DefaultRateByCityQueryVariables
>;
export const FetchNearestRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchNearestRates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fetchRates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pay' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultRates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costRate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payRate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFetchNearestRatesQuery__
 *
 * To run a query within a React component, call `useFetchNearestRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchNearestRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchNearestRatesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      skillId: // value for 'skillId'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useFetchNearestRatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FetchNearestRatesQuery,
    Types.FetchNearestRatesQueryVariables
  > &
    (
      | { variables: Types.FetchNearestRatesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FetchNearestRatesQuery,
    Types.FetchNearestRatesQueryVariables
  >(FetchNearestRatesDocument, options);
}
export function useFetchNearestRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FetchNearestRatesQuery,
    Types.FetchNearestRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FetchNearestRatesQuery,
    Types.FetchNearestRatesQueryVariables
  >(FetchNearestRatesDocument, options);
}
export function useFetchNearestRatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.FetchNearestRatesQuery,
    Types.FetchNearestRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.FetchNearestRatesQuery,
    Types.FetchNearestRatesQueryVariables
  >(FetchNearestRatesDocument, options);
}
export type FetchNearestRatesQueryHookResult = ReturnType<
  typeof useFetchNearestRatesQuery
>;
export type FetchNearestRatesLazyQueryHookResult = ReturnType<
  typeof useFetchNearestRatesLazyQuery
>;
export type FetchNearestRatesSuspenseQueryHookResult = ReturnType<
  typeof useFetchNearestRatesSuspenseQuery
>;
export type FetchNearestRatesQueryResult = Apollo.QueryResult<
  Types.FetchNearestRatesQuery,
  Types.FetchNearestRatesQueryVariables
>;
export const ListSkillCategoriesByDefaultRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListSkillCategoriesByDefaultRates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'descriptionEn' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gravyTrained' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListSkillCategoriesByDefaultRatesQuery__
 *
 * To run a query within a React component, call `useListSkillCategoriesByDefaultRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSkillCategoriesByDefaultRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSkillCategoriesByDefaultRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSkillCategoriesByDefaultRatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListSkillCategoriesByDefaultRatesQuery,
    Types.ListSkillCategoriesByDefaultRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListSkillCategoriesByDefaultRatesQuery,
    Types.ListSkillCategoriesByDefaultRatesQueryVariables
  >(ListSkillCategoriesByDefaultRatesDocument, options);
}
export function useListSkillCategoriesByDefaultRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListSkillCategoriesByDefaultRatesQuery,
    Types.ListSkillCategoriesByDefaultRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListSkillCategoriesByDefaultRatesQuery,
    Types.ListSkillCategoriesByDefaultRatesQueryVariables
  >(ListSkillCategoriesByDefaultRatesDocument, options);
}
export function useListSkillCategoriesByDefaultRatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListSkillCategoriesByDefaultRatesQuery,
    Types.ListSkillCategoriesByDefaultRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListSkillCategoriesByDefaultRatesQuery,
    Types.ListSkillCategoriesByDefaultRatesQueryVariables
  >(ListSkillCategoriesByDefaultRatesDocument, options);
}
export type ListSkillCategoriesByDefaultRatesQueryHookResult = ReturnType<
  typeof useListSkillCategoriesByDefaultRatesQuery
>;
export type ListSkillCategoriesByDefaultRatesLazyQueryHookResult = ReturnType<
  typeof useListSkillCategoriesByDefaultRatesLazyQuery
>;
export type ListSkillCategoriesByDefaultRatesSuspenseQueryHookResult =
  ReturnType<typeof useListSkillCategoriesByDefaultRatesSuspenseQuery>;
export type ListSkillCategoriesByDefaultRatesQueryResult = Apollo.QueryResult<
  Types.ListSkillCategoriesByDefaultRatesQuery,
  Types.ListSkillCategoriesByDefaultRatesQueryVariables
>;
export const ListSkillCategoriesByCustomRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListSkillCategoriesByCustomRates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'descriptionEn',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'gravyTrained',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'category' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'imageUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListSkillCategoriesByCustomRatesQuery__
 *
 * To run a query within a React component, call `useListSkillCategoriesByCustomRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSkillCategoriesByCustomRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSkillCategoriesByCustomRatesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useListSkillCategoriesByCustomRatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListSkillCategoriesByCustomRatesQuery,
    Types.ListSkillCategoriesByCustomRatesQueryVariables
  > &
    (
      | {
          variables: Types.ListSkillCategoriesByCustomRatesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListSkillCategoriesByCustomRatesQuery,
    Types.ListSkillCategoriesByCustomRatesQueryVariables
  >(ListSkillCategoriesByCustomRatesDocument, options);
}
export function useListSkillCategoriesByCustomRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListSkillCategoriesByCustomRatesQuery,
    Types.ListSkillCategoriesByCustomRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListSkillCategoriesByCustomRatesQuery,
    Types.ListSkillCategoriesByCustomRatesQueryVariables
  >(ListSkillCategoriesByCustomRatesDocument, options);
}
export function useListSkillCategoriesByCustomRatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListSkillCategoriesByCustomRatesQuery,
    Types.ListSkillCategoriesByCustomRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListSkillCategoriesByCustomRatesQuery,
    Types.ListSkillCategoriesByCustomRatesQueryVariables
  >(ListSkillCategoriesByCustomRatesDocument, options);
}
export type ListSkillCategoriesByCustomRatesQueryHookResult = ReturnType<
  typeof useListSkillCategoriesByCustomRatesQuery
>;
export type ListSkillCategoriesByCustomRatesLazyQueryHookResult = ReturnType<
  typeof useListSkillCategoriesByCustomRatesLazyQuery
>;
export type ListSkillCategoriesByCustomRatesSuspenseQueryHookResult =
  ReturnType<typeof useListSkillCategoriesByCustomRatesSuspenseQuery>;
export type ListSkillCategoriesByCustomRatesQueryResult = Apollo.QueryResult<
  Types.ListSkillCategoriesByCustomRatesQuery,
  Types.ListSkillCategoriesByCustomRatesQueryVariables
>;
export const ListAgencyCustomerCertificatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAgencyCustomerCertificates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerFilters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CustomerFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificateType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'customerFilters' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'certificates' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'certificateType',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListAgencyCustomerCertificatesQuery__
 *
 * To run a query within a React component, call `useListAgencyCustomerCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgencyCustomerCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgencyCustomerCertificatesQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      customerFilters: // value for 'customerFilters'
 *   },
 * });
 */
export function useListAgencyCustomerCertificatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListAgencyCustomerCertificatesQuery,
    Types.ListAgencyCustomerCertificatesQueryVariables
  > &
    (
      | {
          variables: Types.ListAgencyCustomerCertificatesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListAgencyCustomerCertificatesQuery,
    Types.ListAgencyCustomerCertificatesQueryVariables
  >(ListAgencyCustomerCertificatesDocument, options);
}
export function useListAgencyCustomerCertificatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListAgencyCustomerCertificatesQuery,
    Types.ListAgencyCustomerCertificatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListAgencyCustomerCertificatesQuery,
    Types.ListAgencyCustomerCertificatesQueryVariables
  >(ListAgencyCustomerCertificatesDocument, options);
}
export function useListAgencyCustomerCertificatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListAgencyCustomerCertificatesQuery,
    Types.ListAgencyCustomerCertificatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListAgencyCustomerCertificatesQuery,
    Types.ListAgencyCustomerCertificatesQueryVariables
  >(ListAgencyCustomerCertificatesDocument, options);
}
export type ListAgencyCustomerCertificatesQueryHookResult = ReturnType<
  typeof useListAgencyCustomerCertificatesQuery
>;
export type ListAgencyCustomerCertificatesLazyQueryHookResult = ReturnType<
  typeof useListAgencyCustomerCertificatesLazyQuery
>;
export type ListAgencyCustomerCertificatesSuspenseQueryHookResult = ReturnType<
  typeof useListAgencyCustomerCertificatesSuspenseQuery
>;
export type ListAgencyCustomerCertificatesQueryResult = Apollo.QueryResult<
  Types.ListAgencyCustomerCertificatesQuery,
  Types.ListAgencyCustomerCertificatesQueryVariables
>;
export const CreateAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'defaultContactId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cutOff' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InvoiceCutOffEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'monthlyCutOff' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'billingWeek' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'BillingWeekEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'consolidateUnprocessedCharges' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InvoiceGroupByEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'markupPercent' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rateType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AccountRateTypeEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'defaultContactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'defaultContactId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cutOff' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cutOff' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'monthlyCutOff' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'monthlyCutOff' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingWeek' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'billingWeek' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consolidateUnprocessedCharges' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'consolidateUnprocessedCharges',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'markupPercent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'markupPercent' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rateType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rateType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccountItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPaymentMethod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateAccountMutationFn = Apollo.MutationFunction<
  Types.CreateAccountMutation,
  Types.CreateAccountMutationVariables
>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      defaultContactId: // value for 'defaultContactId'
 *      name: // value for 'name'
 *      city: // value for 'city'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      zip: // value for 'zip'
 *      state: // value for 'state'
 *      cutOff: // value for 'cutOff'
 *      monthlyCutOff: // value for 'monthlyCutOff'
 *      billingWeek: // value for 'billingWeek'
 *      consolidateUnprocessedCharges: // value for 'consolidateUnprocessedCharges'
 *      groupBy: // value for 'groupBy'
 *      markupPercent: // value for 'markupPercent'
 *      rateType: // value for 'rateType'
 *   },
 * });
 */
export function useCreateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateAccountMutation,
    Types.CreateAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateAccountMutation,
    Types.CreateAccountMutationVariables
  >(CreateAccountDocument, options);
}
export type CreateAccountMutationHookResult = ReturnType<
  typeof useCreateAccountMutation
>;
export type CreateAccountMutationResult =
  Apollo.MutationResult<Types.CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAccountMutation,
  Types.CreateAccountMutationVariables
>;
export const ActiveAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActiveAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressValidate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine2' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coords' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latitude' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longitude' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ActiveAddressMutationFn = Apollo.MutationFunction<
  Types.ActiveAddressMutation,
  Types.ActiveAddressMutationVariables
>;

/**
 * __useActiveAddressMutation__
 *
 * To run a mutation, you first call `useActiveAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeAddressMutation, { data, loading, error }] = useActiveAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useActiveAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ActiveAddressMutation,
    Types.ActiveAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ActiveAddressMutation,
    Types.ActiveAddressMutationVariables
  >(ActiveAddressDocument, options);
}
export type ActiveAddressMutationHookResult = ReturnType<
  typeof useActiveAddressMutation
>;
export type ActiveAddressMutationResult =
  Apollo.MutationResult<Types.ActiveAddressMutation>;
export type ActiveAddressMutationOptions = Apollo.BaseMutationOptions<
  Types.ActiveAddressMutation,
  Types.ActiveAddressMutationVariables
>;
export const CreateAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'neighborhood' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'coords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PointInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'neighborhood' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'neighborhood' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coords' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'coords' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AddressItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pay' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateAddressMutationFn = Apollo.MutationFunction<
  Types.CreateAddressMutation,
  Types.CreateAddressMutationVariables
>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      neighborhood: // value for 'neighborhood'
 *      coords: // value for 'coords'
 *      name: // value for 'name'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useCreateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateAddressMutation,
    Types.CreateAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateAddressMutation,
    Types.CreateAddressMutationVariables
  >(CreateAddressDocument, options);
}
export type CreateAddressMutationHookResult = ReturnType<
  typeof useCreateAddressMutation
>;
export type CreateAddressMutationResult =
  Apollo.MutationResult<Types.CreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAddressMutation,
  Types.CreateAddressMutationVariables
>;
export const DeleteAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeleteAddressMutationFn = Apollo.MutationFunction<
  Types.DeleteAddressMutation,
  Types.DeleteAddressMutationVariables
>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeleteAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteAddressMutation,
    Types.DeleteAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteAddressMutation,
    Types.DeleteAddressMutationVariables
  >(DeleteAddressDocument, options);
}
export type DeleteAddressMutationHookResult = ReturnType<
  typeof useDeleteAddressMutation
>;
export type DeleteAddressMutationResult =
  Apollo.MutationResult<Types.DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteAddressMutation,
  Types.DeleteAddressMutationVariables
>;
export const UpdateAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine2' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'neighborhood' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'coords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PointInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'neighborhood' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'neighborhood' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coords' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'coords' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine2' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coords' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latitude' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longitude' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateAddressMutationFn = Apollo.MutationFunction<
  Types.UpdateAddressMutation,
  Types.UpdateAddressMutationVariables
>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      neighborhood: // value for 'neighborhood'
 *      coords: // value for 'coords'
 *      name: // value for 'name'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateAddressMutation,
    Types.UpdateAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateAddressMutation,
    Types.UpdateAddressMutationVariables
  >(UpdateAddressDocument, options);
}
export type UpdateAddressMutationHookResult = ReturnType<
  typeof useUpdateAddressMutation
>;
export type UpdateAddressMutationResult =
  Apollo.MutationResult<Types.UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateAddressMutation,
  Types.UpdateAddressMutationVariables
>;
export const GetCustomersAdminsPaginetedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomersAdminsPagineted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admins' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AdminItem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCustomersAdminsPaginetedQuery__
 *
 * To run a query within a React component, call `useGetCustomersAdminsPaginetedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersAdminsPaginetedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersAdminsPaginetedQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetCustomersAdminsPaginetedQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCustomersAdminsPaginetedQuery,
    Types.GetCustomersAdminsPaginetedQueryVariables
  > &
    (
      | {
          variables: Types.GetCustomersAdminsPaginetedQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCustomersAdminsPaginetedQuery,
    Types.GetCustomersAdminsPaginetedQueryVariables
  >(GetCustomersAdminsPaginetedDocument, options);
}
export function useGetCustomersAdminsPaginetedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCustomersAdminsPaginetedQuery,
    Types.GetCustomersAdminsPaginetedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCustomersAdminsPaginetedQuery,
    Types.GetCustomersAdminsPaginetedQueryVariables
  >(GetCustomersAdminsPaginetedDocument, options);
}
export function useGetCustomersAdminsPaginetedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetCustomersAdminsPaginetedQuery,
    Types.GetCustomersAdminsPaginetedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetCustomersAdminsPaginetedQuery,
    Types.GetCustomersAdminsPaginetedQueryVariables
  >(GetCustomersAdminsPaginetedDocument, options);
}
export type GetCustomersAdminsPaginetedQueryHookResult = ReturnType<
  typeof useGetCustomersAdminsPaginetedQuery
>;
export type GetCustomersAdminsPaginetedLazyQueryHookResult = ReturnType<
  typeof useGetCustomersAdminsPaginetedLazyQuery
>;
export type GetCustomersAdminsPaginetedSuspenseQueryHookResult = ReturnType<
  typeof useGetCustomersAdminsPaginetedSuspenseQuery
>;
export type GetCustomersAdminsPaginetedQueryResult = Apollo.QueryResult<
  Types.GetCustomersAdminsPaginetedQuery,
  Types.GetCustomersAdminsPaginetedQueryVariables
>;
export const InviteAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerInviteAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerAdmin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InviteAdminMutationFn = Apollo.MutationFunction<
  Types.InviteAdminMutation,
  Types.InviteAdminMutationVariables
>;

/**
 * __useInviteAdminMutation__
 *
 * To run a mutation, you first call `useInviteAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteAdminMutation, { data, loading, error }] = useInviteAdminMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useInviteAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.InviteAdminMutation,
    Types.InviteAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.InviteAdminMutation,
    Types.InviteAdminMutationVariables
  >(InviteAdminDocument, options);
}
export type InviteAdminMutationHookResult = ReturnType<
  typeof useInviteAdminMutation
>;
export type InviteAdminMutationResult =
  Apollo.MutationResult<Types.InviteAdminMutation>;
export type InviteAdminMutationOptions = Apollo.BaseMutationOptions<
  Types.InviteAdminMutation,
  Types.InviteAdminMutationVariables
>;
export const CreateRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'baseCost' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'basePay' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rateCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseCost' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'baseCost' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'basePay' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'basePay' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceAreaId' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'level' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pay' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine1' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateRateMutationFn = Apollo.MutationFunction<
  Types.CreateRateMutation,
  Types.CreateRateMutationVariables
>;

/**
 * __useCreateRateMutation__
 *
 * To run a mutation, you first call `useCreateRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRateMutation, { data, loading, error }] = useCreateRateMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      skillId: // value for 'skillId'
 *      accountId: // value for 'accountId'
 *      addressId: // value for 'addressId'
 *      baseCost: // value for 'baseCost'
 *      basePay: // value for 'basePay'
 *   },
 * });
 */
export function useCreateRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateRateMutation,
    Types.CreateRateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateRateMutation,
    Types.CreateRateMutationVariables
  >(CreateRateDocument, options);
}
export type CreateRateMutationHookResult = ReturnType<
  typeof useCreateRateMutation
>;
export type CreateRateMutationResult =
  Apollo.MutationResult<Types.CreateRateMutation>;
export type CreateRateMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateRateMutation,
  Types.CreateRateMutationVariables
>;
export const CustomRateCreateBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CustomRateCreateBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'baseCost' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'basePay' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customRateCreateBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseCost' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'baseCost' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'basePay' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'basePay' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceAreaId' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'level' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zipCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zipCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'allCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CustomRateCreateBatchMutationFn = Apollo.MutationFunction<
  Types.CustomRateCreateBatchMutation,
  Types.CustomRateCreateBatchMutationVariables
>;

/**
 * __useCustomRateCreateBatchMutation__
 *
 * To run a mutation, you first call `useCustomRateCreateBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomRateCreateBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customRateCreateBatchMutation, { data, loading, error }] = useCustomRateCreateBatchMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      agencyId: // value for 'agencyId'
 *      baseCost: // value for 'baseCost'
 *      basePay: // value for 'basePay'
 *      skillId: // value for 'skillId'
 *      state: // value for 'state'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useCustomRateCreateBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CustomRateCreateBatchMutation,
    Types.CustomRateCreateBatchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CustomRateCreateBatchMutation,
    Types.CustomRateCreateBatchMutationVariables
  >(CustomRateCreateBatchDocument, options);
}
export type CustomRateCreateBatchMutationHookResult = ReturnType<
  typeof useCustomRateCreateBatchMutation
>;
export type CustomRateCreateBatchMutationResult =
  Apollo.MutationResult<Types.CustomRateCreateBatchMutation>;
export type CustomRateCreateBatchMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomRateCreateBatchMutation,
  Types.CustomRateCreateBatchMutationVariables
>;
export const DeleteRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rateDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeleteRateMutationFn = Apollo.MutationFunction<
  Types.DeleteRateMutation,
  Types.DeleteRateMutationVariables
>;

/**
 * __useDeleteRateMutation__
 *
 * To run a mutation, you first call `useDeleteRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRateMutation, { data, loading, error }] = useDeleteRateMutation({
 *   variables: {
 *      rateId: // value for 'rateId'
 *   },
 * });
 */
export function useDeleteRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteRateMutation,
    Types.DeleteRateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteRateMutation,
    Types.DeleteRateMutationVariables
  >(DeleteRateDocument, options);
}
export type DeleteRateMutationHookResult = ReturnType<
  typeof useDeleteRateMutation
>;
export type DeleteRateMutationResult =
  Apollo.MutationResult<Types.DeleteRateMutation>;
export type DeleteRateMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteRateMutation,
  Types.DeleteRateMutationVariables
>;
export const UpdateRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'baseCost' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'basePay' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rateUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseCost' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'baseCost' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'basePay' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'basePay' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pay' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine1' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateRateMutationFn = Apollo.MutationFunction<
  Types.UpdateRateMutation,
  Types.UpdateRateMutationVariables
>;

/**
 * __useUpdateRateMutation__
 *
 * To run a mutation, you first call `useUpdateRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRateMutation, { data, loading, error }] = useUpdateRateMutation({
 *   variables: {
 *      rateId: // value for 'rateId'
 *      baseCost: // value for 'baseCost'
 *      basePay: // value for 'basePay'
 *   },
 * });
 */
export function useUpdateRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateRateMutation,
    Types.UpdateRateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateRateMutation,
    Types.UpdateRateMutationVariables
  >(UpdateRateDocument, options);
}
export type UpdateRateMutationHookResult = ReturnType<
  typeof useUpdateRateMutation
>;
export type UpdateRateMutationResult =
  Apollo.MutationResult<Types.UpdateRateMutation>;
export type UpdateRateMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateRateMutation,
  Types.UpdateRateMutationVariables
>;
export const GetCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoiceDueDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTaxType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ltaAllowed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markupPercent' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checkInRadiusFeet' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccountItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificateType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admins' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AdminItem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workerRelationships' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WorkerRelationshipItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine2' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobsCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coords' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latitude' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longitude' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pay' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine1',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zip' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timezone' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPaymentMethod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerRelationshipItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkerRelationship' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  > &
    (
      | { variables: Types.GetCustomerQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  >(GetCustomerDocument, options);
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  >(GetCustomerDocument, options);
}
export function useGetCustomerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  >(GetCustomerDocument, options);
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<
  typeof useGetCustomerLazyQuery
>;
export type GetCustomerSuspenseQueryHookResult = ReturnType<
  typeof useGetCustomerSuspenseQuery
>;
export type GetCustomerQueryResult = Apollo.QueryResult<
  Types.GetCustomerQuery,
  Types.GetCustomerQueryVariables
>;
export const GetCustomerInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoices' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'total' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdDatetime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dueDateDatetime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dueDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCustomerInvoiceQuery__
 *
 * To run a query within a React component, call `useGetCustomerInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerInvoiceQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetCustomerInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCustomerInvoiceQuery,
    Types.GetCustomerInvoiceQueryVariables
  > &
    (
      | { variables: Types.GetCustomerInvoiceQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCustomerInvoiceQuery,
    Types.GetCustomerInvoiceQueryVariables
  >(GetCustomerInvoiceDocument, options);
}
export function useGetCustomerInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCustomerInvoiceQuery,
    Types.GetCustomerInvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCustomerInvoiceQuery,
    Types.GetCustomerInvoiceQueryVariables
  >(GetCustomerInvoiceDocument, options);
}
export function useGetCustomerInvoiceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetCustomerInvoiceQuery,
    Types.GetCustomerInvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetCustomerInvoiceQuery,
    Types.GetCustomerInvoiceQueryVariables
  >(GetCustomerInvoiceDocument, options);
}
export type GetCustomerInvoiceQueryHookResult = ReturnType<
  typeof useGetCustomerInvoiceQuery
>;
export type GetCustomerInvoiceLazyQueryHookResult = ReturnType<
  typeof useGetCustomerInvoiceLazyQuery
>;
export type GetCustomerInvoiceSuspenseQueryHookResult = ReturnType<
  typeof useGetCustomerInvoiceSuspenseQuery
>;
export type GetCustomerInvoiceQueryResult = Apollo.QueryResult<
  Types.GetCustomerInvoiceQuery,
  Types.GetCustomerInvoiceQueryVariables
>;
export const GetCustomerOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'OrderListItem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobWorkers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'showed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalStatus' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCustomerOrdersQuery__
 *
 * To run a query within a React component, call `useGetCustomerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerOrdersQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetCustomerOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCustomerOrdersQuery,
    Types.GetCustomerOrdersQueryVariables
  > &
    (
      | { variables: Types.GetCustomerOrdersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCustomerOrdersQuery,
    Types.GetCustomerOrdersQueryVariables
  >(GetCustomerOrdersDocument, options);
}
export function useGetCustomerOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCustomerOrdersQuery,
    Types.GetCustomerOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCustomerOrdersQuery,
    Types.GetCustomerOrdersQueryVariables
  >(GetCustomerOrdersDocument, options);
}
export function useGetCustomerOrdersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetCustomerOrdersQuery,
    Types.GetCustomerOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetCustomerOrdersQuery,
    Types.GetCustomerOrdersQueryVariables
  >(GetCustomerOrdersDocument, options);
}
export type GetCustomerOrdersQueryHookResult = ReturnType<
  typeof useGetCustomerOrdersQuery
>;
export type GetCustomerOrdersLazyQueryHookResult = ReturnType<
  typeof useGetCustomerOrdersLazyQuery
>;
export type GetCustomerOrdersSuspenseQueryHookResult = ReturnType<
  typeof useGetCustomerOrdersSuspenseQuery
>;
export type GetCustomerOrdersQueryResult = Apollo.QueryResult<
  Types.GetCustomerOrdersQuery,
  Types.GetCustomerOrdersQueryVariables
>;
export const ListAgencyBadgeCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAgencyBadgeCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'badgeCertificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificateType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListAgencyBadgeCertificateQuery__
 *
 * To run a query within a React component, call `useListAgencyBadgeCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgencyBadgeCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgencyBadgeCertificateQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useListAgencyBadgeCertificateQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListAgencyBadgeCertificateQuery,
    Types.ListAgencyBadgeCertificateQueryVariables
  > &
    (
      | {
          variables: Types.ListAgencyBadgeCertificateQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListAgencyBadgeCertificateQuery,
    Types.ListAgencyBadgeCertificateQueryVariables
  >(ListAgencyBadgeCertificateDocument, options);
}
export function useListAgencyBadgeCertificateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListAgencyBadgeCertificateQuery,
    Types.ListAgencyBadgeCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListAgencyBadgeCertificateQuery,
    Types.ListAgencyBadgeCertificateQueryVariables
  >(ListAgencyBadgeCertificateDocument, options);
}
export function useListAgencyBadgeCertificateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListAgencyBadgeCertificateQuery,
    Types.ListAgencyBadgeCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListAgencyBadgeCertificateQuery,
    Types.ListAgencyBadgeCertificateQueryVariables
  >(ListAgencyBadgeCertificateDocument, options);
}
export type ListAgencyBadgeCertificateQueryHookResult = ReturnType<
  typeof useListAgencyBadgeCertificateQuery
>;
export type ListAgencyBadgeCertificateLazyQueryHookResult = ReturnType<
  typeof useListAgencyBadgeCertificateLazyQuery
>;
export type ListAgencyBadgeCertificateSuspenseQueryHookResult = ReturnType<
  typeof useListAgencyBadgeCertificateSuspenseQuery
>;
export type ListAgencyBadgeCertificateQueryResult = Apollo.QueryResult<
  Types.ListAgencyBadgeCertificateQuery,
  Types.ListAgencyBadgeCertificateQueryVariables
>;
export const UpdateCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceDueDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DefaultDueDateEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taxType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaxTypeEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ltaAllowed' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'markupPercent' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'checkInRadiusFeet' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'certificateIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceDueDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceDueDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobTaxType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taxType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ltaAllowed' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ltaAllowed' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'markupPercent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'markupPercent' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkInRadiusFeet' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'checkInRadiusFeet' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'certificateIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoiceDueDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  Types.UpdateCustomerMutation,
  Types.UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      invoiceDueDate: // value for 'invoiceDueDate'
 *      name: // value for 'name'
 *      taxType: // value for 'taxType'
 *      ltaAllowed: // value for 'ltaAllowed'
 *      markupPercent: // value for 'markupPercent'
 *      checkInRadiusFeet: // value for 'checkInRadiusFeet'
 *      certificateIds: // value for 'certificateIds'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCustomerMutation,
    Types.UpdateCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateCustomerMutation,
    Types.UpdateCustomerMutationVariables
  >(UpdateCustomerDocument, options);
}
export type UpdateCustomerMutationHookResult = ReturnType<
  typeof useUpdateCustomerMutation
>;
export type UpdateCustomerMutationResult =
  Apollo.MutationResult<Types.UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCustomerMutation,
  Types.UpdateCustomerMutationVariables
>;
export const UpdateJobTaxTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateJobTaxType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taxType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaxTypeEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerUpdateJobTaxType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobTaxType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taxType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTaxType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateJobTaxTypeMutationFn = Apollo.MutationFunction<
  Types.UpdateJobTaxTypeMutation,
  Types.UpdateJobTaxTypeMutationVariables
>;

/**
 * __useUpdateJobTaxTypeMutation__
 *
 * To run a mutation, you first call `useUpdateJobTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobTaxTypeMutation, { data, loading, error }] = useUpdateJobTaxTypeMutation({
 *   variables: {
 *      taxType: // value for 'taxType'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUpdateJobTaxTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateJobTaxTypeMutation,
    Types.UpdateJobTaxTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateJobTaxTypeMutation,
    Types.UpdateJobTaxTypeMutationVariables
  >(UpdateJobTaxTypeDocument, options);
}
export type UpdateJobTaxTypeMutationHookResult = ReturnType<
  typeof useUpdateJobTaxTypeMutation
>;
export type UpdateJobTaxTypeMutationResult =
  Apollo.MutationResult<Types.UpdateJobTaxTypeMutation>;
export type UpdateJobTaxTypeMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateJobTaxTypeMutation,
  Types.UpdateJobTaxTypeMutationVariables
>;
export const CreateCustomerWorkerRelationshipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCustomerWorkerRelationship' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kind' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RelationshipKindEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comments' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reason' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerRelateWorker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'kind' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'kind' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comments' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'comments' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reason' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workerRelationship' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'worker' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine1' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'phoneNumber',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateCustomerWorkerRelationshipMutationFn =
  Apollo.MutationFunction<
    Types.CreateCustomerWorkerRelationshipMutation,
    Types.CreateCustomerWorkerRelationshipMutationVariables
  >;

/**
 * __useCreateCustomerWorkerRelationshipMutation__
 *
 * To run a mutation, you first call `useCreateCustomerWorkerRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerWorkerRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerWorkerRelationshipMutation, { data, loading, error }] = useCreateCustomerWorkerRelationshipMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      kind: // value for 'kind'
 *      workerId: // value for 'workerId'
 *      comments: // value for 'comments'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateCustomerWorkerRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCustomerWorkerRelationshipMutation,
    Types.CreateCustomerWorkerRelationshipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateCustomerWorkerRelationshipMutation,
    Types.CreateCustomerWorkerRelationshipMutationVariables
  >(CreateCustomerWorkerRelationshipDocument, options);
}
export type CreateCustomerWorkerRelationshipMutationHookResult = ReturnType<
  typeof useCreateCustomerWorkerRelationshipMutation
>;
export type CreateCustomerWorkerRelationshipMutationResult =
  Apollo.MutationResult<Types.CreateCustomerWorkerRelationshipMutation>;
export type CreateCustomerWorkerRelationshipMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CreateCustomerWorkerRelationshipMutation,
    Types.CreateCustomerWorkerRelationshipMutationVariables
  >;
export const RemoveCustomerWorkerRelationshipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveCustomerWorkerRelationship' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kind' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RelationshipKindEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerUnrelateWorker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'kind' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'kind' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RemoveCustomerWorkerRelationshipMutationFn =
  Apollo.MutationFunction<
    Types.RemoveCustomerWorkerRelationshipMutation,
    Types.RemoveCustomerWorkerRelationshipMutationVariables
  >;

/**
 * __useRemoveCustomerWorkerRelationshipMutation__
 *
 * To run a mutation, you first call `useRemoveCustomerWorkerRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomerWorkerRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomerWorkerRelationshipMutation, { data, loading, error }] = useRemoveCustomerWorkerRelationshipMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      workerId: // value for 'workerId'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useRemoveCustomerWorkerRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RemoveCustomerWorkerRelationshipMutation,
    Types.RemoveCustomerWorkerRelationshipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RemoveCustomerWorkerRelationshipMutation,
    Types.RemoveCustomerWorkerRelationshipMutationVariables
  >(RemoveCustomerWorkerRelationshipDocument, options);
}
export type RemoveCustomerWorkerRelationshipMutationHookResult = ReturnType<
  typeof useRemoveCustomerWorkerRelationshipMutation
>;
export type RemoveCustomerWorkerRelationshipMutationResult =
  Apollo.MutationResult<Types.RemoveCustomerWorkerRelationshipMutation>;
export type RemoveCustomerWorkerRelationshipMutationOptions =
  Apollo.BaseMutationOptions<
    Types.RemoveCustomerWorkerRelationshipMutation,
    Types.RemoveCustomerWorkerRelationshipMutationVariables
  >;
export const ChangeCustomerLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeCustomerLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'logoKey' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerChangeLogo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logoKey' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'logoKey' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logoUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ChangeCustomerLogoMutationFn = Apollo.MutationFunction<
  Types.ChangeCustomerLogoMutation,
  Types.ChangeCustomerLogoMutationVariables
>;

/**
 * __useChangeCustomerLogoMutation__
 *
 * To run a mutation, you first call `useChangeCustomerLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCustomerLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCustomerLogoMutation, { data, loading, error }] = useChangeCustomerLogoMutation({
 *   variables: {
 *      logoKey: // value for 'logoKey'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useChangeCustomerLogoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ChangeCustomerLogoMutation,
    Types.ChangeCustomerLogoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ChangeCustomerLogoMutation,
    Types.ChangeCustomerLogoMutationVariables
  >(ChangeCustomerLogoDocument, options);
}
export type ChangeCustomerLogoMutationHookResult = ReturnType<
  typeof useChangeCustomerLogoMutation
>;
export type ChangeCustomerLogoMutationResult =
  Apollo.MutationResult<Types.ChangeCustomerLogoMutation>;
export type ChangeCustomerLogoMutationOptions = Apollo.BaseMutationOptions<
  Types.ChangeCustomerLogoMutation,
  Types.ChangeCustomerLogoMutationVariables
>;
export const UpdateCustomerAdminProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomerAdminProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerAdminId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAdminUpdateProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerAdminId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerAdminId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'title' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerAdmin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserItemFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserItemFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationStats' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateCustomerAdminProfileMutationFn = Apollo.MutationFunction<
  Types.UpdateCustomerAdminProfileMutation,
  Types.UpdateCustomerAdminProfileMutationVariables
>;

/**
 * __useUpdateCustomerAdminProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerAdminProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerAdminProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerAdminProfileMutation, { data, loading, error }] = useUpdateCustomerAdminProfileMutation({
 *   variables: {
 *      customerAdminId: // value for 'customerAdminId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      title: // value for 'title'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateCustomerAdminProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCustomerAdminProfileMutation,
    Types.UpdateCustomerAdminProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateCustomerAdminProfileMutation,
    Types.UpdateCustomerAdminProfileMutationVariables
  >(UpdateCustomerAdminProfileDocument, options);
}
export type UpdateCustomerAdminProfileMutationHookResult = ReturnType<
  typeof useUpdateCustomerAdminProfileMutation
>;
export type UpdateCustomerAdminProfileMutationResult =
  Apollo.MutationResult<Types.UpdateCustomerAdminProfileMutation>;
export type UpdateCustomerAdminProfileMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UpdateCustomerAdminProfileMutation,
    Types.UpdateCustomerAdminProfileMutationVariables
  >;
export const GetAdminCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdminCustomer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerAdminId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerAdminId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerAdminId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logoUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserItemFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserItemFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationStats' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetAdminCustomerQuery__
 *
 * To run a query within a React component, call `useGetAdminCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminCustomerQuery({
 *   variables: {
 *      customerAdminId: // value for 'customerAdminId'
 *   },
 * });
 */
export function useGetAdminCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetAdminCustomerQuery,
    Types.GetAdminCustomerQueryVariables
  > &
    (
      | { variables: Types.GetAdminCustomerQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetAdminCustomerQuery,
    Types.GetAdminCustomerQueryVariables
  >(GetAdminCustomerDocument, options);
}
export function useGetAdminCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetAdminCustomerQuery,
    Types.GetAdminCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetAdminCustomerQuery,
    Types.GetAdminCustomerQueryVariables
  >(GetAdminCustomerDocument, options);
}
export function useGetAdminCustomerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetAdminCustomerQuery,
    Types.GetAdminCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetAdminCustomerQuery,
    Types.GetAdminCustomerQueryVariables
  >(GetAdminCustomerDocument, options);
}
export type GetAdminCustomerQueryHookResult = ReturnType<
  typeof useGetAdminCustomerQuery
>;
export type GetAdminCustomerLazyQueryHookResult = ReturnType<
  typeof useGetAdminCustomerLazyQuery
>;
export type GetAdminCustomerSuspenseQueryHookResult = ReturnType<
  typeof useGetAdminCustomerSuspenseQuery
>;
export type GetAdminCustomerQueryResult = Apollo.QueryResult<
  Types.GetAdminCustomerQuery,
  Types.GetAdminCustomerQueryVariables
>;
export const ResendCustomerAdminInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendCustomerAdminInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAdminResendInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ResendCustomerAdminInvitationMutationFn = Apollo.MutationFunction<
  Types.ResendCustomerAdminInvitationMutation,
  Types.ResendCustomerAdminInvitationMutationVariables
>;

/**
 * __useResendCustomerAdminInvitationMutation__
 *
 * To run a mutation, you first call `useResendCustomerAdminInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCustomerAdminInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCustomerAdminInvitationMutation, { data, loading, error }] = useResendCustomerAdminInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendCustomerAdminInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ResendCustomerAdminInvitationMutation,
    Types.ResendCustomerAdminInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ResendCustomerAdminInvitationMutation,
    Types.ResendCustomerAdminInvitationMutationVariables
  >(ResendCustomerAdminInvitationDocument, options);
}
export type ResendCustomerAdminInvitationMutationHookResult = ReturnType<
  typeof useResendCustomerAdminInvitationMutation
>;
export type ResendCustomerAdminInvitationMutationResult =
  Apollo.MutationResult<Types.ResendCustomerAdminInvitationMutation>;
export type ResendCustomerAdminInvitationMutationOptions =
  Apollo.BaseMutationOptions<
    Types.ResendCustomerAdminInvitationMutation,
    Types.ResendCustomerAdminInvitationMutationVariables
  >;
export const ChangeCustomerAdminAvatarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeCustomerAdminAvatar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'avatarKey' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerAdminId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAdminChangeAvatar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerAdminId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerAdminId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'avatarKey' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'avatarKey' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerAdmin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ChangeCustomerAdminAvatarMutationFn = Apollo.MutationFunction<
  Types.ChangeCustomerAdminAvatarMutation,
  Types.ChangeCustomerAdminAvatarMutationVariables
>;

/**
 * __useChangeCustomerAdminAvatarMutation__
 *
 * To run a mutation, you first call `useChangeCustomerAdminAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCustomerAdminAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCustomerAdminAvatarMutation, { data, loading, error }] = useChangeCustomerAdminAvatarMutation({
 *   variables: {
 *      avatarKey: // value for 'avatarKey'
 *      customerAdminId: // value for 'customerAdminId'
 *   },
 * });
 */
export function useChangeCustomerAdminAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ChangeCustomerAdminAvatarMutation,
    Types.ChangeCustomerAdminAvatarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ChangeCustomerAdminAvatarMutation,
    Types.ChangeCustomerAdminAvatarMutationVariables
  >(ChangeCustomerAdminAvatarDocument, options);
}
export type ChangeCustomerAdminAvatarMutationHookResult = ReturnType<
  typeof useChangeCustomerAdminAvatarMutation
>;
export type ChangeCustomerAdminAvatarMutationResult =
  Apollo.MutationResult<Types.ChangeCustomerAdminAvatarMutation>;
export type ChangeCustomerAdminAvatarMutationOptions =
  Apollo.BaseMutationOptions<
    Types.ChangeCustomerAdminAvatarMutation,
    Types.ChangeCustomerAdminAvatarMutationVariables
  >;
export const CreateCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCustomer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateCustomerMutationFn = Apollo.MutationFunction<
  Types.CreateCustomerMutation,
  Types.CreateCustomerMutationVariables
>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCustomerMutation,
    Types.CreateCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateCustomerMutation,
    Types.CreateCustomerMutationVariables
  >(CreateCustomerDocument, options);
}
export type CreateCustomerMutationHookResult = ReturnType<
  typeof useCreateCustomerMutation
>;
export type CreateCustomerMutationResult =
  Apollo.MutationResult<Types.CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCustomerMutation,
  Types.CreateCustomerMutationVariables
>;
export const RegionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Region' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTaxType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegionQuery__
 *
 * To run a query within a React component, call `useRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useRegionQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.RegionQuery,
    Types.RegionQueryVariables
  > &
    (
      | { variables: Types.RegionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.RegionQuery, Types.RegionQueryVariables>(
    RegionDocument,
    options,
  );
}
export function useRegionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RegionQuery,
    Types.RegionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.RegionQuery, Types.RegionQueryVariables>(
    RegionDocument,
    options,
  );
}
export function useRegionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.RegionQuery,
    Types.RegionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.RegionQuery, Types.RegionQueryVariables>(
    RegionDocument,
    options,
  );
}
export type RegionQueryHookResult = ReturnType<typeof useRegionQuery>;
export type RegionLazyQueryHookResult = ReturnType<typeof useRegionLazyQuery>;
export type RegionSuspenseQueryHookResult = ReturnType<
  typeof useRegionSuspenseQuery
>;
export type RegionQueryResult = Apollo.QueryResult<
  Types.RegionQuery,
  Types.RegionQueryVariables
>;
export const ListCustomersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListCustomers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CustomerFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortKey' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CustomerSortKeyEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reverse' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sortKey' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sortKey' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reverse' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reverse' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logoUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onboardingStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobTaxType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accounts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListCustomersQuery__
 *
 * To run a query within a React component, call `useListCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomersQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortKey: // value for 'sortKey'
 *      reverse: // value for 'reverse'
 *   },
 * });
 */
export function useListCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListCustomersQuery,
    Types.ListCustomersQueryVariables
  > &
    (
      | { variables: Types.ListCustomersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListCustomersQuery,
    Types.ListCustomersQueryVariables
  >(ListCustomersDocument, options);
}
export function useListCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListCustomersQuery,
    Types.ListCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListCustomersQuery,
    Types.ListCustomersQueryVariables
  >(ListCustomersDocument, options);
}
export function useListCustomersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListCustomersQuery,
    Types.ListCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListCustomersQuery,
    Types.ListCustomersQueryVariables
  >(ListCustomersDocument, options);
}
export type ListCustomersQueryHookResult = ReturnType<
  typeof useListCustomersQuery
>;
export type ListCustomersLazyQueryHookResult = ReturnType<
  typeof useListCustomersLazyQuery
>;
export type ListCustomersSuspenseQueryHookResult = ReturnType<
  typeof useListCustomersSuspenseQuery
>;
export type ListCustomersQueryResult = Apollo.QueryResult<
  Types.ListCustomersQuery,
  Types.ListCustomersQueryVariables
>;
export const GetCustomerAndAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerAndAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CustomerFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: { kind: 'IntValue', value: '1000' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accounts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCustomerAndAccountsQuery__
 *
 * To run a query within a React component, call `useGetCustomerAndAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAndAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAndAccountsQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCustomerAndAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCustomerAndAccountsQuery,
    Types.GetCustomerAndAccountsQueryVariables
  > &
    (
      | {
          variables: Types.GetCustomerAndAccountsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCustomerAndAccountsQuery,
    Types.GetCustomerAndAccountsQueryVariables
  >(GetCustomerAndAccountsDocument, options);
}
export function useGetCustomerAndAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCustomerAndAccountsQuery,
    Types.GetCustomerAndAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCustomerAndAccountsQuery,
    Types.GetCustomerAndAccountsQueryVariables
  >(GetCustomerAndAccountsDocument, options);
}
export function useGetCustomerAndAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetCustomerAndAccountsQuery,
    Types.GetCustomerAndAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetCustomerAndAccountsQuery,
    Types.GetCustomerAndAccountsQueryVariables
  >(GetCustomerAndAccountsDocument, options);
}
export type GetCustomerAndAccountsQueryHookResult = ReturnType<
  typeof useGetCustomerAndAccountsQuery
>;
export type GetCustomerAndAccountsLazyQueryHookResult = ReturnType<
  typeof useGetCustomerAndAccountsLazyQuery
>;
export type GetCustomerAndAccountsSuspenseQueryHookResult = ReturnType<
  typeof useGetCustomerAndAccountsSuspenseQuery
>;
export type GetCustomerAndAccountsQueryResult = Apollo.QueryResult<
  Types.GetCustomerAndAccountsQuery,
  Types.GetCustomerAndAccountsQueryVariables
>;
export const GetCustomerAndSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerAndSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CustomerFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: { kind: 'IntValue', value: '1000' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logoUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skillCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCustomerAndSkillsQuery__
 *
 * To run a query within a React component, call `useGetCustomerAndSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAndSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAndSkillsQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCustomerAndSkillsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCustomerAndSkillsQuery,
    Types.GetCustomerAndSkillsQueryVariables
  > &
    (
      | { variables: Types.GetCustomerAndSkillsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCustomerAndSkillsQuery,
    Types.GetCustomerAndSkillsQueryVariables
  >(GetCustomerAndSkillsDocument, options);
}
export function useGetCustomerAndSkillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCustomerAndSkillsQuery,
    Types.GetCustomerAndSkillsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCustomerAndSkillsQuery,
    Types.GetCustomerAndSkillsQueryVariables
  >(GetCustomerAndSkillsDocument, options);
}
export function useGetCustomerAndSkillsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetCustomerAndSkillsQuery,
    Types.GetCustomerAndSkillsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetCustomerAndSkillsQuery,
    Types.GetCustomerAndSkillsQueryVariables
  >(GetCustomerAndSkillsDocument, options);
}
export type GetCustomerAndSkillsQueryHookResult = ReturnType<
  typeof useGetCustomerAndSkillsQuery
>;
export type GetCustomerAndSkillsLazyQueryHookResult = ReturnType<
  typeof useGetCustomerAndSkillsLazyQuery
>;
export type GetCustomerAndSkillsSuspenseQueryHookResult = ReturnType<
  typeof useGetCustomerAndSkillsSuspenseQuery
>;
export type GetCustomerAndSkillsQueryResult = Apollo.QueryResult<
  Types.GetCustomerAndSkillsQuery,
  Types.GetCustomerAndSkillsQueryVariables
>;
export const GetUserFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserFilter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserFilterTypeEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filterType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filterValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetUserFilterQuery__
 *
 * To run a query within a React component, call `useGetUserFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFilterQuery({
 *   variables: {
 *      filterType: // value for 'filterType'
 *   },
 * });
 */
export function useGetUserFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetUserFilterQuery,
    Types.GetUserFilterQueryVariables
  > &
    (
      | { variables: Types.GetUserFilterQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetUserFilterQuery,
    Types.GetUserFilterQueryVariables
  >(GetUserFilterDocument, options);
}
export function useGetUserFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetUserFilterQuery,
    Types.GetUserFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetUserFilterQuery,
    Types.GetUserFilterQueryVariables
  >(GetUserFilterDocument, options);
}
export function useGetUserFilterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetUserFilterQuery,
    Types.GetUserFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetUserFilterQuery,
    Types.GetUserFilterQueryVariables
  >(GetUserFilterDocument, options);
}
export type GetUserFilterQueryHookResult = ReturnType<
  typeof useGetUserFilterQuery
>;
export type GetUserFilterLazyQueryHookResult = ReturnType<
  typeof useGetUserFilterLazyQuery
>;
export type GetUserFilterSuspenseQueryHookResult = ReturnType<
  typeof useGetUserFilterSuspenseQuery
>;
export type GetUserFilterQueryResult = Apollo.QueryResult<
  Types.GetUserFilterQuery,
  Types.GetUserFilterQueryVariables
>;
export const UserFilterSaveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserFilterSave' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterValue' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserFilterTypeEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFilterSave' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterValue' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterValue' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'version' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UserFilterSaveMutationFn = Apollo.MutationFunction<
  Types.UserFilterSaveMutation,
  Types.UserFilterSaveMutationVariables
>;

/**
 * __useUserFilterSaveMutation__
 *
 * To run a mutation, you first call `useUserFilterSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFilterSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFilterSaveMutation, { data, loading, error }] = useUserFilterSaveMutation({
 *   variables: {
 *      filterValue: // value for 'filterValue'
 *      filterType: // value for 'filterType'
 *   },
 * });
 */
export function useUserFilterSaveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UserFilterSaveMutation,
    Types.UserFilterSaveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UserFilterSaveMutation,
    Types.UserFilterSaveMutationVariables
  >(UserFilterSaveDocument, options);
}
export type UserFilterSaveMutationHookResult = ReturnType<
  typeof useUserFilterSaveMutation
>;
export type UserFilterSaveMutationResult =
  Apollo.MutationResult<Types.UserFilterSaveMutation>;
export type UserFilterSaveMutationOptions = Apollo.BaseMutationOptions<
  Types.UserFilterSaveMutation,
  Types.UserFilterSaveMutationVariables
>;
export const ListShiftsByWeekDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListShiftsByWeek' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601Date' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timeZone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shiftsByWeek' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'timeZone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timeZone' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'hiredWorkersCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'published' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cancelledAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'needsApproval' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine1',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zip' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timezone' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uuid' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListShiftsByWeekQuery__
 *
 * To run a query within a React component, call `useListShiftsByWeekQuery` and pass it any options that fit your needs.
 * When your component renders, `useListShiftsByWeekQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListShiftsByWeekQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      startDate: // value for 'startDate'
 *      filters: // value for 'filters'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useListShiftsByWeekQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListShiftsByWeekQuery,
    Types.ListShiftsByWeekQueryVariables
  > &
    (
      | { variables: Types.ListShiftsByWeekQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListShiftsByWeekQuery,
    Types.ListShiftsByWeekQueryVariables
  >(ListShiftsByWeekDocument, options);
}
export function useListShiftsByWeekLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListShiftsByWeekQuery,
    Types.ListShiftsByWeekQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListShiftsByWeekQuery,
    Types.ListShiftsByWeekQueryVariables
  >(ListShiftsByWeekDocument, options);
}
export function useListShiftsByWeekSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListShiftsByWeekQuery,
    Types.ListShiftsByWeekQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListShiftsByWeekQuery,
    Types.ListShiftsByWeekQueryVariables
  >(ListShiftsByWeekDocument, options);
}
export type ListShiftsByWeekQueryHookResult = ReturnType<
  typeof useListShiftsByWeekQuery
>;
export type ListShiftsByWeekLazyQueryHookResult = ReturnType<
  typeof useListShiftsByWeekLazyQuery
>;
export type ListShiftsByWeekSuspenseQueryHookResult = ReturnType<
  typeof useListShiftsByWeekSuspenseQuery
>;
export type ListShiftsByWeekQueryResult = Apollo.QueryResult<
  Types.ListShiftsByWeekQuery,
  Types.ListShiftsByWeekQueryVariables
>;
export const NeedsApprovalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NeedsApproval' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601Date' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601Date' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsApproval' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tenantId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useNeedsApprovalQuery__
 *
 * To run a query within a React component, call `useNeedsApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useNeedsApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNeedsApprovalQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      tenantId: // value for 'tenantId'
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useNeedsApprovalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.NeedsApprovalQuery,
    Types.NeedsApprovalQueryVariables
  > &
    (
      | { variables: Types.NeedsApprovalQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.NeedsApprovalQuery,
    Types.NeedsApprovalQueryVariables
  >(NeedsApprovalDocument, options);
}
export function useNeedsApprovalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.NeedsApprovalQuery,
    Types.NeedsApprovalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.NeedsApprovalQuery,
    Types.NeedsApprovalQueryVariables
  >(NeedsApprovalDocument, options);
}
export function useNeedsApprovalSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.NeedsApprovalQuery,
    Types.NeedsApprovalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.NeedsApprovalQuery,
    Types.NeedsApprovalQueryVariables
  >(NeedsApprovalDocument, options);
}
export type NeedsApprovalQueryHookResult = ReturnType<
  typeof useNeedsApprovalQuery
>;
export type NeedsApprovalLazyQueryHookResult = ReturnType<
  typeof useNeedsApprovalLazyQuery
>;
export type NeedsApprovalSuspenseQueryHookResult = ReturnType<
  typeof useNeedsApprovalSuspenseQuery
>;
export type NeedsApprovalQueryResult = Apollo.QueryResult<
  Types.NeedsApprovalQuery,
  Types.NeedsApprovalQueryVariables
>;
export const GetAgencyByKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAgencyByKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Key' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agencyByKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tenantId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CurrentAgency' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentAgency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Agency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkinWindow' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxCheckinDistance' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetAgencyByKeyQuery__
 *
 * To run a query within a React component, call `useGetAgencyByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyByKeyQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetAgencyByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetAgencyByKeyQuery,
    Types.GetAgencyByKeyQueryVariables
  > &
    (
      | { variables: Types.GetAgencyByKeyQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetAgencyByKeyQuery,
    Types.GetAgencyByKeyQueryVariables
  >(GetAgencyByKeyDocument, options);
}
export function useGetAgencyByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetAgencyByKeyQuery,
    Types.GetAgencyByKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetAgencyByKeyQuery,
    Types.GetAgencyByKeyQueryVariables
  >(GetAgencyByKeyDocument, options);
}
export function useGetAgencyByKeySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetAgencyByKeyQuery,
    Types.GetAgencyByKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetAgencyByKeyQuery,
    Types.GetAgencyByKeyQueryVariables
  >(GetAgencyByKeyDocument, options);
}
export type GetAgencyByKeyQueryHookResult = ReturnType<
  typeof useGetAgencyByKeyQuery
>;
export type GetAgencyByKeyLazyQueryHookResult = ReturnType<
  typeof useGetAgencyByKeyLazyQuery
>;
export type GetAgencyByKeySuspenseQueryHookResult = ReturnType<
  typeof useGetAgencyByKeySuspenseQuery
>;
export type GetAgencyByKeyQueryResult = Apollo.QueryResult<
  Types.GetAgencyByKeyQuery,
  Types.GetAgencyByKeyQueryVariables
>;
export const CustomerListingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerListing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerStatus' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'NonNegativeInt' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'query' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'value' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'query' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'customerStatus' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobTaxType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accounts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'logoUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'defaultContact',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatarUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'user' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'firstName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'lastName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'email',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'phoneNumber',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currentPaymentMethod',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCustomerListingQuery__
 *
 * To run a query within a React component, call `useCustomerListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerListingQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      query: // value for 'query'
 *      customerStatus: // value for 'customerStatus'
 *   },
 * });
 */
export function useCustomerListingQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CustomerListingQuery,
    Types.CustomerListingQueryVariables
  > &
    (
      | { variables: Types.CustomerListingQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CustomerListingQuery,
    Types.CustomerListingQueryVariables
  >(CustomerListingDocument, options);
}
export function useCustomerListingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CustomerListingQuery,
    Types.CustomerListingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CustomerListingQuery,
    Types.CustomerListingQueryVariables
  >(CustomerListingDocument, options);
}
export function useCustomerListingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.CustomerListingQuery,
    Types.CustomerListingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.CustomerListingQuery,
    Types.CustomerListingQueryVariables
  >(CustomerListingDocument, options);
}
export type CustomerListingQueryHookResult = ReturnType<
  typeof useCustomerListingQuery
>;
export type CustomerListingLazyQueryHookResult = ReturnType<
  typeof useCustomerListingLazyQuery
>;
export type CustomerListingSuspenseQueryHookResult = ReturnType<
  typeof useCustomerListingSuspenseQuery
>;
export type CustomerListingQueryResult = Apollo.QueryResult<
  Types.CustomerListingQuery,
  Types.CustomerListingQueryVariables
>;
export const GenerateInvoiceTimesheetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GenerateInvoiceTimesheets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TimesheetFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateInvoiceTimesheets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'perPage' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvedCheckinAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tipAmount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'netApprovedTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'holdByInvoiceId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoiceItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isEdited' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cancelled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isOvertime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isHoliday' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'netTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timesheetInvoice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobWorker' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'worker' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'middleName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine1',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine2',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timezone' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zip' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGenerateInvoiceTimesheetsQuery__
 *
 * To run a query within a React component, call `useGenerateInvoiceTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateInvoiceTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateInvoiceTimesheetsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGenerateInvoiceTimesheetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GenerateInvoiceTimesheetsQuery,
    Types.GenerateInvoiceTimesheetsQueryVariables
  > &
    (
      | {
          variables: Types.GenerateInvoiceTimesheetsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GenerateInvoiceTimesheetsQuery,
    Types.GenerateInvoiceTimesheetsQueryVariables
  >(GenerateInvoiceTimesheetsDocument, options);
}
export function useGenerateInvoiceTimesheetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GenerateInvoiceTimesheetsQuery,
    Types.GenerateInvoiceTimesheetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GenerateInvoiceTimesheetsQuery,
    Types.GenerateInvoiceTimesheetsQueryVariables
  >(GenerateInvoiceTimesheetsDocument, options);
}
export function useGenerateInvoiceTimesheetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GenerateInvoiceTimesheetsQuery,
    Types.GenerateInvoiceTimesheetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GenerateInvoiceTimesheetsQuery,
    Types.GenerateInvoiceTimesheetsQueryVariables
  >(GenerateInvoiceTimesheetsDocument, options);
}
export type GenerateInvoiceTimesheetsQueryHookResult = ReturnType<
  typeof useGenerateInvoiceTimesheetsQuery
>;
export type GenerateInvoiceTimesheetsLazyQueryHookResult = ReturnType<
  typeof useGenerateInvoiceTimesheetsLazyQuery
>;
export type GenerateInvoiceTimesheetsSuspenseQueryHookResult = ReturnType<
  typeof useGenerateInvoiceTimesheetsSuspenseQuery
>;
export type GenerateInvoiceTimesheetsQueryResult = Apollo.QueryResult<
  Types.GenerateInvoiceTimesheetsQuery,
  Types.GenerateInvoiceTimesheetsQueryVariables
>;
export const InvoiceGenerateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InvoiceGenerate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timesheetIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoiceGenerate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesheetIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timesheetIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InvoiceGenerateMutationFn = Apollo.MutationFunction<
  Types.InvoiceGenerateMutation,
  Types.InvoiceGenerateMutationVariables
>;

/**
 * __useInvoiceGenerateMutation__
 *
 * To run a mutation, you first call `useInvoiceGenerateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceGenerateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceGenerateMutation, { data, loading, error }] = useInvoiceGenerateMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      accountId: // value for 'accountId'
 *      timesheetIds: // value for 'timesheetIds'
 *   },
 * });
 */
export function useInvoiceGenerateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.InvoiceGenerateMutation,
    Types.InvoiceGenerateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.InvoiceGenerateMutation,
    Types.InvoiceGenerateMutationVariables
  >(InvoiceGenerateDocument, options);
}
export type InvoiceGenerateMutationHookResult = ReturnType<
  typeof useInvoiceGenerateMutation
>;
export type InvoiceGenerateMutationResult =
  Apollo.MutationResult<Types.InvoiceGenerateMutation>;
export type InvoiceGenerateMutationOptions = Apollo.BaseMutationOptions<
  Types.InvoiceGenerateMutation,
  Types.InvoiceGenerateMutationVariables
>;
export const FinishDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FinishDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeInvoiceFinalize' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FinishDraftMutationFn = Apollo.MutationFunction<
  Types.FinishDraftMutation,
  Types.FinishDraftMutationVariables
>;

/**
 * __useFinishDraftMutation__
 *
 * To run a mutation, you first call `useFinishDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishDraftMutation, { data, loading, error }] = useFinishDraftMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useFinishDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.FinishDraftMutation,
    Types.FinishDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.FinishDraftMutation,
    Types.FinishDraftMutationVariables
  >(FinishDraftDocument, options);
}
export type FinishDraftMutationHookResult = ReturnType<
  typeof useFinishDraftMutation
>;
export type FinishDraftMutationResult =
  Apollo.MutationResult<Types.FinishDraftMutation>;
export type FinishDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.FinishDraftMutation,
  Types.FinishDraftMutationVariables
>;
export const VoidInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VoidInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeInvoiceVoid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type VoidInvoiceMutationFn = Apollo.MutationFunction<
  Types.VoidInvoiceMutation,
  Types.VoidInvoiceMutationVariables
>;

/**
 * __useVoidInvoiceMutation__
 *
 * To run a mutation, you first call `useVoidInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidInvoiceMutation, { data, loading, error }] = useVoidInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useVoidInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.VoidInvoiceMutation,
    Types.VoidInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.VoidInvoiceMutation,
    Types.VoidInvoiceMutationVariables
  >(VoidInvoiceDocument, options);
}
export type VoidInvoiceMutationHookResult = ReturnType<
  typeof useVoidInvoiceMutation
>;
export type VoidInvoiceMutationResult =
  Apollo.MutationResult<Types.VoidInvoiceMutation>;
export type VoidInvoiceMutationOptions = Apollo.BaseMutationOptions<
  Types.VoidInvoiceMutation,
  Types.VoidInvoiceMutationVariables
>;
export const EditInvoiceDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditInvoiceDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeInvoiceUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dueDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dueDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'description' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type EditInvoiceDetailMutationFn = Apollo.MutationFunction<
  Types.EditInvoiceDetailMutation,
  Types.EditInvoiceDetailMutationVariables
>;

/**
 * __useEditInvoiceDetailMutation__
 *
 * To run a mutation, you first call `useEditInvoiceDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInvoiceDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInvoiceDetailMutation, { data, loading, error }] = useEditInvoiceDetailMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      description: // value for 'description'
 *      dueDate: // value for 'dueDate'
 *   },
 * });
 */
export function useEditInvoiceDetailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.EditInvoiceDetailMutation,
    Types.EditInvoiceDetailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.EditInvoiceDetailMutation,
    Types.EditInvoiceDetailMutationVariables
  >(EditInvoiceDetailDocument, options);
}
export type EditInvoiceDetailMutationHookResult = ReturnType<
  typeof useEditInvoiceDetailMutation
>;
export type EditInvoiceDetailMutationResult =
  Apollo.MutationResult<Types.EditInvoiceDetailMutation>;
export type EditInvoiceDetailMutationOptions = Apollo.BaseMutationOptions<
  Types.EditInvoiceDetailMutation,
  Types.EditInvoiceDetailMutationVariables
>;
export const GetTimesheetPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetTimesheetPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoiceTimesheetsPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pdfCreated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type GetTimesheetPdfMutationFn = Apollo.MutationFunction<
  Types.GetTimesheetPdfMutation,
  Types.GetTimesheetPdfMutationVariables
>;

/**
 * __useGetTimesheetPdfMutation__
 *
 * To run a mutation, you first call `useGetTimesheetPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTimesheetPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTimesheetPdfMutation, { data, loading, error }] = useGetTimesheetPdfMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetTimesheetPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.GetTimesheetPdfMutation,
    Types.GetTimesheetPdfMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.GetTimesheetPdfMutation,
    Types.GetTimesheetPdfMutationVariables
  >(GetTimesheetPdfDocument, options);
}
export type GetTimesheetPdfMutationHookResult = ReturnType<
  typeof useGetTimesheetPdfMutation
>;
export type GetTimesheetPdfMutationResult =
  Apollo.MutationResult<Types.GetTimesheetPdfMutation>;
export type GetTimesheetPdfMutationOptions = Apollo.BaseMutationOptions<
  Types.GetTimesheetPdfMutation,
  Types.GetTimesheetPdfMutationVariables
>;
export const GetInvoiceDetailsRevampDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInvoiceDetailsRevamp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listStripeInvoiceDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'autoApprove' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stripeInvoiceId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amountDue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoicePdf' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultContactEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtotal' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoiceFinalizedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdDatetime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDateDatetime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hostedInvoiceUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoiceUid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentPaymentMethod',
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AccountFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoiceItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stripeCustomerId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isEdited' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'netTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancelled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isOvertime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isHoliday' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timesheetId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'netTimeMinutes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workerTimesheet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'job' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'timezone',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'approvedBreakMinutes',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'approvedCheckinAt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'approvedCheckoutAt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedByType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'worker' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomerItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cutOff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyCutOff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingWeek' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consolidateUnprocessedCharges' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'groupBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPaymentMethod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerRelationshipItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkerRelationship' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pay' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Customer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTaxType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ltaAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkInRadiusFeet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'admins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerRelationshipItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetInvoiceDetailsRevampQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDetailsRevampQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDetailsRevampQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDetailsRevampQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceDetailsRevampQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetInvoiceDetailsRevampQuery,
    Types.GetInvoiceDetailsRevampQueryVariables
  > &
    (
      | {
          variables: Types.GetInvoiceDetailsRevampQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetInvoiceDetailsRevampQuery,
    Types.GetInvoiceDetailsRevampQueryVariables
  >(GetInvoiceDetailsRevampDocument, options);
}
export function useGetInvoiceDetailsRevampLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetInvoiceDetailsRevampQuery,
    Types.GetInvoiceDetailsRevampQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetInvoiceDetailsRevampQuery,
    Types.GetInvoiceDetailsRevampQueryVariables
  >(GetInvoiceDetailsRevampDocument, options);
}
export function useGetInvoiceDetailsRevampSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetInvoiceDetailsRevampQuery,
    Types.GetInvoiceDetailsRevampQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetInvoiceDetailsRevampQuery,
    Types.GetInvoiceDetailsRevampQueryVariables
  >(GetInvoiceDetailsRevampDocument, options);
}
export type GetInvoiceDetailsRevampQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsRevampQuery
>;
export type GetInvoiceDetailsRevampLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsRevampLazyQuery
>;
export type GetInvoiceDetailsRevampSuspenseQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsRevampSuspenseQuery
>;
export type GetInvoiceDetailsRevampQueryResult = Apollo.QueryResult<
  Types.GetInvoiceDetailsRevampQuery,
  Types.GetInvoiceDetailsRevampQueryVariables
>;
export const DeleteInvoiceItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInvoiceItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeInvoiceItemDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceItemId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeleteInvoiceItemMutationFn = Apollo.MutationFunction<
  Types.DeleteInvoiceItemMutation,
  Types.DeleteInvoiceItemMutationVariables
>;

/**
 * __useDeleteInvoiceItemMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceItemMutation, { data, loading, error }] = useDeleteInvoiceItemMutation({
 *   variables: {
 *      invoiceItemId: // value for 'invoiceItemId'
 *   },
 * });
 */
export function useDeleteInvoiceItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteInvoiceItemMutation,
    Types.DeleteInvoiceItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteInvoiceItemMutation,
    Types.DeleteInvoiceItemMutationVariables
  >(DeleteInvoiceItemDocument, options);
}
export type DeleteInvoiceItemMutationHookResult = ReturnType<
  typeof useDeleteInvoiceItemMutation
>;
export type DeleteInvoiceItemMutationResult =
  Apollo.MutationResult<Types.DeleteInvoiceItemMutation>;
export type DeleteInvoiceItemMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteInvoiceItemMutation,
  Types.DeleteInvoiceItemMutationVariables
>;
export const AddInvoiceItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddInvoiceItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'amount' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeInvoiceItemCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'amount' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'description' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoiceItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AddInvoiceItemMutationFn = Apollo.MutationFunction<
  Types.AddInvoiceItemMutation,
  Types.AddInvoiceItemMutationVariables
>;

/**
 * __useAddInvoiceItemMutation__
 *
 * To run a mutation, you first call `useAddInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceItemMutation, { data, loading, error }] = useAddInvoiceItemMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      amount: // value for 'amount'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddInvoiceItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddInvoiceItemMutation,
    Types.AddInvoiceItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddInvoiceItemMutation,
    Types.AddInvoiceItemMutationVariables
  >(AddInvoiceItemDocument, options);
}
export type AddInvoiceItemMutationHookResult = ReturnType<
  typeof useAddInvoiceItemMutation
>;
export type AddInvoiceItemMutationResult =
  Apollo.MutationResult<Types.AddInvoiceItemMutation>;
export type AddInvoiceItemMutationOptions = Apollo.BaseMutationOptions<
  Types.AddInvoiceItemMutation,
  Types.AddInvoiceItemMutationVariables
>;
export const EditInvoiceItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditInvoiceItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'amount' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeInvoiceItemUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceItemId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'description' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'amount' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoiceItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type EditInvoiceItemMutationFn = Apollo.MutationFunction<
  Types.EditInvoiceItemMutation,
  Types.EditInvoiceItemMutationVariables
>;

/**
 * __useEditInvoiceItemMutation__
 *
 * To run a mutation, you first call `useEditInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInvoiceItemMutation, { data, loading, error }] = useEditInvoiceItemMutation({
 *   variables: {
 *      invoiceItemId: // value for 'invoiceItemId'
 *      description: // value for 'description'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useEditInvoiceItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.EditInvoiceItemMutation,
    Types.EditInvoiceItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.EditInvoiceItemMutation,
    Types.EditInvoiceItemMutationVariables
  >(EditInvoiceItemDocument, options);
}
export type EditInvoiceItemMutationHookResult = ReturnType<
  typeof useEditInvoiceItemMutation
>;
export type EditInvoiceItemMutationResult =
  Apollo.MutationResult<Types.EditInvoiceItemMutation>;
export type EditInvoiceItemMutationOptions = Apollo.BaseMutationOptions<
  Types.EditInvoiceItemMutation,
  Types.EditInvoiceItemMutationVariables
>;
export const ToggleAutoApproveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleAutoApprove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchAutoApprove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'autoApprove' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ToggleAutoApproveMutationFn = Apollo.MutationFunction<
  Types.ToggleAutoApproveMutation,
  Types.ToggleAutoApproveMutationVariables
>;

/**
 * __useToggleAutoApproveMutation__
 *
 * To run a mutation, you first call `useToggleAutoApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAutoApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAutoApproveMutation, { data, loading, error }] = useToggleAutoApproveMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useToggleAutoApproveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ToggleAutoApproveMutation,
    Types.ToggleAutoApproveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ToggleAutoApproveMutation,
    Types.ToggleAutoApproveMutationVariables
  >(ToggleAutoApproveDocument, options);
}
export type ToggleAutoApproveMutationHookResult = ReturnType<
  typeof useToggleAutoApproveMutation
>;
export type ToggleAutoApproveMutationResult =
  Apollo.MutationResult<Types.ToggleAutoApproveMutation>;
export type ToggleAutoApproveMutationOptions = Apollo.BaseMutationOptions<
  Types.ToggleAutoApproveMutation,
  Types.ToggleAutoApproveMutationVariables
>;
export const GetInvoiceDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInvoiceDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listStripeInvoiceDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'autoApprove' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stripeInvoiceId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amountDue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoicePdf' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtotal' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdDatetime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDateDatetime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hostedInvoiceUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoiceItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stripeCustomerId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isEdited' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'netTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancelled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isOvertime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isHoliday' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timesheetId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'tip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'worker' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetInvoiceDetailsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDetailsQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetInvoiceDetailsQuery,
    Types.GetInvoiceDetailsQueryVariables
  > &
    (
      | { variables: Types.GetInvoiceDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetInvoiceDetailsQuery,
    Types.GetInvoiceDetailsQueryVariables
  >(GetInvoiceDetailsDocument, options);
}
export function useGetInvoiceDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetInvoiceDetailsQuery,
    Types.GetInvoiceDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetInvoiceDetailsQuery,
    Types.GetInvoiceDetailsQueryVariables
  >(GetInvoiceDetailsDocument, options);
}
export function useGetInvoiceDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetInvoiceDetailsQuery,
    Types.GetInvoiceDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetInvoiceDetailsQuery,
    Types.GetInvoiceDetailsQueryVariables
  >(GetInvoiceDetailsDocument, options);
}
export type GetInvoiceDetailsQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsQuery
>;
export type GetInvoiceDetailsLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsLazyQuery
>;
export type GetInvoiceDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsSuspenseQuery
>;
export type GetInvoiceDetailsQueryResult = Apollo.QueryResult<
  Types.GetInvoiceDetailsQuery,
  Types.GetInvoiceDetailsQueryVariables
>;
export const GetInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoice' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPaymentMethod' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccountFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'amountDue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultContactEmail' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoicePdf' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomerItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cutOff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyCutOff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingWeek' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consolidateUnprocessedCharges' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'groupBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentPaymentMethod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerRelationshipItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkerRelationship' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pay' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Customer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTaxType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ltaAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'markupPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkInRadiusFeet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AddressItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'admins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerRelationshipItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetInvoiceQuery,
    Types.GetInvoiceQueryVariables
  > &
    (
      | { variables: Types.GetInvoiceQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetInvoiceQuery, Types.GetInvoiceQueryVariables>(
    GetInvoiceDocument,
    options,
  );
}
export function useGetInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetInvoiceQuery,
    Types.GetInvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetInvoiceQuery,
    Types.GetInvoiceQueryVariables
  >(GetInvoiceDocument, options);
}
export function useGetInvoiceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetInvoiceQuery,
    Types.GetInvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetInvoiceQuery,
    Types.GetInvoiceQueryVariables
  >(GetInvoiceDocument, options);
}
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceLazyQuery
>;
export type GetInvoiceSuspenseQueryHookResult = ReturnType<
  typeof useGetInvoiceSuspenseQuery
>;
export type GetInvoiceQueryResult = Apollo.QueryResult<
  Types.GetInvoiceQuery,
  Types.GetInvoiceQueryVariables
>;
export const CreatePaymentIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePaymentIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentIntentCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientSecret' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreatePaymentIntentMutationFn = Apollo.MutationFunction<
  Types.CreatePaymentIntentMutation,
  Types.CreatePaymentIntentMutationVariables
>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreatePaymentIntentMutation,
    Types.CreatePaymentIntentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreatePaymentIntentMutation,
    Types.CreatePaymentIntentMutationVariables
  >(CreatePaymentIntentDocument, options);
}
export type CreatePaymentIntentMutationHookResult = ReturnType<
  typeof useCreatePaymentIntentMutation
>;
export type CreatePaymentIntentMutationResult =
  Apollo.MutationResult<Types.CreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<
  Types.CreatePaymentIntentMutation,
  Types.CreatePaymentIntentMutationVariables
>;
export const InvoicePayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InvoicePay' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoicePay' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoice' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InvoicePayMutationFn = Apollo.MutationFunction<
  Types.InvoicePayMutation,
  Types.InvoicePayMutationVariables
>;

/**
 * __useInvoicePayMutation__
 *
 * To run a mutation, you first call `useInvoicePayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoicePayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoicePayMutation, { data, loading, error }] = useInvoicePayMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useInvoicePayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.InvoicePayMutation,
    Types.InvoicePayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.InvoicePayMutation,
    Types.InvoicePayMutationVariables
  >(InvoicePayDocument, options);
}
export type InvoicePayMutationHookResult = ReturnType<
  typeof useInvoicePayMutation
>;
export type InvoicePayMutationResult =
  Apollo.MutationResult<Types.InvoicePayMutation>;
export type InvoicePayMutationOptions = Apollo.BaseMutationOptions<
  Types.InvoicePayMutation,
  Types.InvoicePayMutationVariables
>;
export const UpdatePaymentIntentAchProcessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaymentIntentAchProcess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientSecret' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentMethodId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentIntentAchProcessUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientSecret' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientSecret' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentMethodId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentMethodId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdatePaymentIntentAchProcessMutationFn = Apollo.MutationFunction<
  Types.UpdatePaymentIntentAchProcessMutation,
  Types.UpdatePaymentIntentAchProcessMutationVariables
>;

/**
 * __useUpdatePaymentIntentAchProcessMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentIntentAchProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentIntentAchProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentIntentAchProcessMutation, { data, loading, error }] = useUpdatePaymentIntentAchProcessMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      clientSecret: // value for 'clientSecret'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdatePaymentIntentAchProcessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdatePaymentIntentAchProcessMutation,
    Types.UpdatePaymentIntentAchProcessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdatePaymentIntentAchProcessMutation,
    Types.UpdatePaymentIntentAchProcessMutationVariables
  >(UpdatePaymentIntentAchProcessDocument, options);
}
export type UpdatePaymentIntentAchProcessMutationHookResult = ReturnType<
  typeof useUpdatePaymentIntentAchProcessMutation
>;
export type UpdatePaymentIntentAchProcessMutationResult =
  Apollo.MutationResult<Types.UpdatePaymentIntentAchProcessMutation>;
export type UpdatePaymentIntentAchProcessMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UpdatePaymentIntentAchProcessMutation,
    Types.UpdatePaymentIntentAchProcessMutationVariables
  >;
export const ListInvoicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListInvoices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InvoiceFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortKey' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InvoiceSortKeyEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reverse' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listStripeInvoices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'perPage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reverse' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reverse' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortKey' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortKey' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdDatetime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDateDatetime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalItems' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListInvoicesQuery__
 *
 * To run a query within a React component, call `useListInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvoicesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *      sortKey: // value for 'sortKey'
 *      reverse: // value for 'reverse'
 *   },
 * });
 */
export function useListInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListInvoicesQuery,
    Types.ListInvoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListInvoicesQuery,
    Types.ListInvoicesQueryVariables
  >(ListInvoicesDocument, options);
}
export function useListInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListInvoicesQuery,
    Types.ListInvoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListInvoicesQuery,
    Types.ListInvoicesQueryVariables
  >(ListInvoicesDocument, options);
}
export function useListInvoicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListInvoicesQuery,
    Types.ListInvoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListInvoicesQuery,
    Types.ListInvoicesQueryVariables
  >(ListInvoicesDocument, options);
}
export type ListInvoicesQueryHookResult = ReturnType<
  typeof useListInvoicesQuery
>;
export type ListInvoicesLazyQueryHookResult = ReturnType<
  typeof useListInvoicesLazyQuery
>;
export type ListInvoicesSuspenseQueryHookResult = ReturnType<
  typeof useListInvoicesSuspenseQuery
>;
export type ListInvoicesQueryResult = Apollo.QueryResult<
  Types.ListInvoicesQuery,
  Types.ListInvoicesQueryVariables
>;
export const AcceptRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobRequestId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobRequestAccept' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobRequestId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobRequestId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AcceptRequestMutationFn = Apollo.MutationFunction<
  Types.AcceptRequestMutation,
  Types.AcceptRequestMutationVariables
>;

/**
 * __useAcceptRequestMutation__
 *
 * To run a mutation, you first call `useAcceptRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptRequestMutation, { data, loading, error }] = useAcceptRequestMutation({
 *   variables: {
 *      jobRequestId: // value for 'jobRequestId'
 *   },
 * });
 */
export function useAcceptRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AcceptRequestMutation,
    Types.AcceptRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AcceptRequestMutation,
    Types.AcceptRequestMutationVariables
  >(AcceptRequestDocument, options);
}
export type AcceptRequestMutationHookResult = ReturnType<
  typeof useAcceptRequestMutation
>;
export type AcceptRequestMutationResult =
  Apollo.MutationResult<Types.AcceptRequestMutation>;
export type AcceptRequestMutationOptions = Apollo.BaseMutationOptions<
  Types.AcceptRequestMutation,
  Types.AcceptRequestMutationVariables
>;
export const CancelJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reason' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCancel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reason' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cancelUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CancelJobMutationFn = Apollo.MutationFunction<
  Types.CancelJobMutation,
  Types.CancelJobMutationVariables
>;

/**
 * __useCancelJobMutation__
 *
 * To run a mutation, you first call `useCancelJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelJobMutation, { data, loading, error }] = useCancelJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      userId: // value for 'userId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CancelJobMutation,
    Types.CancelJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CancelJobMutation,
    Types.CancelJobMutationVariables
  >(CancelJobDocument, options);
}
export type CancelJobMutationHookResult = ReturnType<
  typeof useCancelJobMutation
>;
export type CancelJobMutationResult =
  Apollo.MutationResult<Types.CancelJobMutation>;
export type CancelJobMutationOptions = Apollo.BaseMutationOptions<
  Types.CancelJobMutation,
  Types.CancelJobMutationVariables
>;
export const ListJobCustomerAdminsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListJobCustomerAdmins' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'admins' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatarUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'email',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListJobCustomerAdminsQuery__
 *
 * To run a query within a React component, call `useListJobCustomerAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListJobCustomerAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListJobCustomerAdminsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useListJobCustomerAdminsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListJobCustomerAdminsQuery,
    Types.ListJobCustomerAdminsQueryVariables
  > &
    (
      | { variables: Types.ListJobCustomerAdminsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListJobCustomerAdminsQuery,
    Types.ListJobCustomerAdminsQueryVariables
  >(ListJobCustomerAdminsDocument, options);
}
export function useListJobCustomerAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListJobCustomerAdminsQuery,
    Types.ListJobCustomerAdminsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListJobCustomerAdminsQuery,
    Types.ListJobCustomerAdminsQueryVariables
  >(ListJobCustomerAdminsDocument, options);
}
export function useListJobCustomerAdminsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListJobCustomerAdminsQuery,
    Types.ListJobCustomerAdminsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListJobCustomerAdminsQuery,
    Types.ListJobCustomerAdminsQueryVariables
  >(ListJobCustomerAdminsDocument, options);
}
export type ListJobCustomerAdminsQueryHookResult = ReturnType<
  typeof useListJobCustomerAdminsQuery
>;
export type ListJobCustomerAdminsLazyQueryHookResult = ReturnType<
  typeof useListJobCustomerAdminsLazyQuery
>;
export type ListJobCustomerAdminsSuspenseQueryHookResult = ReturnType<
  typeof useListJobCustomerAdminsSuspenseQuery
>;
export type ListJobCustomerAdminsQueryResult = Apollo.QueryResult<
  Types.ListJobCustomerAdminsQuery,
  Types.ListJobCustomerAdminsQueryVariables
>;
export const AdminEditCertificatesOfJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminEditCertificatesOfJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'certificateIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminEditCertificatesOfJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'certificateIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'certificateType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AdminEditCertificatesOfJobMutationFn = Apollo.MutationFunction<
  Types.AdminEditCertificatesOfJobMutation,
  Types.AdminEditCertificatesOfJobMutationVariables
>;

/**
 * __useAdminEditCertificatesOfJobMutation__
 *
 * To run a mutation, you first call `useAdminEditCertificatesOfJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditCertificatesOfJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditCertificatesOfJobMutation, { data, loading, error }] = useAdminEditCertificatesOfJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      certificateIds: // value for 'certificateIds'
 *   },
 * });
 */
export function useAdminEditCertificatesOfJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AdminEditCertificatesOfJobMutation,
    Types.AdminEditCertificatesOfJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AdminEditCertificatesOfJobMutation,
    Types.AdminEditCertificatesOfJobMutationVariables
  >(AdminEditCertificatesOfJobDocument, options);
}
export type AdminEditCertificatesOfJobMutationHookResult = ReturnType<
  typeof useAdminEditCertificatesOfJobMutation
>;
export type AdminEditCertificatesOfJobMutationResult =
  Apollo.MutationResult<Types.AdminEditCertificatesOfJobMutation>;
export type AdminEditCertificatesOfJobMutationOptions =
  Apollo.BaseMutationOptions<
    Types.AdminEditCertificatesOfJobMutation,
    Types.AdminEditCertificatesOfJobMutationVariables
  >;
export const GetJobHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJobHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetJobHistoryQuery__
 *
 * To run a query within a React component, call `useGetJobHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobHistoryQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetJobHistoryQuery,
    Types.GetJobHistoryQueryVariables
  > &
    (
      | { variables: Types.GetJobHistoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetJobHistoryQuery,
    Types.GetJobHistoryQueryVariables
  >(GetJobHistoryDocument, options);
}
export function useGetJobHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetJobHistoryQuery,
    Types.GetJobHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetJobHistoryQuery,
    Types.GetJobHistoryQueryVariables
  >(GetJobHistoryDocument, options);
}
export function useGetJobHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetJobHistoryQuery,
    Types.GetJobHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetJobHistoryQuery,
    Types.GetJobHistoryQueryVariables
  >(GetJobHistoryDocument, options);
}
export type GetJobHistoryQueryHookResult = ReturnType<
  typeof useGetJobHistoryQuery
>;
export type GetJobHistoryLazyQueryHookResult = ReturnType<
  typeof useGetJobHistoryLazyQuery
>;
export type GetJobHistorySuspenseQueryHookResult = ReturnType<
  typeof useGetJobHistorySuspenseQuery
>;
export type GetJobHistoryQueryResult = Apollo.QueryResult<
  Types.GetJobHistoryQuery,
  Types.GetJobHistoryQueryVariables
>;
export const ListAgencyUniformsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAgencyUniforms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uniforms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultInstructions' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListAgencyUniformsQuery__
 *
 * To run a query within a React component, call `useListAgencyUniformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgencyUniformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgencyUniformsQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useListAgencyUniformsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListAgencyUniformsQuery,
    Types.ListAgencyUniformsQueryVariables
  > &
    (
      | { variables: Types.ListAgencyUniformsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListAgencyUniformsQuery,
    Types.ListAgencyUniformsQueryVariables
  >(ListAgencyUniformsDocument, options);
}
export function useListAgencyUniformsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListAgencyUniformsQuery,
    Types.ListAgencyUniformsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListAgencyUniformsQuery,
    Types.ListAgencyUniformsQueryVariables
  >(ListAgencyUniformsDocument, options);
}
export function useListAgencyUniformsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListAgencyUniformsQuery,
    Types.ListAgencyUniformsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListAgencyUniformsQuery,
    Types.ListAgencyUniformsQueryVariables
  >(ListAgencyUniformsDocument, options);
}
export type ListAgencyUniformsQueryHookResult = ReturnType<
  typeof useListAgencyUniformsQuery
>;
export type ListAgencyUniformsLazyQueryHookResult = ReturnType<
  typeof useListAgencyUniformsLazyQuery
>;
export type ListAgencyUniformsSuspenseQueryHookResult = ReturnType<
  typeof useListAgencyUniformsSuspenseQuery
>;
export type ListAgencyUniformsQueryResult = Apollo.QueryResult<
  Types.ListAgencyUniformsQuery,
  Types.ListAgencyUniformsQueryVariables
>;
export const GetJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressInstructions' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelledAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInstructions' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstShiftStartAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hiredWorkersCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instructions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastShiftEndAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markupPercent' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isRateLock' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minPayRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'published' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isHoliday' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mandatoryBreakTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'originalCostRate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'originalPayRate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rateType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'markupPercent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ltaAllowed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobTaxType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'workerRelationships',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'WorkerRelationshipItem',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addresses' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine1',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine2' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coords' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latitude' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longitude' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'middleName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phoneNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'JobOfferItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'worker' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobWorkers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'JobWorkerItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'markupPercent' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shifts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'descriptionEn' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gravyTrained' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TimesheetItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uniform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultInstructions' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uniformInstructions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerRelationshipItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkerRelationship' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Worker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsRatingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shiftsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeCertificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobWorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobWorker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dropReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'droppedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShowMarkedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'noShowMarkedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimesheetItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Timesheet' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tipAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBreakMinutes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedCheckinAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedCheckoutAt' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedMinutes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netApprovedTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkinAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetComments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'actorType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'actor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedByType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkinCoords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'checkoutAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkoutCoords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportedCheckinAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportedCheckoutAt' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportedBreakMinutes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reportComment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobWorker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'middleName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shift' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetJobQuery__
 *
 * To run a query within a React component, call `useGetJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetJobQuery,
    Types.GetJobQueryVariables
  > &
    (
      | { variables: Types.GetJobQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetJobQuery, Types.GetJobQueryVariables>(
    GetJobDocument,
    options,
  );
}
export function useGetJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetJobQuery,
    Types.GetJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetJobQuery, Types.GetJobQueryVariables>(
    GetJobDocument,
    options,
  );
}
export function useGetJobSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetJobQuery,
    Types.GetJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetJobQuery, Types.GetJobQueryVariables>(
    GetJobDocument,
    options,
  );
}
export type GetJobQueryHookResult = ReturnType<typeof useGetJobQuery>;
export type GetJobLazyQueryHookResult = ReturnType<typeof useGetJobLazyQuery>;
export type GetJobSuspenseQueryHookResult = ReturnType<
  typeof useGetJobSuspenseQuery
>;
export type GetJobQueryResult = Apollo.QueryResult<
  Types.GetJobQuery,
  Types.GetJobQueryVariables
>;
export const JobRemoveWorkerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JobRemoveWorker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobWorkerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reason' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobDismissWorker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobWorkerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobWorkerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reason' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type JobRemoveWorkerMutationFn = Apollo.MutationFunction<
  Types.JobRemoveWorkerMutation,
  Types.JobRemoveWorkerMutationVariables
>;

/**
 * __useJobRemoveWorkerMutation__
 *
 * To run a mutation, you first call `useJobRemoveWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobRemoveWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobRemoveWorkerMutation, { data, loading, error }] = useJobRemoveWorkerMutation({
 *   variables: {
 *      jobWorkerId: // value for 'jobWorkerId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useJobRemoveWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.JobRemoveWorkerMutation,
    Types.JobRemoveWorkerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.JobRemoveWorkerMutation,
    Types.JobRemoveWorkerMutationVariables
  >(JobRemoveWorkerDocument, options);
}
export type JobRemoveWorkerMutationHookResult = ReturnType<
  typeof useJobRemoveWorkerMutation
>;
export type JobRemoveWorkerMutationResult =
  Apollo.MutationResult<Types.JobRemoveWorkerMutation>;
export type JobRemoveWorkerMutationOptions = Apollo.BaseMutationOptions<
  Types.JobRemoveWorkerMutation,
  Types.JobRemoveWorkerMutationVariables
>;
export const MarkShowedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkShowed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobWorkerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showed' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminMarkWorkerAsShowed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobWorkerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobWorkerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showed' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'showed' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobWorker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'showed' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type MarkShowedMutationFn = Apollo.MutationFunction<
  Types.MarkShowedMutation,
  Types.MarkShowedMutationVariables
>;

/**
 * __useMarkShowedMutation__
 *
 * To run a mutation, you first call `useMarkShowedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkShowedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markShowedMutation, { data, loading, error }] = useMarkShowedMutation({
 *   variables: {
 *      jobWorkerId: // value for 'jobWorkerId'
 *      showed: // value for 'showed'
 *   },
 * });
 */
export function useMarkShowedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.MarkShowedMutation,
    Types.MarkShowedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.MarkShowedMutation,
    Types.MarkShowedMutationVariables
  >(MarkShowedDocument, options);
}
export type MarkShowedMutationHookResult = ReturnType<
  typeof useMarkShowedMutation
>;
export type MarkShowedMutationResult =
  Apollo.MutationResult<Types.MarkShowedMutation>;
export type MarkShowedMutationOptions = Apollo.BaseMutationOptions<
  Types.MarkShowedMutation,
  Types.MarkShowedMutationVariables
>;
export const UpdatePaymentJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaymentJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'costRate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payRate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'originalCostRate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'originalPayRate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'markupPercent' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isRateLock' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminEditRateOfJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'costRate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'costRate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payRate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payRate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'originalCostRate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'originalCostRate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'originalPayRate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'originalPayRate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'markupPercent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'markupPercent' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isRateLock' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isRateLock' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalCostRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalPayRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'markupPercent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isRateLock' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdatePaymentJobMutationFn = Apollo.MutationFunction<
  Types.UpdatePaymentJobMutation,
  Types.UpdatePaymentJobMutationVariables
>;

/**
 * __useUpdatePaymentJobMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentJobMutation, { data, loading, error }] = useUpdatePaymentJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      costRate: // value for 'costRate'
 *      payRate: // value for 'payRate'
 *      originalCostRate: // value for 'originalCostRate'
 *      originalPayRate: // value for 'originalPayRate'
 *      markupPercent: // value for 'markupPercent'
 *      isRateLock: // value for 'isRateLock'
 *   },
 * });
 */
export function useUpdatePaymentJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdatePaymentJobMutation,
    Types.UpdatePaymentJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdatePaymentJobMutation,
    Types.UpdatePaymentJobMutationVariables
  >(UpdatePaymentJobDocument, options);
}
export type UpdatePaymentJobMutationHookResult = ReturnType<
  typeof useUpdatePaymentJobMutation
>;
export type UpdatePaymentJobMutationResult =
  Apollo.MutationResult<Types.UpdatePaymentJobMutation>;
export type UpdatePaymentJobMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePaymentJobMutation,
  Types.UpdatePaymentJobMutationVariables
>;
export const PublishJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobPublish' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'published' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type PublishJobMutationFn = Apollo.MutationFunction<
  Types.PublishJobMutation,
  Types.PublishJobMutationVariables
>;

/**
 * __usePublishJobMutation__
 *
 * To run a mutation, you first call `usePublishJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishJobMutation, { data, loading, error }] = usePublishJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function usePublishJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.PublishJobMutation,
    Types.PublishJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.PublishJobMutation,
    Types.PublishJobMutationVariables
  >(PublishJobDocument, options);
}
export type PublishJobMutationHookResult = ReturnType<
  typeof usePublishJobMutation
>;
export type PublishJobMutationResult =
  Apollo.MutationResult<Types.PublishJobMutation>;
export type PublishJobMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishJobMutation,
  Types.PublishJobMutationVariables
>;
export const RejectRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobRequestId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobRequestReject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobRequestId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobRequestId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RejectRequestMutationFn = Apollo.MutationFunction<
  Types.RejectRequestMutation,
  Types.RejectRequestMutationVariables
>;

/**
 * __useRejectRequestMutation__
 *
 * To run a mutation, you first call `useRejectRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectRequestMutation, { data, loading, error }] = useRejectRequestMutation({
 *   variables: {
 *      jobRequestId: // value for 'jobRequestId'
 *   },
 * });
 */
export function useRejectRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RejectRequestMutation,
    Types.RejectRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RejectRequestMutation,
    Types.RejectRequestMutationVariables
  >(RejectRequestDocument, options);
}
export type RejectRequestMutationHookResult = ReturnType<
  typeof useRejectRequestMutation
>;
export type RejectRequestMutationResult =
  Apollo.MutationResult<Types.RejectRequestMutation>;
export type RejectRequestMutationOptions = Apollo.BaseMutationOptions<
  Types.RejectRequestMutation,
  Types.RejectRequestMutationVariables
>;
export const AddTimesheetCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddTimesheetComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timesheetId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'body' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetCommentsAdd' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesheetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timesheetId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'body' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'body' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rejectedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timesheetComments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actorType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'body' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AddTimesheetCommentMutationFn = Apollo.MutationFunction<
  Types.AddTimesheetCommentMutation,
  Types.AddTimesheetCommentMutationVariables
>;

/**
 * __useAddTimesheetCommentMutation__
 *
 * To run a mutation, you first call `useAddTimesheetCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTimesheetCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTimesheetCommentMutation, { data, loading, error }] = useAddTimesheetCommentMutation({
 *   variables: {
 *      timesheetId: // value for 'timesheetId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAddTimesheetCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddTimesheetCommentMutation,
    Types.AddTimesheetCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddTimesheetCommentMutation,
    Types.AddTimesheetCommentMutationVariables
  >(AddTimesheetCommentDocument, options);
}
export type AddTimesheetCommentMutationHookResult = ReturnType<
  typeof useAddTimesheetCommentMutation
>;
export type AddTimesheetCommentMutationResult =
  Apollo.MutationResult<Types.AddTimesheetCommentMutation>;
export type AddTimesheetCommentMutationOptions = Apollo.BaseMutationOptions<
  Types.AddTimesheetCommentMutation,
  Types.AddTimesheetCommentMutationVariables
>;
export const WorkerUpdateRatingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'WorkerUpdateRating' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timesheetId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rating' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Rating' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'body' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerUpdateRating' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesheetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timesheetId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rating' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rating' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'body' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'body' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobWorker' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'worker' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'averageRating',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'jobsRatingCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobsWorked' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timesheetComments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actorType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'body' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type WorkerUpdateRatingMutationFn = Apollo.MutationFunction<
  Types.WorkerUpdateRatingMutation,
  Types.WorkerUpdateRatingMutationVariables
>;

/**
 * __useWorkerUpdateRatingMutation__
 *
 * To run a mutation, you first call `useWorkerUpdateRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerUpdateRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerUpdateRatingMutation, { data, loading, error }] = useWorkerUpdateRatingMutation({
 *   variables: {
 *      timesheetId: // value for 'timesheetId'
 *      rating: // value for 'rating'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useWorkerUpdateRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WorkerUpdateRatingMutation,
    Types.WorkerUpdateRatingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WorkerUpdateRatingMutation,
    Types.WorkerUpdateRatingMutationVariables
  >(WorkerUpdateRatingDocument, options);
}
export type WorkerUpdateRatingMutationHookResult = ReturnType<
  typeof useWorkerUpdateRatingMutation
>;
export type WorkerUpdateRatingMutationResult =
  Apollo.MutationResult<Types.WorkerUpdateRatingMutation>;
export type WorkerUpdateRatingMutationOptions = Apollo.BaseMutationOptions<
  Types.WorkerUpdateRatingMutation,
  Types.WorkerUpdateRatingMutationVariables
>;
export const CreateTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateTimesheetMutationFn = Apollo.MutationFunction<
  Types.CreateTimesheetMutation,
  Types.CreateTimesheetMutationVariables
>;

/**
 * __useCreateTimesheetMutation__
 *
 * To run a mutation, you first call `useCreateTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimesheetMutation, { data, loading, error }] = useCreateTimesheetMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useCreateTimesheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTimesheetMutation,
    Types.CreateTimesheetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateTimesheetMutation,
    Types.CreateTimesheetMutationVariables
  >(CreateTimesheetDocument, options);
}
export type CreateTimesheetMutationHookResult = ReturnType<
  typeof useCreateTimesheetMutation
>;
export type CreateTimesheetMutationResult =
  Apollo.MutationResult<Types.CreateTimesheetMutation>;
export type CreateTimesheetMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTimesheetMutation,
  Types.CreateTimesheetMutationVariables
>;
export const ApproveTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timesheetId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'approvedBreakMinutes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NonNegativeInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'approvedCheckinAt' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'approvedCheckoutAt' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ratingComment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tipAmount' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'NonNegativeFloat' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetApprove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesheetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timesheetId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedBreakMinutes' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'approvedBreakMinutes' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedCheckinAt' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'approvedCheckinAt' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedCheckoutAt' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'approvedCheckoutAt' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ratingComment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ratingComment' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tipAmount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tipAmount' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ApproveTimesheetMutationFn = Apollo.MutationFunction<
  Types.ApproveTimesheetMutation,
  Types.ApproveTimesheetMutationVariables
>;

/**
 * __useApproveTimesheetMutation__
 *
 * To run a mutation, you first call `useApproveTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTimesheetMutation, { data, loading, error }] = useApproveTimesheetMutation({
 *   variables: {
 *      timesheetId: // value for 'timesheetId'
 *      approvedBreakMinutes: // value for 'approvedBreakMinutes'
 *      approvedCheckinAt: // value for 'approvedCheckinAt'
 *      approvedCheckoutAt: // value for 'approvedCheckoutAt'
 *      ratingComment: // value for 'ratingComment'
 *      tipAmount: // value for 'tipAmount'
 *   },
 * });
 */
export function useApproveTimesheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ApproveTimesheetMutation,
    Types.ApproveTimesheetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ApproveTimesheetMutation,
    Types.ApproveTimesheetMutationVariables
  >(ApproveTimesheetDocument, options);
}
export type ApproveTimesheetMutationHookResult = ReturnType<
  typeof useApproveTimesheetMutation
>;
export type ApproveTimesheetMutationResult =
  Apollo.MutationResult<Types.ApproveTimesheetMutation>;
export type ApproveTimesheetMutationOptions = Apollo.BaseMutationOptions<
  Types.ApproveTimesheetMutation,
  Types.ApproveTimesheetMutationVariables
>;
export const RejectTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timesheetId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ratingComment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetReject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesheetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timesheetId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ratingComment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ratingComment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rejectedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RejectTimesheetMutationFn = Apollo.MutationFunction<
  Types.RejectTimesheetMutation,
  Types.RejectTimesheetMutationVariables
>;

/**
 * __useRejectTimesheetMutation__
 *
 * To run a mutation, you first call `useRejectTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTimesheetMutation, { data, loading, error }] = useRejectTimesheetMutation({
 *   variables: {
 *      timesheetId: // value for 'timesheetId'
 *      ratingComment: // value for 'ratingComment'
 *   },
 * });
 */
export function useRejectTimesheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RejectTimesheetMutation,
    Types.RejectTimesheetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RejectTimesheetMutation,
    Types.RejectTimesheetMutationVariables
  >(RejectTimesheetDocument, options);
}
export type RejectTimesheetMutationHookResult = ReturnType<
  typeof useRejectTimesheetMutation
>;
export type RejectTimesheetMutationResult =
  Apollo.MutationResult<Types.RejectTimesheetMutation>;
export type RejectTimesheetMutationOptions = Apollo.BaseMutationOptions<
  Types.RejectTimesheetMutation,
  Types.RejectTimesheetMutationVariables
>;
export const SaveTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timesheetId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportedBreakMinutes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NonNegativeInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportedCheckinAt' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportedCheckoutAt' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ratingComment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tipAmount' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'NonNegativeFloat' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesheetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timesheetId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportedBreakMinutes' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reportedBreakMinutes' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportedCheckinAt' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reportedCheckinAt' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportedCheckoutAt' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reportedCheckoutAt' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ratingComment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ratingComment' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tipAmount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tipAmount' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SaveTimesheetMutationFn = Apollo.MutationFunction<
  Types.SaveTimesheetMutation,
  Types.SaveTimesheetMutationVariables
>;

/**
 * __useSaveTimesheetMutation__
 *
 * To run a mutation, you first call `useSaveTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTimesheetMutation, { data, loading, error }] = useSaveTimesheetMutation({
 *   variables: {
 *      timesheetId: // value for 'timesheetId'
 *      reportedBreakMinutes: // value for 'reportedBreakMinutes'
 *      reportedCheckinAt: // value for 'reportedCheckinAt'
 *      reportedCheckoutAt: // value for 'reportedCheckoutAt'
 *      ratingComment: // value for 'ratingComment'
 *      tipAmount: // value for 'tipAmount'
 *   },
 * });
 */
export function useSaveTimesheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SaveTimesheetMutation,
    Types.SaveTimesheetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SaveTimesheetMutation,
    Types.SaveTimesheetMutationVariables
  >(SaveTimesheetDocument, options);
}
export type SaveTimesheetMutationHookResult = ReturnType<
  typeof useSaveTimesheetMutation
>;
export type SaveTimesheetMutationResult =
  Apollo.MutationResult<Types.SaveTimesheetMutation>;
export type SaveTimesheetMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveTimesheetMutation,
  Types.SaveTimesheetMutationVariables
>;
export const SaveTimesheetTipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveTimesheetTip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timesheetId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tipAmount' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NonNegativeFloat' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetTipUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesheetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timesheetId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tipAmount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tipAmount' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SaveTimesheetTipMutationFn = Apollo.MutationFunction<
  Types.SaveTimesheetTipMutation,
  Types.SaveTimesheetTipMutationVariables
>;

/**
 * __useSaveTimesheetTipMutation__
 *
 * To run a mutation, you first call `useSaveTimesheetTipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTimesheetTipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTimesheetTipMutation, { data, loading, error }] = useSaveTimesheetTipMutation({
 *   variables: {
 *      timesheetId: // value for 'timesheetId'
 *      tipAmount: // value for 'tipAmount'
 *   },
 * });
 */
export function useSaveTimesheetTipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SaveTimesheetTipMutation,
    Types.SaveTimesheetTipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SaveTimesheetTipMutation,
    Types.SaveTimesheetTipMutationVariables
  >(SaveTimesheetTipDocument, options);
}
export type SaveTimesheetTipMutationHookResult = ReturnType<
  typeof useSaveTimesheetTipMutation
>;
export type SaveTimesheetTipMutationResult =
  Apollo.MutationResult<Types.SaveTimesheetTipMutation>;
export type SaveTimesheetTipMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveTimesheetTipMutation,
  Types.SaveTimesheetTipMutationVariables
>;
export const UnrejectTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnrejectTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timesheetId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetUnreject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesheetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timesheetId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rejectedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UnrejectTimesheetMutationFn = Apollo.MutationFunction<
  Types.UnrejectTimesheetMutation,
  Types.UnrejectTimesheetMutationVariables
>;

/**
 * __useUnrejectTimesheetMutation__
 *
 * To run a mutation, you first call `useUnrejectTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnrejectTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unrejectTimesheetMutation, { data, loading, error }] = useUnrejectTimesheetMutation({
 *   variables: {
 *      timesheetId: // value for 'timesheetId'
 *   },
 * });
 */
export function useUnrejectTimesheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnrejectTimesheetMutation,
    Types.UnrejectTimesheetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UnrejectTimesheetMutation,
    Types.UnrejectTimesheetMutationVariables
  >(UnrejectTimesheetDocument, options);
}
export type UnrejectTimesheetMutationHookResult = ReturnType<
  typeof useUnrejectTimesheetMutation
>;
export type UnrejectTimesheetMutationResult =
  Apollo.MutationResult<Types.UnrejectTimesheetMutation>;
export type UnrejectTimesheetMutationOptions = Apollo.BaseMutationOptions<
  Types.UnrejectTimesheetMutation,
  Types.UnrejectTimesheetMutationVariables
>;
export const UnapproveTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnapproveTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timesheetId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetUnapprove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesheetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timesheetId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UnapproveTimesheetMutationFn = Apollo.MutationFunction<
  Types.UnapproveTimesheetMutation,
  Types.UnapproveTimesheetMutationVariables
>;

/**
 * __useUnapproveTimesheetMutation__
 *
 * To run a mutation, you first call `useUnapproveTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnapproveTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unapproveTimesheetMutation, { data, loading, error }] = useUnapproveTimesheetMutation({
 *   variables: {
 *      timesheetId: // value for 'timesheetId'
 *   },
 * });
 */
export function useUnapproveTimesheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnapproveTimesheetMutation,
    Types.UnapproveTimesheetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UnapproveTimesheetMutation,
    Types.UnapproveTimesheetMutationVariables
  >(UnapproveTimesheetDocument, options);
}
export type UnapproveTimesheetMutationHookResult = ReturnType<
  typeof useUnapproveTimesheetMutation
>;
export type UnapproveTimesheetMutationResult =
  Apollo.MutationResult<Types.UnapproveTimesheetMutation>;
export type UnapproveTimesheetMutationOptions = Apollo.BaseMutationOptions<
  Types.UnapproveTimesheetMutation,
  Types.UnapproveTimesheetMutationVariables
>;
export const UnPublishJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnPublishJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobUnpublish' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'published' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UnPublishJobMutationFn = Apollo.MutationFunction<
  Types.UnPublishJobMutation,
  Types.UnPublishJobMutationVariables
>;

/**
 * __useUnPublishJobMutation__
 *
 * To run a mutation, you first call `useUnPublishJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnPublishJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unPublishJobMutation, { data, loading, error }] = useUnPublishJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useUnPublishJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnPublishJobMutation,
    Types.UnPublishJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UnPublishJobMutation,
    Types.UnPublishJobMutationVariables
  >(UnPublishJobDocument, options);
}
export type UnPublishJobMutationHookResult = ReturnType<
  typeof useUnPublishJobMutation
>;
export type UnPublishJobMutationResult =
  Apollo.MutationResult<Types.UnPublishJobMutation>;
export type UnPublishJobMutationOptions = Apollo.BaseMutationOptions<
  Types.UnPublishJobMutation,
  Types.UnPublishJobMutationVariables
>;
export const UpdateGravyWorkersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGravyWorkers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberWorkers' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminEditNumberWorkersJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'numberWorkers' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberWorkers' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobWorkers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'JobWorkerItem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Worker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsRatingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shiftsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeCertificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobWorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobWorker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dropReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'droppedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShowMarkedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'noShowMarkedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateGravyWorkersMutationFn = Apollo.MutationFunction<
  Types.UpdateGravyWorkersMutation,
  Types.UpdateGravyWorkersMutationVariables
>;

/**
 * __useUpdateGravyWorkersMutation__
 *
 * To run a mutation, you first call `useUpdateGravyWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGravyWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGravyWorkersMutation, { data, loading, error }] = useUpdateGravyWorkersMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      numberWorkers: // value for 'numberWorkers'
 *   },
 * });
 */
export function useUpdateGravyWorkersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateGravyWorkersMutation,
    Types.UpdateGravyWorkersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateGravyWorkersMutation,
    Types.UpdateGravyWorkersMutationVariables
  >(UpdateGravyWorkersDocument, options);
}
export type UpdateGravyWorkersMutationHookResult = ReturnType<
  typeof useUpdateGravyWorkersMutation
>;
export type UpdateGravyWorkersMutationResult =
  Apollo.MutationResult<Types.UpdateGravyWorkersMutation>;
export type UpdateGravyWorkersMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateGravyWorkersMutation,
  Types.UpdateGravyWorkersMutationVariables
>;
export const UpdateJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressInstructions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactInstructions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'instructions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'uniformId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'uniformInstructions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressInstructions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressInstructions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactInstructions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactInstructions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instructions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'instructions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniformId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uniformId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniformInstructions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uniformInstructions' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressInstructions' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactInstructions' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instructions' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uniformInstructions' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uniform' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateJobMutationFn = Apollo.MutationFunction<
  Types.UpdateJobMutation,
  Types.UpdateJobMutationVariables
>;

/**
 * __useUpdateJobMutation__
 *
 * To run a mutation, you first call `useUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      addressId: // value for 'addressId'
 *      addressInstructions: // value for 'addressInstructions'
 *      contactId: // value for 'contactId'
 *      contactInstructions: // value for 'contactInstructions'
 *      instructions: // value for 'instructions'
 *      uniformId: // value for 'uniformId'
 *      uniformInstructions: // value for 'uniformInstructions'
 *   },
 * });
 */
export function useUpdateJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateJobMutation,
    Types.UpdateJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateJobMutation,
    Types.UpdateJobMutationVariables
  >(UpdateJobDocument, options);
}
export type UpdateJobMutationHookResult = ReturnType<
  typeof useUpdateJobMutation
>;
export type UpdateJobMutationResult =
  Apollo.MutationResult<Types.UpdateJobMutation>;
export type UpdateJobMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateJobMutation,
  Types.UpdateJobMutationVariables
>;
export const UpdateJobScheduleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateJobSchedule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstShiftStartAt' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastShiftEndAt' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timeZoneOffset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mandatoryBreakTime' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Minutes' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminEditTimeOfJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstShiftStartAt' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstShiftStartAt' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastShiftEndAt' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastShiftEndAt' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timeZoneOffset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timeZoneOffset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mandatoryBreakTime' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mandatoryBreakTime' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstShiftStartAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastShiftEndAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shifts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateJobScheduleMutationFn = Apollo.MutationFunction<
  Types.UpdateJobScheduleMutation,
  Types.UpdateJobScheduleMutationVariables
>;

/**
 * __useUpdateJobScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateJobScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobScheduleMutation, { data, loading, error }] = useUpdateJobScheduleMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      firstShiftStartAt: // value for 'firstShiftStartAt'
 *      lastShiftEndAt: // value for 'lastShiftEndAt'
 *      timeZoneOffset: // value for 'timeZoneOffset'
 *      mandatoryBreakTime: // value for 'mandatoryBreakTime'
 *   },
 * });
 */
export function useUpdateJobScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateJobScheduleMutation,
    Types.UpdateJobScheduleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateJobScheduleMutation,
    Types.UpdateJobScheduleMutationVariables
  >(UpdateJobScheduleDocument, options);
}
export type UpdateJobScheduleMutationHookResult = ReturnType<
  typeof useUpdateJobScheduleMutation
>;
export type UpdateJobScheduleMutationResult =
  Apollo.MutationResult<Types.UpdateJobScheduleMutation>;
export type UpdateJobScheduleMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateJobScheduleMutation,
  Types.UpdateJobScheduleMutationVariables
>;
export const ListWorkersForJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListWorkersForJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WorkerFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onboardingStatus' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'workerJurisdictions',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'readyToWork' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListWorkersForJobQuery__
 *
 * To run a query within a React component, call `useListWorkersForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkersForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkersForJobQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListWorkersForJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListWorkersForJobQuery,
    Types.ListWorkersForJobQueryVariables
  > &
    (
      | { variables: Types.ListWorkersForJobQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListWorkersForJobQuery,
    Types.ListWorkersForJobQueryVariables
  >(ListWorkersForJobDocument, options);
}
export function useListWorkersForJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListWorkersForJobQuery,
    Types.ListWorkersForJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListWorkersForJobQuery,
    Types.ListWorkersForJobQueryVariables
  >(ListWorkersForJobDocument, options);
}
export function useListWorkersForJobSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListWorkersForJobQuery,
    Types.ListWorkersForJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListWorkersForJobQuery,
    Types.ListWorkersForJobQueryVariables
  >(ListWorkersForJobDocument, options);
}
export type ListWorkersForJobQueryHookResult = ReturnType<
  typeof useListWorkersForJobQuery
>;
export type ListWorkersForJobLazyQueryHookResult = ReturnType<
  typeof useListWorkersForJobLazyQuery
>;
export type ListWorkersForJobSuspenseQueryHookResult = ReturnType<
  typeof useListWorkersForJobSuspenseQuery
>;
export type ListWorkersForJobQueryResult = Apollo.QueryResult<
  Types.ListWorkersForJobQuery,
  Types.ListWorkersForJobQueryVariables
>;
export const JobOfferCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JobOfferCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'forceInvite' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forceInvite' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'forceInvite' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'JobOfferItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Worker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsRatingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shiftsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeCertificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type JobOfferCreateMutationFn = Apollo.MutationFunction<
  Types.JobOfferCreateMutation,
  Types.JobOfferCreateMutationVariables
>;

/**
 * __useJobOfferCreateMutation__
 *
 * To run a mutation, you first call `useJobOfferCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobOfferCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobOfferCreateMutation, { data, loading, error }] = useJobOfferCreateMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      workerId: // value for 'workerId'
 *      forceInvite: // value for 'forceInvite'
 *   },
 * });
 */
export function useJobOfferCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.JobOfferCreateMutation,
    Types.JobOfferCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.JobOfferCreateMutation,
    Types.JobOfferCreateMutationVariables
  >(JobOfferCreateDocument, options);
}
export type JobOfferCreateMutationHookResult = ReturnType<
  typeof useJobOfferCreateMutation
>;
export type JobOfferCreateMutationResult =
  Apollo.MutationResult<Types.JobOfferCreateMutation>;
export type JobOfferCreateMutationOptions = Apollo.BaseMutationOptions<
  Types.JobOfferCreateMutation,
  Types.JobOfferCreateMutationVariables
>;
export const JobHireWorkerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JobHireWorker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payRate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'forceHire' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobHireWorker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payRate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payRate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forceHire' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'forceHire' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiredWorkersCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobWorkers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'JobWorkerItem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerAssignJobWorkplace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onboardingStatus' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'heardFrom' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ownsCar' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'language' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'secondaryLanguage',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Worker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsRatingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shiftsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeCertificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobWorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobWorker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dropReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'droppedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShowMarkedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'noShowMarkedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type JobHireWorkerMutationFn = Apollo.MutationFunction<
  Types.JobHireWorkerMutation,
  Types.JobHireWorkerMutationVariables
>;

/**
 * __useJobHireWorkerMutation__
 *
 * To run a mutation, you first call `useJobHireWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobHireWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobHireWorkerMutation, { data, loading, error }] = useJobHireWorkerMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      payRate: // value for 'payRate'
 *      workerId: // value for 'workerId'
 *      forceHire: // value for 'forceHire'
 *   },
 * });
 */
export function useJobHireWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.JobHireWorkerMutation,
    Types.JobHireWorkerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.JobHireWorkerMutation,
    Types.JobHireWorkerMutationVariables
  >(JobHireWorkerDocument, options);
}
export type JobHireWorkerMutationHookResult = ReturnType<
  typeof useJobHireWorkerMutation
>;
export type JobHireWorkerMutationResult =
  Apollo.MutationResult<Types.JobHireWorkerMutation>;
export type JobHireWorkerMutationOptions = Apollo.BaseMutationOptions<
  Types.JobHireWorkerMutation,
  Types.JobHireWorkerMutationVariables
>;
export const BulkEditTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BulkEditTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobWorkerIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'action' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TimesheetsBulkEditActionEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'editInfo' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'BulkEditTimesheetsInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetsBulkEdit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobWorkerIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobWorkerIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'action' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesheetsInfo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'editInfo' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type BulkEditTimesheetMutationFn = Apollo.MutationFunction<
  Types.BulkEditTimesheetMutation,
  Types.BulkEditTimesheetMutationVariables
>;

/**
 * __useBulkEditTimesheetMutation__
 *
 * To run a mutation, you first call `useBulkEditTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditTimesheetMutation, { data, loading, error }] = useBulkEditTimesheetMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      jobWorkerIds: // value for 'jobWorkerIds'
 *      action: // value for 'action'
 *      editInfo: // value for 'editInfo'
 *   },
 * });
 */
export function useBulkEditTimesheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.BulkEditTimesheetMutation,
    Types.BulkEditTimesheetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.BulkEditTimesheetMutation,
    Types.BulkEditTimesheetMutationVariables
  >(BulkEditTimesheetDocument, options);
}
export type BulkEditTimesheetMutationHookResult = ReturnType<
  typeof useBulkEditTimesheetMutation
>;
export type BulkEditTimesheetMutationResult =
  Apollo.MutationResult<Types.BulkEditTimesheetMutation>;
export type BulkEditTimesheetMutationOptions = Apollo.BaseMutationOptions<
  Types.BulkEditTimesheetMutation,
  Types.BulkEditTimesheetMutationVariables
>;
export const GetWorkerProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkerProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobsRatingCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shiftsWorked' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
                { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeCertificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workerSkills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobsWorked' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetWorkerProfileQuery__
 *
 * To run a query within a React component, call `useGetWorkerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerProfileQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useGetWorkerProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetWorkerProfileQuery,
    Types.GetWorkerProfileQueryVariables
  > &
    (
      | { variables: Types.GetWorkerProfileQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetWorkerProfileQuery,
    Types.GetWorkerProfileQueryVariables
  >(GetWorkerProfileDocument, options);
}
export function useGetWorkerProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetWorkerProfileQuery,
    Types.GetWorkerProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetWorkerProfileQuery,
    Types.GetWorkerProfileQueryVariables
  >(GetWorkerProfileDocument, options);
}
export function useGetWorkerProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetWorkerProfileQuery,
    Types.GetWorkerProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetWorkerProfileQuery,
    Types.GetWorkerProfileQueryVariables
  >(GetWorkerProfileDocument, options);
}
export type GetWorkerProfileQueryHookResult = ReturnType<
  typeof useGetWorkerProfileQuery
>;
export type GetWorkerProfileLazyQueryHookResult = ReturnType<
  typeof useGetWorkerProfileLazyQuery
>;
export type GetWorkerProfileSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkerProfileSuspenseQuery
>;
export type GetWorkerProfileQueryResult = Apollo.QueryResult<
  Types.GetWorkerProfileQuery,
  Types.GetWorkerProfileQueryVariables
>;
export const GetJobWorkersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJobWorkers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobWorkers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'checkInStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timesheets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checkinAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checkoutAt' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'reportedCheckinAt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'reportedCheckoutAt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'approvedCheckinAt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'approvedCheckoutAt',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'worker' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'phoneNumber',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetJobWorkersQuery__
 *
 * To run a query within a React component, call `useGetJobWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobWorkersQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobWorkersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetJobWorkersQuery,
    Types.GetJobWorkersQueryVariables
  > &
    (
      | { variables: Types.GetJobWorkersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetJobWorkersQuery,
    Types.GetJobWorkersQueryVariables
  >(GetJobWorkersDocument, options);
}
export function useGetJobWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetJobWorkersQuery,
    Types.GetJobWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetJobWorkersQuery,
    Types.GetJobWorkersQueryVariables
  >(GetJobWorkersDocument, options);
}
export function useGetJobWorkersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetJobWorkersQuery,
    Types.GetJobWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetJobWorkersQuery,
    Types.GetJobWorkersQueryVariables
  >(GetJobWorkersDocument, options);
}
export type GetJobWorkersQueryHookResult = ReturnType<
  typeof useGetJobWorkersQuery
>;
export type GetJobWorkersLazyQueryHookResult = ReturnType<
  typeof useGetJobWorkersLazyQuery
>;
export type GetJobWorkersSuspenseQueryHookResult = ReturnType<
  typeof useGetJobWorkersSuspenseQuery
>;
export type GetJobWorkersQueryResult = Apollo.QueryResult<
  Types.GetJobWorkersQuery,
  Types.GetJobWorkersQueryVariables
>;
export const ListJobsForMonitorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListJobsForMonitor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601Date' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timeZone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shiftsByDay' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'timeZone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timeZone' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'hiredWorkersCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'published' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine1',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zip' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timezone' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastShiftEndAt' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'firstShiftStartAt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'checkinWorkerCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasNoShow' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListJobsForMonitorQuery__
 *
 * To run a query within a React component, call `useListJobsForMonitorQuery` and pass it any options that fit your needs.
 * When your component renders, `useListJobsForMonitorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListJobsForMonitorQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      startDate: // value for 'startDate'
 *      filters: // value for 'filters'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useListJobsForMonitorQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListJobsForMonitorQuery,
    Types.ListJobsForMonitorQueryVariables
  > &
    (
      | { variables: Types.ListJobsForMonitorQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListJobsForMonitorQuery,
    Types.ListJobsForMonitorQueryVariables
  >(ListJobsForMonitorDocument, options);
}
export function useListJobsForMonitorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListJobsForMonitorQuery,
    Types.ListJobsForMonitorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListJobsForMonitorQuery,
    Types.ListJobsForMonitorQueryVariables
  >(ListJobsForMonitorDocument, options);
}
export function useListJobsForMonitorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListJobsForMonitorQuery,
    Types.ListJobsForMonitorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListJobsForMonitorQuery,
    Types.ListJobsForMonitorQueryVariables
  >(ListJobsForMonitorDocument, options);
}
export type ListJobsForMonitorQueryHookResult = ReturnType<
  typeof useListJobsForMonitorQuery
>;
export type ListJobsForMonitorLazyQueryHookResult = ReturnType<
  typeof useListJobsForMonitorLazyQuery
>;
export type ListJobsForMonitorSuspenseQueryHookResult = ReturnType<
  typeof useListJobsForMonitorSuspenseQuery
>;
export type ListJobsForMonitorQueryResult = Apollo.QueryResult<
  Types.ListJobsForMonitorQuery,
  Types.ListJobsForMonitorQueryVariables
>;
export const GetJobSubscribersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJobSubscribers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobSubscriber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'job' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriberEmail' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetJobSubscribersQuery__
 *
 * To run a query within a React component, call `useGetJobSubscribersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobSubscribersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobSubscribersQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobSubscribersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetJobSubscribersQuery,
    Types.GetJobSubscribersQueryVariables
  > &
    (
      | { variables: Types.GetJobSubscribersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetJobSubscribersQuery,
    Types.GetJobSubscribersQueryVariables
  >(GetJobSubscribersDocument, options);
}
export function useGetJobSubscribersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetJobSubscribersQuery,
    Types.GetJobSubscribersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetJobSubscribersQuery,
    Types.GetJobSubscribersQueryVariables
  >(GetJobSubscribersDocument, options);
}
export function useGetJobSubscribersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetJobSubscribersQuery,
    Types.GetJobSubscribersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetJobSubscribersQuery,
    Types.GetJobSubscribersQueryVariables
  >(GetJobSubscribersDocument, options);
}
export type GetJobSubscribersQueryHookResult = ReturnType<
  typeof useGetJobSubscribersQuery
>;
export type GetJobSubscribersLazyQueryHookResult = ReturnType<
  typeof useGetJobSubscribersLazyQuery
>;
export type GetJobSubscribersSuspenseQueryHookResult = ReturnType<
  typeof useGetJobSubscribersSuspenseQuery
>;
export type GetJobSubscribersQueryResult = Apollo.QueryResult<
  Types.GetJobSubscribersQuery,
  Types.GetJobSubscribersQueryVariables
>;
export const JobAddSubscriberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JobAddSubscriber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shiftId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subscriberEmail' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAddSubscriber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shiftId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shiftId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriberEmail' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subscriberEmail' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type JobAddSubscriberMutationFn = Apollo.MutationFunction<
  Types.JobAddSubscriberMutation,
  Types.JobAddSubscriberMutationVariables
>;

/**
 * __useJobAddSubscriberMutation__
 *
 * To run a mutation, you first call `useJobAddSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobAddSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobAddSubscriberMutation, { data, loading, error }] = useJobAddSubscriberMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      jobId: // value for 'jobId'
 *      orderId: // value for 'orderId'
 *      shiftId: // value for 'shiftId'
 *      subscriberEmail: // value for 'subscriberEmail'
 *   },
 * });
 */
export function useJobAddSubscriberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.JobAddSubscriberMutation,
    Types.JobAddSubscriberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.JobAddSubscriberMutation,
    Types.JobAddSubscriberMutationVariables
  >(JobAddSubscriberDocument, options);
}
export type JobAddSubscriberMutationHookResult = ReturnType<
  typeof useJobAddSubscriberMutation
>;
export type JobAddSubscriberMutationResult =
  Apollo.MutationResult<Types.JobAddSubscriberMutation>;
export type JobAddSubscriberMutationOptions = Apollo.BaseMutationOptions<
  Types.JobAddSubscriberMutation,
  Types.JobAddSubscriberMutationVariables
>;
export const JobRemoveSubscriberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JobRemoveSubscriber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobSubscriberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobRemoveSubscriber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobSubscriberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobSubscriberId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tenantId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type JobRemoveSubscriberMutationFn = Apollo.MutationFunction<
  Types.JobRemoveSubscriberMutation,
  Types.JobRemoveSubscriberMutationVariables
>;

/**
 * __useJobRemoveSubscriberMutation__
 *
 * To run a mutation, you first call `useJobRemoveSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobRemoveSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobRemoveSubscriberMutation, { data, loading, error }] = useJobRemoveSubscriberMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      jobSubscriberId: // value for 'jobSubscriberId'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useJobRemoveSubscriberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.JobRemoveSubscriberMutation,
    Types.JobRemoveSubscriberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.JobRemoveSubscriberMutation,
    Types.JobRemoveSubscriberMutationVariables
  >(JobRemoveSubscriberDocument, options);
}
export type JobRemoveSubscriberMutationHookResult = ReturnType<
  typeof useJobRemoveSubscriberMutation
>;
export type JobRemoveSubscriberMutationResult =
  Apollo.MutationResult<Types.JobRemoveSubscriberMutation>;
export type JobRemoveSubscriberMutationOptions = Apollo.BaseMutationOptions<
  Types.JobRemoveSubscriberMutation,
  Types.JobRemoveSubscriberMutationVariables
>;
export const GetJobOldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJobOld' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressInstructions' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelledAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInstructions' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstShiftStartAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hiredWorkersCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instructions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastShiftEndAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markupPercent' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isRateLock' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minPayRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'published' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isHoliday' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mandatoryBreakTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'originalCostRate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'originalPayRate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rateType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'markupPercent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ltaAllowed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobTaxType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'workerRelationships',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'WorkerRelationshipItem',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addresses' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine1',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine2' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coords' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latitude' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longitude' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'middleName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phoneNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'JobOfferItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'worker' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobWorkers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'JobWorkerItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'markupPercent' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shifts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'descriptionEn' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TimesheetItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uniform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultInstructions' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uniformInstructions' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerRelationshipItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkerRelationship' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Worker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsRatingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shiftsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeCertificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobWorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobWorker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dropReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'droppedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShowMarkedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'noShowMarkedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkerItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimesheetItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Timesheet' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tipAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBreakMinutes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedCheckinAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedCheckoutAt' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedMinutes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netApprovedTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkinAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetComments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'actorType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'actor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedByType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkinCoords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'checkoutAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkoutCoords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportedCheckinAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportedCheckoutAt' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportedBreakMinutes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reportComment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobWorker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'middleName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shift' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetJobOldQuery__
 *
 * To run a query within a React component, call `useGetJobOldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobOldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobOldQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobOldQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetJobOldQuery,
    Types.GetJobOldQueryVariables
  > &
    (
      | { variables: Types.GetJobOldQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetJobOldQuery, Types.GetJobOldQueryVariables>(
    GetJobOldDocument,
    options,
  );
}
export function useGetJobOldLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetJobOldQuery,
    Types.GetJobOldQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetJobOldQuery,
    Types.GetJobOldQueryVariables
  >(GetJobOldDocument, options);
}
export function useGetJobOldSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetJobOldQuery,
    Types.GetJobOldQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetJobOldQuery,
    Types.GetJobOldQueryVariables
  >(GetJobOldDocument, options);
}
export type GetJobOldQueryHookResult = ReturnType<typeof useGetJobOldQuery>;
export type GetJobOldLazyQueryHookResult = ReturnType<
  typeof useGetJobOldLazyQuery
>;
export type GetJobOldSuspenseQueryHookResult = ReturnType<
  typeof useGetJobOldSuspenseQuery
>;
export type GetJobOldQueryResult = Apollo.QueryResult<
  Types.GetJobOldQuery,
  Types.GetJobOldQueryVariables
>;
export const ListJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'hiredWorkersCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'firstShiftStartAt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastShiftEndAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListJobsQuery__
 *
 * To run a query within a React component, call `useListJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListJobsQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useListJobsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListJobsQuery,
    Types.ListJobsQueryVariables
  > &
    (
      | { variables: Types.ListJobsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListJobsQuery, Types.ListJobsQueryVariables>(
    ListJobsDocument,
    options,
  );
}
export function useListJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListJobsQuery,
    Types.ListJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ListJobsQuery, Types.ListJobsQueryVariables>(
    ListJobsDocument,
    options,
  );
}
export function useListJobsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListJobsQuery,
    Types.ListJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListJobsQuery,
    Types.ListJobsQueryVariables
  >(ListJobsDocument, options);
}
export type ListJobsQueryHookResult = ReturnType<typeof useListJobsQuery>;
export type ListJobsLazyQueryHookResult = ReturnType<
  typeof useListJobsLazyQuery
>;
export type ListJobsSuspenseQueryHookResult = ReturnType<
  typeof useListJobsSuspenseQuery
>;
export type ListJobsQueryResult = Apollo.QueryResult<
  Types.ListJobsQuery,
  Types.ListJobsQueryVariables
>;
export const GetMyTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMyTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CustomerAdminFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admins' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'acceptedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'phoneNumber',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetMyTeamQuery__
 *
 * To run a query within a React component, call `useGetMyTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTeamQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetMyTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetMyTeamQuery,
    Types.GetMyTeamQueryVariables
  > &
    (
      | { variables: Types.GetMyTeamQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetMyTeamQuery, Types.GetMyTeamQueryVariables>(
    GetMyTeamDocument,
    options,
  );
}
export function useGetMyTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetMyTeamQuery,
    Types.GetMyTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetMyTeamQuery,
    Types.GetMyTeamQueryVariables
  >(GetMyTeamDocument, options);
}
export function useGetMyTeamSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetMyTeamQuery,
    Types.GetMyTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetMyTeamQuery,
    Types.GetMyTeamQueryVariables
  >(GetMyTeamDocument, options);
}
export type GetMyTeamQueryHookResult = ReturnType<typeof useGetMyTeamQuery>;
export type GetMyTeamLazyQueryHookResult = ReturnType<
  typeof useGetMyTeamLazyQuery
>;
export type GetMyTeamSuspenseQueryHookResult = ReturnType<
  typeof useGetMyTeamSuspenseQuery
>;
export type GetMyTeamQueryResult = Apollo.QueryResult<
  Types.GetMyTeamQuery,
  Types.GetMyTeamQueryVariables
>;
export const CreateNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'link' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notificationType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationTypeEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publishAt' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'link' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'link' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notificationType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publishAt' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publishAt' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'title' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publishAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateNotificationMutationFn = Apollo.MutationFunction<
  Types.CreateNotificationMutation,
  Types.CreateNotificationMutationVariables
>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      link: // value for 'link'
 *      message: // value for 'message'
 *      notificationType: // value for 'notificationType'
 *      publishAt: // value for 'publishAt'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateNotificationMutation,
    Types.CreateNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateNotificationMutation,
    Types.CreateNotificationMutationVariables
  >(CreateNotificationDocument, options);
}
export type CreateNotificationMutationHookResult = ReturnType<
  typeof useCreateNotificationMutation
>;
export type CreateNotificationMutationResult =
  Apollo.MutationResult<Types.CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateNotificationMutation,
  Types.CreateNotificationMutationVariables
>;
export const UpdateNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'link' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notificationType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationTypeEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publishAt' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'link' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'link' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notificationType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publishAt' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publishAt' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'title' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publishAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<
  Types.UpdateNotificationMutation,
  Types.UpdateNotificationMutationVariables
>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      link: // value for 'link'
 *      message: // value for 'message'
 *      notificationType: // value for 'notificationType'
 *      publishAt: // value for 'publishAt'
 *      title: // value for 'title'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateNotificationMutation,
    Types.UpdateNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateNotificationMutation,
    Types.UpdateNotificationMutationVariables
  >(UpdateNotificationDocument, options);
}
export type UpdateNotificationMutationHookResult = ReturnType<
  typeof useUpdateNotificationMutation
>;
export type UpdateNotificationMutationResult =
  Apollo.MutationResult<Types.UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateNotificationMutation,
  Types.UpdateNotificationMutationVariables
>;
export const DeleteNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<
  Types.DeleteNotificationMutation,
  Types.DeleteNotificationMutationVariables
>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteNotificationMutation,
    Types.DeleteNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteNotificationMutation,
    Types.DeleteNotificationMutationVariables
  >(DeleteNotificationDocument, options);
}
export type DeleteNotificationMutationHookResult = ReturnType<
  typeof useDeleteNotificationMutation
>;
export type DeleteNotificationMutationResult =
  Apollo.MutationResult<Types.DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteNotificationMutation,
  Types.DeleteNotificationMutationVariables
>;
export const GetNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
          defaultValue: { kind: 'IntValue', value: '1' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
          defaultValue: { kind: 'IntValue', value: '25' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'NotificationFilterSetInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'perPage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publishAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isRead' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalItems' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetNotificationsQuery,
    Types.GetNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetNotificationsQuery,
    Types.GetNotificationsQueryVariables
  >(GetNotificationsDocument, options);
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetNotificationsQuery,
    Types.GetNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetNotificationsQuery,
    Types.GetNotificationsQueryVariables
  >(GetNotificationsDocument, options);
}
export function useGetNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetNotificationsQuery,
    Types.GetNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetNotificationsQuery,
    Types.GetNotificationsQueryVariables
  >(GetNotificationsDocument, options);
}
export type GetNotificationsQueryHookResult = ReturnType<
  typeof useGetNotificationsQuery
>;
export type GetNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsLazyQuery
>;
export type GetNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useGetNotificationsSuspenseQuery
>;
export type GetNotificationsQueryResult = Apollo.QueryResult<
  Types.GetNotificationsQuery,
  Types.GetNotificationsQueryVariables
>;
export const GetOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logoUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobTaxType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ltaAllowed' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'jobsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobWorkers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'showed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'worker' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'OrderWorkerItem',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvalStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mandatoryBreakTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timezone' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shifts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endAt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiredWorkersCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobOffers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'acceptedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'worker' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'OrderWorkerItem',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstShiftStartAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastShiftEndAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancelledAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'published' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reason' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsApproval' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timesheets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gravyTrained' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancelUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'TenantAdmin' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatarUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'email',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CustomerAdmin' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatarUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'email',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderWorkerItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Worker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsRatingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shiftsWorked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noShow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetOrderQuery,
    Types.GetOrderQueryVariables
  > &
    (
      | { variables: Types.GetOrderQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetOrderQuery, Types.GetOrderQueryVariables>(
    GetOrderDocument,
    options,
  );
}
export function useGetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetOrderQuery,
    Types.GetOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetOrderQuery, Types.GetOrderQueryVariables>(
    GetOrderDocument,
    options,
  );
}
export function useGetOrderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetOrderQuery,
    Types.GetOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetOrderQuery,
    Types.GetOrderQueryVariables
  >(GetOrderDocument, options);
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<
  typeof useGetOrderLazyQuery
>;
export type GetOrderSuspenseQueryHookResult = ReturnType<
  typeof useGetOrderSuspenseQuery
>;
export type GetOrderQueryResult = Apollo.QueryResult<
  Types.GetOrderQuery,
  Types.GetOrderQueryVariables
>;
export const CancelOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reason' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCancelBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reason' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reason' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cancelledAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cancelled' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CancelOrderMutationFn = Apollo.MutationFunction<
  Types.CancelOrderMutation,
  Types.CancelOrderMutationVariables
>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CancelOrderMutation,
    Types.CancelOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CancelOrderMutation,
    Types.CancelOrderMutationVariables
  >(CancelOrderDocument, options);
}
export type CancelOrderMutationHookResult = ReturnType<
  typeof useCancelOrderMutation
>;
export type CancelOrderMutationResult =
  Apollo.MutationResult<Types.CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<
  Types.CancelOrderMutation,
  Types.CancelOrderMutationVariables
>;
export const ListOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OrderFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortKey' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OrderSortKeyEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reverse' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reverse' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reverse' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sortKey' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sortKey' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uuid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobsCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobs' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filled' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cancelled' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'taxType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timesheets' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobWorkers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'showed',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'approvalStatus',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skill' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'imageUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'logoUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListOrdersQuery__
 *
 * To run a query within a React component, call `useListOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrdersQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortKey: // value for 'sortKey'
 *      reverse: // value for 'reverse'
 *   },
 * });
 */
export function useListOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListOrdersQuery,
    Types.ListOrdersQueryVariables
  > &
    (
      | { variables: Types.ListOrdersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ListOrdersQuery, Types.ListOrdersQueryVariables>(
    ListOrdersDocument,
    options,
  );
}
export function useListOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListOrdersQuery,
    Types.ListOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListOrdersQuery,
    Types.ListOrdersQueryVariables
  >(ListOrdersDocument, options);
}
export function useListOrdersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListOrdersQuery,
    Types.ListOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListOrdersQuery,
    Types.ListOrdersQueryVariables
  >(ListOrdersDocument, options);
}
export type ListOrdersQueryHookResult = ReturnType<typeof useListOrdersQuery>;
export type ListOrdersLazyQueryHookResult = ReturnType<
  typeof useListOrdersLazyQuery
>;
export type ListOrdersSuspenseQueryHookResult = ReturnType<
  typeof useListOrdersSuspenseQuery
>;
export type ListOrdersQueryResult = Apollo.QueryResult<
  Types.ListOrdersQuery,
  Types.ListOrdersQueryVariables
>;
export const ListPayrollsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListPayrolls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPayrolls' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tenantId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'perPage' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvalDeadline' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payday' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodStart' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodEnd' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalItems' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListPayrollsQuery__
 *
 * To run a query within a React component, call `useListPayrollsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPayrollsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPayrollsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useListPayrollsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListPayrollsQuery,
    Types.ListPayrollsQueryVariables
  > &
    (
      | { variables: Types.ListPayrollsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListPayrollsQuery,
    Types.ListPayrollsQueryVariables
  >(ListPayrollsDocument, options);
}
export function useListPayrollsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListPayrollsQuery,
    Types.ListPayrollsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListPayrollsQuery,
    Types.ListPayrollsQueryVariables
  >(ListPayrollsDocument, options);
}
export function useListPayrollsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListPayrollsQuery,
    Types.ListPayrollsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListPayrollsQuery,
    Types.ListPayrollsQueryVariables
  >(ListPayrollsDocument, options);
}
export type ListPayrollsQueryHookResult = ReturnType<
  typeof useListPayrollsQuery
>;
export type ListPayrollsLazyQueryHookResult = ReturnType<
  typeof useListPayrollsLazyQuery
>;
export type ListPayrollsSuspenseQueryHookResult = ReturnType<
  typeof useListPayrollsSuspenseQuery
>;
export type ListPayrollsQueryResult = Apollo.QueryResult<
  Types.ListPayrollsQuery,
  Types.ListPayrollsQueryVariables
>;
export const CreateNewPayrollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewPayroll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'weekDay' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601Date' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payday' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601Date' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payrollCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'weekDay' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'weekDay' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payday' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payday' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payroll' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payday' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodEnd' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodStart' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvalDeadline' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateNewPayrollMutationFn = Apollo.MutationFunction<
  Types.CreateNewPayrollMutation,
  Types.CreateNewPayrollMutationVariables
>;

/**
 * __useCreateNewPayrollMutation__
 *
 * To run a mutation, you first call `useCreateNewPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewPayrollMutation, { data, loading, error }] = useCreateNewPayrollMutation({
 *   variables: {
 *      weekDay: // value for 'weekDay'
 *      payday: // value for 'payday'
 *   },
 * });
 */
export function useCreateNewPayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateNewPayrollMutation,
    Types.CreateNewPayrollMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateNewPayrollMutation,
    Types.CreateNewPayrollMutationVariables
  >(CreateNewPayrollDocument, options);
}
export type CreateNewPayrollMutationHookResult = ReturnType<
  typeof useCreateNewPayrollMutation
>;
export type CreateNewPayrollMutationResult =
  Apollo.MutationResult<Types.CreateNewPayrollMutation>;
export type CreateNewPayrollMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateNewPayrollMutation,
  Types.CreateNewPayrollMutationVariables
>;
export const ApprovePreviewPayrollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApprovePreviewPayroll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payrollId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payrollApprove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payrollId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payrollId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payroll' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvalDeadline' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payrollItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'earnings' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'SimpleEarnings',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'worker' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'user' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'firstName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'lastName',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'netPay' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'paymentMethod',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'taxes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'SimpleTaxes',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payday' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodEnd' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodStart' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totals' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SimpleTotals' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleEarnings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItemEarning' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'earningType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedByType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tipAmount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleTotals' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollTotals' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyTaxes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyBenefits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postTaxDeductions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'liability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cashRequirement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeGross' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeTaxes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeBenefits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeNet' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employeeReimbursements' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleTaxes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItemTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkTaxId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ApprovePreviewPayrollMutationFn = Apollo.MutationFunction<
  Types.ApprovePreviewPayrollMutation,
  Types.ApprovePreviewPayrollMutationVariables
>;

/**
 * __useApprovePreviewPayrollMutation__
 *
 * To run a mutation, you first call `useApprovePreviewPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovePreviewPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvePreviewPayrollMutation, { data, loading, error }] = useApprovePreviewPayrollMutation({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *   },
 * });
 */
export function useApprovePreviewPayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ApprovePreviewPayrollMutation,
    Types.ApprovePreviewPayrollMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ApprovePreviewPayrollMutation,
    Types.ApprovePreviewPayrollMutationVariables
  >(ApprovePreviewPayrollDocument, options);
}
export type ApprovePreviewPayrollMutationHookResult = ReturnType<
  typeof useApprovePreviewPayrollMutation
>;
export type ApprovePreviewPayrollMutationResult =
  Apollo.MutationResult<Types.ApprovePreviewPayrollMutation>;
export type ApprovePreviewPayrollMutationOptions = Apollo.BaseMutationOptions<
  Types.ApprovePreviewPayrollMutation,
  Types.ApprovePreviewPayrollMutationVariables
>;
export const DeletePayrollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePayroll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payrollId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payrollDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payrollId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payrollId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeletePayrollMutationFn = Apollo.MutationFunction<
  Types.DeletePayrollMutation,
  Types.DeletePayrollMutationVariables
>;

/**
 * __useDeletePayrollMutation__
 *
 * To run a mutation, you first call `useDeletePayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayrollMutation, { data, loading, error }] = useDeletePayrollMutation({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *   },
 * });
 */
export function useDeletePayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeletePayrollMutation,
    Types.DeletePayrollMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeletePayrollMutation,
    Types.DeletePayrollMutationVariables
  >(DeletePayrollDocument, options);
}
export type DeletePayrollMutationHookResult = ReturnType<
  typeof useDeletePayrollMutation
>;
export type DeletePayrollMutationResult =
  Apollo.MutationResult<Types.DeletePayrollMutation>;
export type DeletePayrollMutationOptions = Apollo.BaseMutationOptions<
  Types.DeletePayrollMutation,
  Types.DeletePayrollMutationVariables
>;
export const GetPayrollDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPayrollDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payrollId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payroll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payrollId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payrollId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalDeadline' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payrollItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SimplePayrollItem',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractorPayments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SimpleContractorPayments',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'payday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SimpleTotals' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleEarnings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItemEarning' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'earningType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedByType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tipAmount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleTotals' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollTotals' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyTaxes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyBenefits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postTaxDeductions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'liability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cashRequirement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeGross' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeTaxes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeBenefits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeNet' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employeeReimbursements' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleTaxes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItemTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkTaxId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleContractorPayments' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContractorPayment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netPay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractorPaymentItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'earningType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedByType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tipAmount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimplePayrollItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'earnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SimpleEarnings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'netPay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SimpleTaxes' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deductions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkPtdId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ptdType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetPayrollDetailQuery__
 *
 * To run a query within a React component, call `useGetPayrollDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollDetailQuery({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *   },
 * });
 */
export function useGetPayrollDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetPayrollDetailQuery,
    Types.GetPayrollDetailQueryVariables
  > &
    (
      | { variables: Types.GetPayrollDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetPayrollDetailQuery,
    Types.GetPayrollDetailQueryVariables
  >(GetPayrollDetailDocument, options);
}
export function useGetPayrollDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetPayrollDetailQuery,
    Types.GetPayrollDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetPayrollDetailQuery,
    Types.GetPayrollDetailQueryVariables
  >(GetPayrollDetailDocument, options);
}
export function useGetPayrollDetailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetPayrollDetailQuery,
    Types.GetPayrollDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetPayrollDetailQuery,
    Types.GetPayrollDetailQueryVariables
  >(GetPayrollDetailDocument, options);
}
export type GetPayrollDetailQueryHookResult = ReturnType<
  typeof useGetPayrollDetailQuery
>;
export type GetPayrollDetailLazyQueryHookResult = ReturnType<
  typeof useGetPayrollDetailLazyQuery
>;
export type GetPayrollDetailSuspenseQueryHookResult = ReturnType<
  typeof useGetPayrollDetailSuspenseQuery
>;
export type GetPayrollDetailQueryResult = Apollo.QueryResult<
  Types.GetPayrollDetailQuery,
  Types.GetPayrollDetailQueryVariables
>;
export const GetPreviewPayrollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPreviewPayroll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payrollId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payrollPreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payrollId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payrollId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalDeadline' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payrollItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SimplePayrollItem',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractorPayments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SimpleContractorPayments',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'payday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SimpleTotals' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleEarnings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItemEarning' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'earningType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedByType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tipAmount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleTotals' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollTotals' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyTaxes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyBenefits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postTaxDeductions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'liability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cashRequirement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeGross' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeTaxes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeBenefits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeNet' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employeeReimbursements' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleTaxes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItemTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkTaxId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleContractorPayments' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContractorPayment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netPay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractorPaymentItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'earningType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedByType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tipAmount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimplePayrollItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'earnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SimpleEarnings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'netPay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SimpleTaxes' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deductions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkPtdId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ptdType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetPreviewPayrollQuery__
 *
 * To run a query within a React component, call `useGetPreviewPayrollQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviewPayrollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviewPayrollQuery({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *   },
 * });
 */
export function useGetPreviewPayrollQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetPreviewPayrollQuery,
    Types.GetPreviewPayrollQueryVariables
  > &
    (
      | { variables: Types.GetPreviewPayrollQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetPreviewPayrollQuery,
    Types.GetPreviewPayrollQueryVariables
  >(GetPreviewPayrollDocument, options);
}
export function useGetPreviewPayrollLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetPreviewPayrollQuery,
    Types.GetPreviewPayrollQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetPreviewPayrollQuery,
    Types.GetPreviewPayrollQueryVariables
  >(GetPreviewPayrollDocument, options);
}
export function useGetPreviewPayrollSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetPreviewPayrollQuery,
    Types.GetPreviewPayrollQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetPreviewPayrollQuery,
    Types.GetPreviewPayrollQueryVariables
  >(GetPreviewPayrollDocument, options);
}
export type GetPreviewPayrollQueryHookResult = ReturnType<
  typeof useGetPreviewPayrollQuery
>;
export type GetPreviewPayrollLazyQueryHookResult = ReturnType<
  typeof useGetPreviewPayrollLazyQuery
>;
export type GetPreviewPayrollSuspenseQueryHookResult = ReturnType<
  typeof useGetPreviewPayrollSuspenseQuery
>;
export type GetPreviewPayrollQueryResult = Apollo.QueryResult<
  Types.GetPreviewPayrollQuery,
  Types.GetPreviewPayrollQueryVariables
>;
export const ListPayrollJournalReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListPayrollJournalReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payrollId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payrollJournalReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payrollId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payrollId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListPayrollJournalReportQuery__
 *
 * To run a query within a React component, call `useListPayrollJournalReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPayrollJournalReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPayrollJournalReportQuery({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *   },
 * });
 */
export function useListPayrollJournalReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListPayrollJournalReportQuery,
    Types.ListPayrollJournalReportQueryVariables
  > &
    (
      | {
          variables: Types.ListPayrollJournalReportQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListPayrollJournalReportQuery,
    Types.ListPayrollJournalReportQueryVariables
  >(ListPayrollJournalReportDocument, options);
}
export function useListPayrollJournalReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListPayrollJournalReportQuery,
    Types.ListPayrollJournalReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListPayrollJournalReportQuery,
    Types.ListPayrollJournalReportQueryVariables
  >(ListPayrollJournalReportDocument, options);
}
export function useListPayrollJournalReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListPayrollJournalReportQuery,
    Types.ListPayrollJournalReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListPayrollJournalReportQuery,
    Types.ListPayrollJournalReportQueryVariables
  >(ListPayrollJournalReportDocument, options);
}
export type ListPayrollJournalReportQueryHookResult = ReturnType<
  typeof useListPayrollJournalReportQuery
>;
export type ListPayrollJournalReportLazyQueryHookResult = ReturnType<
  typeof useListPayrollJournalReportLazyQuery
>;
export type ListPayrollJournalReportSuspenseQueryHookResult = ReturnType<
  typeof useListPayrollJournalReportSuspenseQuery
>;
export type ListPayrollJournalReportQueryResult = Apollo.QueryResult<
  Types.ListPayrollJournalReportQuery,
  Types.ListPayrollJournalReportQueryVariables
>;
export const ListActiveUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListActiveUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeUserListReport' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListActiveUsersQuery__
 *
 * To run a query within a React component, call `useListActiveUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActiveUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActiveUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListActiveUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListActiveUsersQuery,
    Types.ListActiveUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListActiveUsersQuery,
    Types.ListActiveUsersQueryVariables
  >(ListActiveUsersDocument, options);
}
export function useListActiveUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListActiveUsersQuery,
    Types.ListActiveUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListActiveUsersQuery,
    Types.ListActiveUsersQueryVariables
  >(ListActiveUsersDocument, options);
}
export function useListActiveUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListActiveUsersQuery,
    Types.ListActiveUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListActiveUsersQuery,
    Types.ListActiveUsersQueryVariables
  >(ListActiveUsersDocument, options);
}
export type ListActiveUsersQueryHookResult = ReturnType<
  typeof useListActiveUsersQuery
>;
export type ListActiveUsersLazyQueryHookResult = ReturnType<
  typeof useListActiveUsersLazyQuery
>;
export type ListActiveUsersSuspenseQueryHookResult = ReturnType<
  typeof useListActiveUsersSuspenseQuery
>;
export type ListActiveUsersQueryResult = Apollo.QueryResult<
  Types.ListActiveUsersQuery,
  Types.ListActiveUsersQueryVariables
>;
export const ListActiveUsersBySkilDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListActiveUsersBySkil' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skills' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeUsersBySkillReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skills' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skills' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skill' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListActiveUsersBySkilQuery__
 *
 * To run a query within a React component, call `useListActiveUsersBySkilQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActiveUsersBySkilQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActiveUsersBySkilQuery({
 *   variables: {
 *      skills: // value for 'skills'
 *   },
 * });
 */
export function useListActiveUsersBySkilQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListActiveUsersBySkilQuery,
    Types.ListActiveUsersBySkilQueryVariables
  > &
    (
      | { variables: Types.ListActiveUsersBySkilQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListActiveUsersBySkilQuery,
    Types.ListActiveUsersBySkilQueryVariables
  >(ListActiveUsersBySkilDocument, options);
}
export function useListActiveUsersBySkilLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListActiveUsersBySkilQuery,
    Types.ListActiveUsersBySkilQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListActiveUsersBySkilQuery,
    Types.ListActiveUsersBySkilQueryVariables
  >(ListActiveUsersBySkilDocument, options);
}
export function useListActiveUsersBySkilSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListActiveUsersBySkilQuery,
    Types.ListActiveUsersBySkilQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListActiveUsersBySkilQuery,
    Types.ListActiveUsersBySkilQueryVariables
  >(ListActiveUsersBySkilDocument, options);
}
export type ListActiveUsersBySkilQueryHookResult = ReturnType<
  typeof useListActiveUsersBySkilQuery
>;
export type ListActiveUsersBySkilLazyQueryHookResult = ReturnType<
  typeof useListActiveUsersBySkilLazyQuery
>;
export type ListActiveUsersBySkilSuspenseQueryHookResult = ReturnType<
  typeof useListActiveUsersBySkilSuspenseQuery
>;
export type ListActiveUsersBySkilQueryResult = Apollo.QueryResult<
  Types.ListActiveUsersBySkilQuery,
  Types.ListActiveUsersBySkilQueryVariables
>;
export const ListCustomersReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListCustomersReport' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerListReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountAgeDays' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountRepEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountRepName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountRepPhone' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'latestOrder' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ordersFiveMonthsAgo' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ordersFourMonthsAgo' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ordersLastMonth' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ordersSixMonthsAgo' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ordersThisMonth' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ordersThreeMonthsAgo' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ordersTwoMonthsAgo' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalJobs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListCustomersReportQuery__
 *
 * To run a query within a React component, call `useListCustomersReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomersReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomersReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCustomersReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListCustomersReportQuery,
    Types.ListCustomersReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListCustomersReportQuery,
    Types.ListCustomersReportQueryVariables
  >(ListCustomersReportDocument, options);
}
export function useListCustomersReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListCustomersReportQuery,
    Types.ListCustomersReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListCustomersReportQuery,
    Types.ListCustomersReportQueryVariables
  >(ListCustomersReportDocument, options);
}
export function useListCustomersReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListCustomersReportQuery,
    Types.ListCustomersReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListCustomersReportQuery,
    Types.ListCustomersReportQueryVariables
  >(ListCustomersReportDocument, options);
}
export type ListCustomersReportQueryHookResult = ReturnType<
  typeof useListCustomersReportQuery
>;
export type ListCustomersReportLazyQueryHookResult = ReturnType<
  typeof useListCustomersReportLazyQuery
>;
export type ListCustomersReportSuspenseQueryHookResult = ReturnType<
  typeof useListCustomersReportSuspenseQuery
>;
export type ListCustomersReportQueryResult = Apollo.QueryResult<
  Types.ListCustomersReportQuery,
  Types.ListCustomersReportQueryVariables
>;
export const ListInvoicesReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListInvoicesReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoicingReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'from' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'to' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoiceNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoiceStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'jobDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unapprovedHours' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiced' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoicePaid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openInvoice' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListInvoicesReportQuery__
 *
 * To run a query within a React component, call `useListInvoicesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvoicesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvoicesReportQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useListInvoicesReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ListInvoicesReportQuery,
    Types.ListInvoicesReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListInvoicesReportQuery,
    Types.ListInvoicesReportQueryVariables
  >(ListInvoicesReportDocument, options);
}
export function useListInvoicesReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListInvoicesReportQuery,
    Types.ListInvoicesReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListInvoicesReportQuery,
    Types.ListInvoicesReportQueryVariables
  >(ListInvoicesReportDocument, options);
}
export function useListInvoicesReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListInvoicesReportQuery,
    Types.ListInvoicesReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListInvoicesReportQuery,
    Types.ListInvoicesReportQueryVariables
  >(ListInvoicesReportDocument, options);
}
export type ListInvoicesReportQueryHookResult = ReturnType<
  typeof useListInvoicesReportQuery
>;
export type ListInvoicesReportLazyQueryHookResult = ReturnType<
  typeof useListInvoicesReportLazyQuery
>;
export type ListInvoicesReportSuspenseQueryHookResult = ReturnType<
  typeof useListInvoicesReportSuspenseQuery
>;
export type ListInvoicesReportQueryResult = Apollo.QueryResult<
  Types.ListInvoicesReportQuery,
  Types.ListInvoicesReportQueryVariables
>;
export const RevenueByRangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RevenueByRange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revenueReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'from' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'to' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalHours' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalRevenue' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalPayrollExpense' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'netProfit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRevenueByRangeQuery__
 *
 * To run a query within a React component, call `useRevenueByRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueByRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueByRangeQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useRevenueByRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.RevenueByRangeQuery,
    Types.RevenueByRangeQueryVariables
  > &
    (
      | { variables: Types.RevenueByRangeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.RevenueByRangeQuery,
    Types.RevenueByRangeQueryVariables
  >(RevenueByRangeDocument, options);
}
export function useRevenueByRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RevenueByRangeQuery,
    Types.RevenueByRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.RevenueByRangeQuery,
    Types.RevenueByRangeQueryVariables
  >(RevenueByRangeDocument, options);
}
export function useRevenueByRangeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.RevenueByRangeQuery,
    Types.RevenueByRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.RevenueByRangeQuery,
    Types.RevenueByRangeQueryVariables
  >(RevenueByRangeDocument, options);
}
export type RevenueByRangeQueryHookResult = ReturnType<
  typeof useRevenueByRangeQuery
>;
export type RevenueByRangeLazyQueryHookResult = ReturnType<
  typeof useRevenueByRangeLazyQuery
>;
export type RevenueByRangeSuspenseQueryHookResult = ReturnType<
  typeof useRevenueByRangeSuspenseQuery
>;
export type RevenueByRangeQueryResult = Apollo.QueryResult<
  Types.RevenueByRangeQuery,
  Types.RevenueByRangeQueryVariables
>;
export const TimesheetsByPayPeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TimesheetsByPayPeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payPeriod' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601Date' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timesheetReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payPeriod' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payPeriod' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useTimesheetsByPayPeriodQuery__
 *
 * To run a query within a React component, call `useTimesheetsByPayPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetsByPayPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetsByPayPeriodQuery({
 *   variables: {
 *      payPeriod: // value for 'payPeriod'
 *   },
 * });
 */
export function useTimesheetsByPayPeriodQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TimesheetsByPayPeriodQuery,
    Types.TimesheetsByPayPeriodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.TimesheetsByPayPeriodQuery,
    Types.TimesheetsByPayPeriodQueryVariables
  >(TimesheetsByPayPeriodDocument, options);
}
export function useTimesheetsByPayPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TimesheetsByPayPeriodQuery,
    Types.TimesheetsByPayPeriodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.TimesheetsByPayPeriodQuery,
    Types.TimesheetsByPayPeriodQueryVariables
  >(TimesheetsByPayPeriodDocument, options);
}
export function useTimesheetsByPayPeriodSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.TimesheetsByPayPeriodQuery,
    Types.TimesheetsByPayPeriodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.TimesheetsByPayPeriodQuery,
    Types.TimesheetsByPayPeriodQueryVariables
  >(TimesheetsByPayPeriodDocument, options);
}
export type TimesheetsByPayPeriodQueryHookResult = ReturnType<
  typeof useTimesheetsByPayPeriodQuery
>;
export type TimesheetsByPayPeriodLazyQueryHookResult = ReturnType<
  typeof useTimesheetsByPayPeriodLazyQuery
>;
export type TimesheetsByPayPeriodSuspenseQueryHookResult = ReturnType<
  typeof useTimesheetsByPayPeriodSuspenseQuery
>;
export type TimesheetsByPayPeriodQueryResult = Apollo.QueryResult<
  Types.TimesheetsByPayPeriodQuery,
  Types.TimesheetsByPayPeriodQueryVariables
>;
export const GetWorkerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasSsn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reenableDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disableReason' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deactivatedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interviewStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SimpleComment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WorkerCoords' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skillRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayNameEn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checkEmployeeId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'candidateStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboardingStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backgroundStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'i9Status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboardingDocsStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heardFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownsCar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'jobsDropped' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobsWorked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shiftsWorked' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobsRatingCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobWorkers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'lastShiftEndAt' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'op' },
                                  value: { kind: 'EnumValue', value: 'GT' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'value' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filterDate' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WorkerJobWorkers' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingJobOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastShiftEndAt' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'firstShiftStartAt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cancelled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine1',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine2',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timezone' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zip' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logoUrl' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingJobRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastShiftEndAt' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'firstShiftStartAt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cancelled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine1',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLine2',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timezone' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zip' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logoUrl' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skill' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'references' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duration' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employerName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'summary' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WorkerSkill' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeCertificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'language' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondaryLanguage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'smartphoneType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateOfBirth' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ssnVerificationRetryCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'verificationCode' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'verificationRequiredAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'verificationStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleComment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Comment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'actor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TenantAdmin' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TenantAdminItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CustomerAdmin' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantAdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailConfirmed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerJobWorkers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobWorkerPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancelled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine1' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timezone' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logoUrl' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstShiftStartAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastShiftEndAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerSkill' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerCoords' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Point' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetWorkerQuery__
 *
 * To run a query within a React component, call `useGetWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerQuery({
 *   variables: {
 *      filterDate: // value for 'filterDate'
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useGetWorkerQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetWorkerQuery,
    Types.GetWorkerQueryVariables
  > &
    (
      | { variables: Types.GetWorkerQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetWorkerQuery, Types.GetWorkerQueryVariables>(
    GetWorkerDocument,
    options,
  );
}
export function useGetWorkerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetWorkerQuery,
    Types.GetWorkerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetWorkerQuery,
    Types.GetWorkerQueryVariables
  >(GetWorkerDocument, options);
}
export function useGetWorkerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetWorkerQuery,
    Types.GetWorkerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetWorkerQuery,
    Types.GetWorkerQueryVariables
  >(GetWorkerDocument, options);
}
export type GetWorkerQueryHookResult = ReturnType<typeof useGetWorkerQuery>;
export type GetWorkerLazyQueryHookResult = ReturnType<
  typeof useGetWorkerLazyQuery
>;
export type GetWorkerSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkerSuspenseQuery
>;
export type GetWorkerQueryResult = Apollo.QueryResult<
  Types.GetWorkerQuery,
  Types.GetWorkerQueryVariables
>;
export const GetWorkerJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkerJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobWorkerFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PositiveInt' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'worker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobWorkers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'showed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'job' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cancelled' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'order' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'addressLine1',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'addressLine2',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'timezone',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'city' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'state',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'zip' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'logoUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'firstShiftStartAt',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastShiftEndAt',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skill' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'imageUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetWorkerJobsQuery__
 *
 * To run a query within a React component, call `useGetWorkerJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerJobsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      workerId: // value for 'workerId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetWorkerJobsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetWorkerJobsQuery,
    Types.GetWorkerJobsQueryVariables
  > &
    (
      | { variables: Types.GetWorkerJobsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetWorkerJobsQuery,
    Types.GetWorkerJobsQueryVariables
  >(GetWorkerJobsDocument, options);
}
export function useGetWorkerJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetWorkerJobsQuery,
    Types.GetWorkerJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetWorkerJobsQuery,
    Types.GetWorkerJobsQueryVariables
  >(GetWorkerJobsDocument, options);
}
export function useGetWorkerJobsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetWorkerJobsQuery,
    Types.GetWorkerJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetWorkerJobsQuery,
    Types.GetWorkerJobsQueryVariables
  >(GetWorkerJobsDocument, options);
}
export type GetWorkerJobsQueryHookResult = ReturnType<
  typeof useGetWorkerJobsQuery
>;
export type GetWorkerJobsLazyQueryHookResult = ReturnType<
  typeof useGetWorkerJobsLazyQuery
>;
export type GetWorkerJobsSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkerJobsSuspenseQuery
>;
export type GetWorkerJobsQueryResult = Apollo.QueryResult<
  Types.GetWorkerJobsQuery,
  Types.GetWorkerJobsQueryVariables
>;
export const ResetWorkerPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetWorkerPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminResetWorkerPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ResetWorkerPasswordMutationFn = Apollo.MutationFunction<
  Types.ResetWorkerPasswordMutation,
  Types.ResetWorkerPasswordMutationVariables
>;

/**
 * __useResetWorkerPasswordMutation__
 *
 * To run a mutation, you first call `useResetWorkerPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetWorkerPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetWorkerPasswordMutation, { data, loading, error }] = useResetWorkerPasswordMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useResetWorkerPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ResetWorkerPasswordMutation,
    Types.ResetWorkerPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ResetWorkerPasswordMutation,
    Types.ResetWorkerPasswordMutationVariables
  >(ResetWorkerPasswordDocument, options);
}
export type ResetWorkerPasswordMutationHookResult = ReturnType<
  typeof useResetWorkerPasswordMutation
>;
export type ResetWorkerPasswordMutationResult =
  Apollo.MutationResult<Types.ResetWorkerPasswordMutation>;
export type ResetWorkerPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ResetWorkerPasswordMutation,
  Types.ResetWorkerPasswordMutationVariables
>;
export const GetShowcaseLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShowcaseLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'typeInterview' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WilloWorkerInterviewStatusEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'willoWorkerInterview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'typeInterview' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'typeInterview' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'showcaseLink' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetShowcaseLinkQuery__
 *
 * To run a query within a React component, call `useGetShowcaseLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowcaseLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowcaseLinkQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      typeInterview: // value for 'typeInterview'
 *   },
 * });
 */
export function useGetShowcaseLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetShowcaseLinkQuery,
    Types.GetShowcaseLinkQueryVariables
  > &
    (
      | { variables: Types.GetShowcaseLinkQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetShowcaseLinkQuery,
    Types.GetShowcaseLinkQueryVariables
  >(GetShowcaseLinkDocument, options);
}
export function useGetShowcaseLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetShowcaseLinkQuery,
    Types.GetShowcaseLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetShowcaseLinkQuery,
    Types.GetShowcaseLinkQueryVariables
  >(GetShowcaseLinkDocument, options);
}
export function useGetShowcaseLinkSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetShowcaseLinkQuery,
    Types.GetShowcaseLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetShowcaseLinkQuery,
    Types.GetShowcaseLinkQueryVariables
  >(GetShowcaseLinkDocument, options);
}
export type GetShowcaseLinkQueryHookResult = ReturnType<
  typeof useGetShowcaseLinkQuery
>;
export type GetShowcaseLinkLazyQueryHookResult = ReturnType<
  typeof useGetShowcaseLinkLazyQuery
>;
export type GetShowcaseLinkSuspenseQueryHookResult = ReturnType<
  typeof useGetShowcaseLinkSuspenseQuery
>;
export type GetShowcaseLinkQueryResult = Apollo.QueryResult<
  Types.GetShowcaseLinkQuery,
  Types.GetShowcaseLinkQueryVariables
>;
export const ChangeWorkerAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeWorkerAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'coords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PointInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerChangeAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coords' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'coords' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine2' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coords' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latitude' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longitude' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ChangeWorkerAddressMutationFn = Apollo.MutationFunction<
  Types.ChangeWorkerAddressMutation,
  Types.ChangeWorkerAddressMutationVariables
>;

/**
 * __useChangeWorkerAddressMutation__
 *
 * To run a mutation, you first call `useChangeWorkerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeWorkerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeWorkerAddressMutation, { data, loading, error }] = useChangeWorkerAddressMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      coords: // value for 'coords'
 *   },
 * });
 */
export function useChangeWorkerAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ChangeWorkerAddressMutation,
    Types.ChangeWorkerAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ChangeWorkerAddressMutation,
    Types.ChangeWorkerAddressMutationVariables
  >(ChangeWorkerAddressDocument, options);
}
export type ChangeWorkerAddressMutationHookResult = ReturnType<
  typeof useChangeWorkerAddressMutation
>;
export type ChangeWorkerAddressMutationResult =
  Apollo.MutationResult<Types.ChangeWorkerAddressMutation>;
export type ChangeWorkerAddressMutationOptions = Apollo.BaseMutationOptions<
  Types.ChangeWorkerAddressMutation,
  Types.ChangeWorkerAddressMutationVariables
>;
export const UpdateBackgroundStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBackgroundStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'backgroundStatus' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BackgroundStatusEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerUpdateBackgroundCheck' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'backgroundStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'backgroundStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'backgroundStatus' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateBackgroundStatusMutationFn = Apollo.MutationFunction<
  Types.UpdateBackgroundStatusMutation,
  Types.UpdateBackgroundStatusMutationVariables
>;

/**
 * __useUpdateBackgroundStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBackgroundStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBackgroundStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBackgroundStatusMutation, { data, loading, error }] = useUpdateBackgroundStatusMutation({
 *   variables: {
 *      backgroundStatus: // value for 'backgroundStatus'
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useUpdateBackgroundStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateBackgroundStatusMutation,
    Types.UpdateBackgroundStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateBackgroundStatusMutation,
    Types.UpdateBackgroundStatusMutationVariables
  >(UpdateBackgroundStatusDocument, options);
}
export type UpdateBackgroundStatusMutationHookResult = ReturnType<
  typeof useUpdateBackgroundStatusMutation
>;
export type UpdateBackgroundStatusMutationResult =
  Apollo.MutationResult<Types.UpdateBackgroundStatusMutation>;
export type UpdateBackgroundStatusMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateBackgroundStatusMutation,
  Types.UpdateBackgroundStatusMutationVariables
>;
export const AddWorkerCertificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddWorkerCertification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'certificateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerAddCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'certificateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activeCertificates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AddWorkerCertificationMutationFn = Apollo.MutationFunction<
  Types.AddWorkerCertificationMutation,
  Types.AddWorkerCertificationMutationVariables
>;

/**
 * __useAddWorkerCertificationMutation__
 *
 * To run a mutation, you first call `useAddWorkerCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkerCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkerCertificationMutation, { data, loading, error }] = useAddWorkerCertificationMutation({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useAddWorkerCertificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddWorkerCertificationMutation,
    Types.AddWorkerCertificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddWorkerCertificationMutation,
    Types.AddWorkerCertificationMutationVariables
  >(AddWorkerCertificationDocument, options);
}
export type AddWorkerCertificationMutationHookResult = ReturnType<
  typeof useAddWorkerCertificationMutation
>;
export type AddWorkerCertificationMutationResult =
  Apollo.MutationResult<Types.AddWorkerCertificationMutation>;
export type AddWorkerCertificationMutationOptions = Apollo.BaseMutationOptions<
  Types.AddWorkerCertificationMutation,
  Types.AddWorkerCertificationMutationVariables
>;
export const ListAgencyCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAgencyCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificateType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListAgencyCertificateQuery__
 *
 * To run a query within a React component, call `useListAgencyCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgencyCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgencyCertificateQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useListAgencyCertificateQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListAgencyCertificateQuery,
    Types.ListAgencyCertificateQueryVariables
  > &
    (
      | { variables: Types.ListAgencyCertificateQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListAgencyCertificateQuery,
    Types.ListAgencyCertificateQueryVariables
  >(ListAgencyCertificateDocument, options);
}
export function useListAgencyCertificateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListAgencyCertificateQuery,
    Types.ListAgencyCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListAgencyCertificateQuery,
    Types.ListAgencyCertificateQueryVariables
  >(ListAgencyCertificateDocument, options);
}
export function useListAgencyCertificateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListAgencyCertificateQuery,
    Types.ListAgencyCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListAgencyCertificateQuery,
    Types.ListAgencyCertificateQueryVariables
  >(ListAgencyCertificateDocument, options);
}
export type ListAgencyCertificateQueryHookResult = ReturnType<
  typeof useListAgencyCertificateQuery
>;
export type ListAgencyCertificateLazyQueryHookResult = ReturnType<
  typeof useListAgencyCertificateLazyQuery
>;
export type ListAgencyCertificateSuspenseQueryHookResult = ReturnType<
  typeof useListAgencyCertificateSuspenseQuery
>;
export type ListAgencyCertificateQueryResult = Apollo.QueryResult<
  Types.ListAgencyCertificateQuery,
  Types.ListAgencyCertificateQueryVariables
>;
export const RemoveWorkerCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveWorkerCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'certificateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerRemoveCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'certificateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activeCertificates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RemoveWorkerCertificateMutationFn = Apollo.MutationFunction<
  Types.RemoveWorkerCertificateMutation,
  Types.RemoveWorkerCertificateMutationVariables
>;

/**
 * __useRemoveWorkerCertificateMutation__
 *
 * To run a mutation, you first call `useRemoveWorkerCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkerCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkerCertificateMutation, { data, loading, error }] = useRemoveWorkerCertificateMutation({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useRemoveWorkerCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RemoveWorkerCertificateMutation,
    Types.RemoveWorkerCertificateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RemoveWorkerCertificateMutation,
    Types.RemoveWorkerCertificateMutationVariables
  >(RemoveWorkerCertificateDocument, options);
}
export type RemoveWorkerCertificateMutationHookResult = ReturnType<
  typeof useRemoveWorkerCertificateMutation
>;
export type RemoveWorkerCertificateMutationResult =
  Apollo.MutationResult<Types.RemoveWorkerCertificateMutation>;
export type RemoveWorkerCertificateMutationOptions = Apollo.BaseMutationOptions<
  Types.RemoveWorkerCertificateMutation,
  Types.RemoveWorkerCertificateMutationVariables
>;
export const AddWorkerSkillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddWorkerSkill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerAddSkill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'WorkerSkill' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerSkill' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AddWorkerSkillMutationFn = Apollo.MutationFunction<
  Types.AddWorkerSkillMutation,
  Types.AddWorkerSkillMutationVariables
>;

/**
 * __useAddWorkerSkillMutation__
 *
 * To run a mutation, you first call `useAddWorkerSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkerSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkerSkillMutation, { data, loading, error }] = useAddWorkerSkillMutation({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useAddWorkerSkillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddWorkerSkillMutation,
    Types.AddWorkerSkillMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddWorkerSkillMutation,
    Types.AddWorkerSkillMutationVariables
  >(AddWorkerSkillDocument, options);
}
export type AddWorkerSkillMutationHookResult = ReturnType<
  typeof useAddWorkerSkillMutation
>;
export type AddWorkerSkillMutationResult =
  Apollo.MutationResult<Types.AddWorkerSkillMutation>;
export type AddWorkerSkillMutationOptions = Apollo.BaseMutationOptions<
  Types.AddWorkerSkillMutation,
  Types.AddWorkerSkillMutationVariables
>;
export const ListAgencySkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAgencySkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skillCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'WorkerSkill' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerSkill' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListAgencySkillsQuery__
 *
 * To run a query within a React component, call `useListAgencySkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgencySkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgencySkillsQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useListAgencySkillsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListAgencySkillsQuery,
    Types.ListAgencySkillsQueryVariables
  > &
    (
      | { variables: Types.ListAgencySkillsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListAgencySkillsQuery,
    Types.ListAgencySkillsQueryVariables
  >(ListAgencySkillsDocument, options);
}
export function useListAgencySkillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListAgencySkillsQuery,
    Types.ListAgencySkillsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListAgencySkillsQuery,
    Types.ListAgencySkillsQueryVariables
  >(ListAgencySkillsDocument, options);
}
export function useListAgencySkillsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListAgencySkillsQuery,
    Types.ListAgencySkillsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListAgencySkillsQuery,
    Types.ListAgencySkillsQueryVariables
  >(ListAgencySkillsDocument, options);
}
export type ListAgencySkillsQueryHookResult = ReturnType<
  typeof useListAgencySkillsQuery
>;
export type ListAgencySkillsLazyQueryHookResult = ReturnType<
  typeof useListAgencySkillsLazyQuery
>;
export type ListAgencySkillsSuspenseQueryHookResult = ReturnType<
  typeof useListAgencySkillsSuspenseQuery
>;
export type ListAgencySkillsQueryResult = Apollo.QueryResult<
  Types.ListAgencySkillsQuery,
  Types.ListAgencySkillsQueryVariables
>;
export const RemoveWorkerSkillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveWorkerSkill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerRemoveSkill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'WorkerSkill' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkerSkill' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RemoveWorkerSkillMutationFn = Apollo.MutationFunction<
  Types.RemoveWorkerSkillMutation,
  Types.RemoveWorkerSkillMutationVariables
>;

/**
 * __useRemoveWorkerSkillMutation__
 *
 * To run a mutation, you first call `useRemoveWorkerSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkerSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkerSkillMutation, { data, loading, error }] = useRemoveWorkerSkillMutation({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useRemoveWorkerSkillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RemoveWorkerSkillMutation,
    Types.RemoveWorkerSkillMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RemoveWorkerSkillMutation,
    Types.RemoveWorkerSkillMutationVariables
  >(RemoveWorkerSkillDocument, options);
}
export type RemoveWorkerSkillMutationHookResult = ReturnType<
  typeof useRemoveWorkerSkillMutation
>;
export type RemoveWorkerSkillMutationResult =
  Apollo.MutationResult<Types.RemoveWorkerSkillMutation>;
export type RemoveWorkerSkillMutationOptions = Apollo.BaseMutationOptions<
  Types.RemoveWorkerSkillMutation,
  Types.RemoveWorkerSkillMutationVariables
>;
export const WorkerSignatureRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WorkerSignatureRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SignatureRequestTypeEnum' },
          },
          defaultValue: { kind: 'EnumValue', value: 'ONBOARDING_DOCS' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerSignatureRequestsTenant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useWorkerSignatureRequestsQuery__
 *
 * To run a query within a React component, call `useWorkerSignatureRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkerSignatureRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkerSignatureRequestsQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useWorkerSignatureRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WorkerSignatureRequestsQuery,
    Types.WorkerSignatureRequestsQueryVariables
  > &
    (
      | {
          variables: Types.WorkerSignatureRequestsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WorkerSignatureRequestsQuery,
    Types.WorkerSignatureRequestsQueryVariables
  >(WorkerSignatureRequestsDocument, options);
}
export function useWorkerSignatureRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WorkerSignatureRequestsQuery,
    Types.WorkerSignatureRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WorkerSignatureRequestsQuery,
    Types.WorkerSignatureRequestsQueryVariables
  >(WorkerSignatureRequestsDocument, options);
}
export function useWorkerSignatureRequestsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.WorkerSignatureRequestsQuery,
    Types.WorkerSignatureRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.WorkerSignatureRequestsQuery,
    Types.WorkerSignatureRequestsQueryVariables
  >(WorkerSignatureRequestsDocument, options);
}
export type WorkerSignatureRequestsQueryHookResult = ReturnType<
  typeof useWorkerSignatureRequestsQuery
>;
export type WorkerSignatureRequestsLazyQueryHookResult = ReturnType<
  typeof useWorkerSignatureRequestsLazyQuery
>;
export type WorkerSignatureRequestsSuspenseQueryHookResult = ReturnType<
  typeof useWorkerSignatureRequestsSuspenseQuery
>;
export type WorkerSignatureRequestsQueryResult = Apollo.QueryResult<
  Types.WorkerSignatureRequestsQuery,
  Types.WorkerSignatureRequestsQueryVariables
>;
export const WorkerResetVerificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'WorkerResetVerification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerResetVerification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine2' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarKey' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'averageRating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'backgroundStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'candidateStatus' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'candidateStatusUpdatedAt',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chatUid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'checkEmployeeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'checkWorkplaceId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'complianceId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deactivatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deactivatedBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultDistance' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deniedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disableReason' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formi9Restartable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasSsn' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'heardFrom' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'i9Status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interviewStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobsDropped' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobsFailed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobsRatingCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobsWorked' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastFourSsn' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'migrated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noShow' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onboardingDocsStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onboardingStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ownsCar' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'readyToWork' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reenableDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shiftsWorked' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ssnVerificationRetryCount',
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tcAcceptedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usCitizenOrWorkPermit' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verificationCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verificationRequiredAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verificationStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verifiedAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type WorkerResetVerificationMutationFn = Apollo.MutationFunction<
  Types.WorkerResetVerificationMutation,
  Types.WorkerResetVerificationMutationVariables
>;

/**
 * __useWorkerResetVerificationMutation__
 *
 * To run a mutation, you first call `useWorkerResetVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerResetVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerResetVerificationMutation, { data, loading, error }] = useWorkerResetVerificationMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useWorkerResetVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WorkerResetVerificationMutation,
    Types.WorkerResetVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WorkerResetVerificationMutation,
    Types.WorkerResetVerificationMutationVariables
  >(WorkerResetVerificationDocument, options);
}
export type WorkerResetVerificationMutationHookResult = ReturnType<
  typeof useWorkerResetVerificationMutation
>;
export type WorkerResetVerificationMutationResult =
  Apollo.MutationResult<Types.WorkerResetVerificationMutation>;
export type WorkerResetVerificationMutationOptions = Apollo.BaseMutationOptions<
  Types.WorkerResetVerificationMutation,
  Types.WorkerResetVerificationMutationVariables
>;
export const UpdateTaxTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTaxType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taxType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaxTypeEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hasSsn' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerUpdateTaxType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taxType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taxType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hasSsn' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hasSsn' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateTaxTypeMutationFn = Apollo.MutationFunction<
  Types.UpdateTaxTypeMutation,
  Types.UpdateTaxTypeMutationVariables
>;

/**
 * __useUpdateTaxTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxTypeMutation, { data, loading, error }] = useUpdateTaxTypeMutation({
 *   variables: {
 *      taxType: // value for 'taxType'
 *      workerId: // value for 'workerId'
 *      hasSsn: // value for 'hasSsn'
 *   },
 * });
 */
export function useUpdateTaxTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTaxTypeMutation,
    Types.UpdateTaxTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateTaxTypeMutation,
    Types.UpdateTaxTypeMutationVariables
  >(UpdateTaxTypeDocument, options);
}
export type UpdateTaxTypeMutationHookResult = ReturnType<
  typeof useUpdateTaxTypeMutation
>;
export type UpdateTaxTypeMutationResult =
  Apollo.MutationResult<Types.UpdateTaxTypeMutation>;
export type UpdateTaxTypeMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTaxTypeMutation,
  Types.UpdateTaxTypeMutationVariables
>;
export const WorkerDisableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'WorkerDisable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reason' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reenableDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601Date' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerDisable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reason' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reenableDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reenableDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type WorkerDisableMutationFn = Apollo.MutationFunction<
  Types.WorkerDisableMutation,
  Types.WorkerDisableMutationVariables
>;

/**
 * __useWorkerDisableMutation__
 *
 * To run a mutation, you first call `useWorkerDisableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerDisableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerDisableMutation, { data, loading, error }] = useWorkerDisableMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      reason: // value for 'reason'
 *      reenableDate: // value for 'reenableDate'
 *   },
 * });
 */
export function useWorkerDisableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WorkerDisableMutation,
    Types.WorkerDisableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WorkerDisableMutation,
    Types.WorkerDisableMutationVariables
  >(WorkerDisableDocument, options);
}
export type WorkerDisableMutationHookResult = ReturnType<
  typeof useWorkerDisableMutation
>;
export type WorkerDisableMutationResult =
  Apollo.MutationResult<Types.WorkerDisableMutation>;
export type WorkerDisableMutationOptions = Apollo.BaseMutationOptions<
  Types.WorkerDisableMutation,
  Types.WorkerDisableMutationVariables
>;
export const WorkerEnableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'WorkerEnable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerEnable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type WorkerEnableMutationFn = Apollo.MutationFunction<
  Types.WorkerEnableMutation,
  Types.WorkerEnableMutationVariables
>;

/**
 * __useWorkerEnableMutation__
 *
 * To run a mutation, you first call `useWorkerEnableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerEnableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerEnableMutation, { data, loading, error }] = useWorkerEnableMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useWorkerEnableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WorkerEnableMutation,
    Types.WorkerEnableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WorkerEnableMutation,
    Types.WorkerEnableMutationVariables
  >(WorkerEnableDocument, options);
}
export type WorkerEnableMutationHookResult = ReturnType<
  typeof useWorkerEnableMutation
>;
export type WorkerEnableMutationResult =
  Apollo.MutationResult<Types.WorkerEnableMutation>;
export type WorkerEnableMutationOptions = Apollo.BaseMutationOptions<
  Types.WorkerEnableMutation,
  Types.WorkerEnableMutationVariables
>;
export const WorkerUpdateProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'WorkerUpdateProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'middleName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfBirth' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GenderEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'heardFrom' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'language' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ownsCar' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'secondaryLanguage' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'smartphoneType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SmartphoneTypeEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hasSsn' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerUpdateProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'middleName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'middleName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateOfBirth' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateOfBirth' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'heardFrom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'heardFrom' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'language' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ownsCar' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ownsCar' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'secondaryLanguage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'secondaryLanguage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'smartphoneType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'smartphoneType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hasSsn' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hasSsn' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type WorkerUpdateProfileMutationFn = Apollo.MutationFunction<
  Types.WorkerUpdateProfileMutation,
  Types.WorkerUpdateProfileMutationVariables
>;

/**
 * __useWorkerUpdateProfileMutation__
 *
 * To run a mutation, you first call `useWorkerUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerUpdateProfileMutation, { data, loading, error }] = useWorkerUpdateProfileMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      firstName: // value for 'firstName'
 *      middleName: // value for 'middleName'
 *      lastName: // value for 'lastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *      gender: // value for 'gender'
 *      heardFrom: // value for 'heardFrom'
 *      language: // value for 'language'
 *      ownsCar: // value for 'ownsCar'
 *      secondaryLanguage: // value for 'secondaryLanguage'
 *      smartphoneType: // value for 'smartphoneType'
 *      hasSsn: // value for 'hasSsn'
 *   },
 * });
 */
export function useWorkerUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WorkerUpdateProfileMutation,
    Types.WorkerUpdateProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WorkerUpdateProfileMutation,
    Types.WorkerUpdateProfileMutationVariables
  >(WorkerUpdateProfileDocument, options);
}
export type WorkerUpdateProfileMutationHookResult = ReturnType<
  typeof useWorkerUpdateProfileMutation
>;
export type WorkerUpdateProfileMutationResult =
  Apollo.MutationResult<Types.WorkerUpdateProfileMutation>;
export type WorkerUpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  Types.WorkerUpdateProfileMutation,
  Types.WorkerUpdateProfileMutationVariables
>;
export const UpdateInterviewStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInterviewStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InterviewStatusEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerInterviewId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerInterviewUpdateV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'willoWorkerInterviewId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerInterviewId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'willoWorkerInterview' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateInterviewStatusMutationFn = Apollo.MutationFunction<
  Types.UpdateInterviewStatusMutation,
  Types.UpdateInterviewStatusMutationVariables
>;

/**
 * __useUpdateInterviewStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewStatusMutation, { data, loading, error }] = useUpdateInterviewStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      workerInterviewId: // value for 'workerInterviewId'
 *   },
 * });
 */
export function useUpdateInterviewStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateInterviewStatusMutation,
    Types.UpdateInterviewStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateInterviewStatusMutation,
    Types.UpdateInterviewStatusMutationVariables
  >(UpdateInterviewStatusDocument, options);
}
export type UpdateInterviewStatusMutationHookResult = ReturnType<
  typeof useUpdateInterviewStatusMutation
>;
export type UpdateInterviewStatusMutationResult =
  Apollo.MutationResult<Types.UpdateInterviewStatusMutation>;
export type UpdateInterviewStatusMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateInterviewStatusMutation,
  Types.UpdateInterviewStatusMutationVariables
>;
export const ChangeWorkerAvatarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeWorkerAvatar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'avatarKey' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerChangeAvatar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'avatarKey' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'avatarKey' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ChangeWorkerAvatarMutationFn = Apollo.MutationFunction<
  Types.ChangeWorkerAvatarMutation,
  Types.ChangeWorkerAvatarMutationVariables
>;

/**
 * __useChangeWorkerAvatarMutation__
 *
 * To run a mutation, you first call `useChangeWorkerAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeWorkerAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeWorkerAvatarMutation, { data, loading, error }] = useChangeWorkerAvatarMutation({
 *   variables: {
 *      avatarKey: // value for 'avatarKey'
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useChangeWorkerAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ChangeWorkerAvatarMutation,
    Types.ChangeWorkerAvatarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ChangeWorkerAvatarMutation,
    Types.ChangeWorkerAvatarMutationVariables
  >(ChangeWorkerAvatarDocument, options);
}
export type ChangeWorkerAvatarMutationHookResult = ReturnType<
  typeof useChangeWorkerAvatarMutation
>;
export type ChangeWorkerAvatarMutationResult =
  Apollo.MutationResult<Types.ChangeWorkerAvatarMutation>;
export type ChangeWorkerAvatarMutationOptions = Apollo.BaseMutationOptions<
  Types.ChangeWorkerAvatarMutation,
  Types.ChangeWorkerAvatarMutationVariables
>;
export const BlockWorkerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BlockWorker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'active' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reason' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerChangeActive' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'active' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'active' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reason' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type BlockWorkerMutationFn = Apollo.MutationFunction<
  Types.BlockWorkerMutation,
  Types.BlockWorkerMutationVariables
>;

/**
 * __useBlockWorkerMutation__
 *
 * To run a mutation, you first call `useBlockWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockWorkerMutation, { data, loading, error }] = useBlockWorkerMutation({
 *   variables: {
 *      active: // value for 'active'
 *      workerId: // value for 'workerId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useBlockWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.BlockWorkerMutation,
    Types.BlockWorkerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.BlockWorkerMutation,
    Types.BlockWorkerMutationVariables
  >(BlockWorkerDocument, options);
}
export type BlockWorkerMutationHookResult = ReturnType<
  typeof useBlockWorkerMutation
>;
export type BlockWorkerMutationResult =
  Apollo.MutationResult<Types.BlockWorkerMutation>;
export type BlockWorkerMutationOptions = Apollo.BaseMutationOptions<
  Types.BlockWorkerMutation,
  Types.BlockWorkerMutationVariables
>;
export const AddWorkerReferenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddWorkerReference' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'duration' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DurationEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'employerName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'summary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerAddReference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'duration' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'duration' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'employerName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'employerName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'summary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'summary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'title' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AddWorkerReferenceMutationFn = Apollo.MutationFunction<
  Types.AddWorkerReferenceMutation,
  Types.AddWorkerReferenceMutationVariables
>;

/**
 * __useAddWorkerReferenceMutation__
 *
 * To run a mutation, you first call `useAddWorkerReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkerReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkerReferenceMutation, { data, loading, error }] = useAddWorkerReferenceMutation({
 *   variables: {
 *      city: // value for 'city'
 *      duration: // value for 'duration'
 *      email: // value for 'email'
 *      employerName: // value for 'employerName'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      summary: // value for 'summary'
 *      title: // value for 'title'
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useAddWorkerReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddWorkerReferenceMutation,
    Types.AddWorkerReferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddWorkerReferenceMutation,
    Types.AddWorkerReferenceMutationVariables
  >(AddWorkerReferenceDocument, options);
}
export type AddWorkerReferenceMutationHookResult = ReturnType<
  typeof useAddWorkerReferenceMutation
>;
export type AddWorkerReferenceMutationResult =
  Apollo.MutationResult<Types.AddWorkerReferenceMutation>;
export type AddWorkerReferenceMutationOptions = Apollo.BaseMutationOptions<
  Types.AddWorkerReferenceMutation,
  Types.AddWorkerReferenceMutationVariables
>;
export const CreateWorkerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateWorker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'coords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PointInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'middleName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfBirth' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenderEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'language' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LanguageEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ownsCar' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'secondaryLanguage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LanguageEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coords' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'coords' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'middleName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'middleName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateOfBirth' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateOfBirth' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'language' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ownsCar' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ownsCar' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'secondaryLanguage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'secondaryLanguage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateWorkerMutationFn = Apollo.MutationFunction<
  Types.CreateWorkerMutation,
  Types.CreateWorkerMutationVariables
>;

/**
 * __useCreateWorkerMutation__
 *
 * To run a mutation, you first call `useCreateWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkerMutation, { data, loading, error }] = useCreateWorkerMutation({
 *   variables: {
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      agencyId: // value for 'agencyId'
 *      city: // value for 'city'
 *      coords: // value for 'coords'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      middleName: // value for 'middleName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      gender: // value for 'gender'
 *      language: // value for 'language'
 *      lastName: // value for 'lastName'
 *      ownsCar: // value for 'ownsCar'
 *      phoneNumber: // value for 'phoneNumber'
 *      secondaryLanguage: // value for 'secondaryLanguage'
 *      skillIds: // value for 'skillIds'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useCreateWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateWorkerMutation,
    Types.CreateWorkerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateWorkerMutation,
    Types.CreateWorkerMutationVariables
  >(CreateWorkerDocument, options);
}
export type CreateWorkerMutationHookResult = ReturnType<
  typeof useCreateWorkerMutation
>;
export type CreateWorkerMutationResult =
  Apollo.MutationResult<Types.CreateWorkerMutation>;
export type CreateWorkerMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateWorkerMutation,
  Types.CreateWorkerMutationVariables
>;
export const UpdateApplicantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateApplicant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine1' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'candidateStatus' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CandidateStatusEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'middleName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'language' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LanguageEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ownsCar' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfBirth' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateOrEmptyString' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'secondaryLanguage' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'smartphoneType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SmartphoneTypeEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taxType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaxTypeEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hasSsn' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerUpdateApplicant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'candidateStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'candidateStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'middleName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'middleName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'language' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateOfBirth' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateOfBirth' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ownsCar' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ownsCar' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'secondaryLanguage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'secondaryLanguage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'smartphoneType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'smartphoneType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taxType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taxType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hasSsn' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hasSsn' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateApplicantMutationFn = Apollo.MutationFunction<
  Types.UpdateApplicantMutation,
  Types.UpdateApplicantMutationVariables
>;

/**
 * __useUpdateApplicantMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantMutation, { data, loading, error }] = useUpdateApplicantMutation({
 *   variables: {
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      candidateStatus: // value for 'candidateStatus'
 *      city: // value for 'city'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      middleName: // value for 'middleName'
 *      language: // value for 'language'
 *      lastName: // value for 'lastName'
 *      ownsCar: // value for 'ownsCar'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      phoneNumber: // value for 'phoneNumber'
 *      secondaryLanguage: // value for 'secondaryLanguage'
 *      skillIds: // value for 'skillIds'
 *      smartphoneType: // value for 'smartphoneType'
 *      state: // value for 'state'
 *      taxType: // value for 'taxType'
 *      workerId: // value for 'workerId'
 *      zip: // value for 'zip'
 *      hasSsn: // value for 'hasSsn'
 *   },
 * });
 */
export function useUpdateApplicantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateApplicantMutation,
    Types.UpdateApplicantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateApplicantMutation,
    Types.UpdateApplicantMutationVariables
  >(UpdateApplicantDocument, options);
}
export type UpdateApplicantMutationHookResult = ReturnType<
  typeof useUpdateApplicantMutation
>;
export type UpdateApplicantMutationResult =
  Apollo.MutationResult<Types.UpdateApplicantMutation>;
export type UpdateApplicantMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateApplicantMutation,
  Types.UpdateApplicantMutationVariables
>;
export const UpdateCandidateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCandidate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine1' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'candidateStatus' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CandidateStatusEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'middleName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'language' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfBirth' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'secondaryLanguage' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'smartphoneType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SmartphoneTypeEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workerUpdateCandidate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'candidateStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'candidateStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'middleName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'middleName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateOfBirth' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateOfBirth' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'language' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'secondaryLanguage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'secondaryLanguage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'smartphoneType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'smartphoneType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worker' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'candidateStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'middleName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateCandidateMutationFn = Apollo.MutationFunction<
  Types.UpdateCandidateMutation,
  Types.UpdateCandidateMutationVariables
>;

/**
 * __useUpdateCandidateMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateMutation, { data, loading, error }] = useUpdateCandidateMutation({
 *   variables: {
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      candidateStatus: // value for 'candidateStatus'
 *      city: // value for 'city'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      middleName: // value for 'middleName'
 *      language: // value for 'language'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      secondaryLanguage: // value for 'secondaryLanguage'
 *      smartphoneType: // value for 'smartphoneType'
 *      state: // value for 'state'
 *      workerId: // value for 'workerId'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useUpdateCandidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCandidateMutation,
    Types.UpdateCandidateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateCandidateMutation,
    Types.UpdateCandidateMutationVariables
  >(UpdateCandidateDocument, options);
}
export type UpdateCandidateMutationHookResult = ReturnType<
  typeof useUpdateCandidateMutation
>;
export type UpdateCandidateMutationResult =
  Apollo.MutationResult<Types.UpdateCandidateMutation>;
export type UpdateCandidateMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCandidateMutation,
  Types.UpdateCandidateMutationVariables
>;
export const ListCandidatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListCandidates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CandidateFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'candidates' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deactivatedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deactivatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deactivatedByUser',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'disableReason' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'candidateStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'backgroundStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onboardingStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'interviewStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine1' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timezone' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coords' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasSsn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ownsCar' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'taxType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dateOfBirth',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'language' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'phoneNumber',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'secondaryLanguage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'smartphoneType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'references' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'employerName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'phoneNumber',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'summary' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'summary' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skills' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListCandidatesQuery__
 *
 * To run a query within a React component, call `useListCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCandidatesQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useListCandidatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListCandidatesQuery,
    Types.ListCandidatesQueryVariables
  > &
    (
      | { variables: Types.ListCandidatesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListCandidatesQuery,
    Types.ListCandidatesQueryVariables
  >(ListCandidatesDocument, options);
}
export function useListCandidatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListCandidatesQuery,
    Types.ListCandidatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListCandidatesQuery,
    Types.ListCandidatesQueryVariables
  >(ListCandidatesDocument, options);
}
export function useListCandidatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListCandidatesQuery,
    Types.ListCandidatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListCandidatesQuery,
    Types.ListCandidatesQueryVariables
  >(ListCandidatesDocument, options);
}
export type ListCandidatesQueryHookResult = ReturnType<
  typeof useListCandidatesQuery
>;
export type ListCandidatesLazyQueryHookResult = ReturnType<
  typeof useListCandidatesLazyQuery
>;
export type ListCandidatesSuspenseQueryHookResult = ReturnType<
  typeof useListCandidatesSuspenseQuery
>;
export type ListCandidatesQueryResult = Apollo.QueryResult<
  Types.ListCandidatesQuery,
  Types.ListCandidatesQueryVariables
>;
export const ListWorkersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListWorkers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WorkerFilterSetInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PositiveInt' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'candidateStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deactivatedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deactivatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deactivatedByUser',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'disableReason' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'interviewStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLine1' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gender' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'averageRating' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobsRatingCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skills' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'activeCertificates',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'language' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'phoneNumber',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalItems' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPages' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useListWorkersQuery__
 *
 * To run a query within a React component, call `useListWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkersQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useListWorkersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ListWorkersQuery,
    Types.ListWorkersQueryVariables
  > &
    (
      | { variables: Types.ListWorkersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ListWorkersQuery,
    Types.ListWorkersQueryVariables
  >(ListWorkersDocument, options);
}
export function useListWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ListWorkersQuery,
    Types.ListWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ListWorkersQuery,
    Types.ListWorkersQueryVariables
  >(ListWorkersDocument, options);
}
export function useListWorkersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.ListWorkersQuery,
    Types.ListWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.ListWorkersQuery,
    Types.ListWorkersQueryVariables
  >(ListWorkersDocument, options);
}
export type ListWorkersQueryHookResult = ReturnType<typeof useListWorkersQuery>;
export type ListWorkersLazyQueryHookResult = ReturnType<
  typeof useListWorkersLazyQuery
>;
export type ListWorkersSuspenseQueryHookResult = ReturnType<
  typeof useListWorkersSuspenseQuery
>;
export type ListWorkersQueryResult = Apollo.QueryResult<
  Types.ListWorkersQuery,
  Types.ListWorkersQueryVariables
>;
export const GetCurrentActorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentActor' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentActor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CurrentTenantAdmin' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CurrentCustomerAdmin' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentCustomerAdmin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationStats' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboardingStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ltaAllowed' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentTenantAdmin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pendingCustomers' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserItemFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserItemFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationStats' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCurrentActorQuery__
 *
 * To run a query within a React component, call `useGetCurrentActorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentActorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentActorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentActorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetCurrentActorQuery,
    Types.GetCurrentActorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCurrentActorQuery,
    Types.GetCurrentActorQueryVariables
  >(GetCurrentActorDocument, options);
}
export function useGetCurrentActorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCurrentActorQuery,
    Types.GetCurrentActorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCurrentActorQuery,
    Types.GetCurrentActorQueryVariables
  >(GetCurrentActorDocument, options);
}
export function useGetCurrentActorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetCurrentActorQuery,
    Types.GetCurrentActorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetCurrentActorQuery,
    Types.GetCurrentActorQueryVariables
  >(GetCurrentActorDocument, options);
}
export type GetCurrentActorQueryHookResult = ReturnType<
  typeof useGetCurrentActorQuery
>;
export type GetCurrentActorLazyQueryHookResult = ReturnType<
  typeof useGetCurrentActorLazyQuery
>;
export type GetCurrentActorSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentActorSuspenseQuery
>;
export type GetCurrentActorQueryResult = Apollo.QueryResult<
  Types.GetCurrentActorQuery,
  Types.GetCurrentActorQueryVariables
>;
export const ForgotPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForgotPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'app' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TypeAppEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userForgotPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'app' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'app' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  Types.ForgotPasswordMutation,
  Types.ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      app: // value for 'app'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ForgotPasswordMutation,
    Types.ForgotPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ForgotPasswordMutation,
    Types.ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<Types.ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ForgotPasswordMutation,
  Types.ForgotPasswordMutationVariables
>;
export const AdminLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminLogin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminLogin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tenantId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refreshToken' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resetPassword' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantAdmin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CurrentTenantAdmin' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerAdmin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CurrentCustomerAdmin' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentCustomerAdmin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationStats' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboardingStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ltaAllowed' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentTenantAdmin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantAdmin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pendingCustomers' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserItemFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserItemFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailConfirmed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationStats' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AdminLoginMutationFn = Apollo.MutationFunction<
  Types.AdminLoginMutation,
  Types.AdminLoginMutationVariables
>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AdminLoginMutation,
    Types.AdminLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AdminLoginMutation,
    Types.AdminLoginMutationVariables
  >(AdminLoginDocument, options);
}
export type AdminLoginMutationHookResult = ReturnType<
  typeof useAdminLoginMutation
>;
export type AdminLoginMutationResult =
  Apollo.MutationResult<Types.AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<
  Types.AdminLoginMutation,
  Types.AdminLoginMutationVariables
>;
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userResetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ResetPasswordMutation,
    Types.ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ResetPasswordMutation,
    Types.ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>;
export const UserSetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'userSetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'repeatedPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAuthResetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'repeatedPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'repeatedPassword' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resetPassword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UserSetPasswordMutationFn = Apollo.MutationFunction<
  Types.UserSetPasswordMutation,
  Types.UserSetPasswordMutationVariables
>;

/**
 * __useUserSetPasswordMutation__
 *
 * To run a mutation, you first call `useUserSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSetPasswordMutation, { data, loading, error }] = useUserSetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      repeatedPassword: // value for 'repeatedPassword'
 *   },
 * });
 */
export function useUserSetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UserSetPasswordMutation,
    Types.UserSetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UserSetPasswordMutation,
    Types.UserSetPasswordMutationVariables
  >(UserSetPasswordDocument, options);
}
export type UserSetPasswordMutationHookResult = ReturnType<
  typeof useUserSetPasswordMutation
>;
export type UserSetPasswordMutationResult =
  Apollo.MutationResult<Types.UserSetPasswordMutation>;
export type UserSetPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.UserSetPasswordMutation,
  Types.UserSetPasswordMutationVariables
>;
export const GetActiveRegionStatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActiveRegionStates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeRegionState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tenantId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetActiveRegionStatesQuery__
 *
 * To run a query within a React component, call `useGetActiveRegionStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveRegionStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveRegionStatesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetActiveRegionStatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetActiveRegionStatesQuery,
    Types.GetActiveRegionStatesQueryVariables
  > &
    (
      | { variables: Types.GetActiveRegionStatesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetActiveRegionStatesQuery,
    Types.GetActiveRegionStatesQueryVariables
  >(GetActiveRegionStatesDocument, options);
}
export function useGetActiveRegionStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetActiveRegionStatesQuery,
    Types.GetActiveRegionStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetActiveRegionStatesQuery,
    Types.GetActiveRegionStatesQueryVariables
  >(GetActiveRegionStatesDocument, options);
}
export function useGetActiveRegionStatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetActiveRegionStatesQuery,
    Types.GetActiveRegionStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetActiveRegionStatesQuery,
    Types.GetActiveRegionStatesQueryVariables
  >(GetActiveRegionStatesDocument, options);
}
export type GetActiveRegionStatesQueryHookResult = ReturnType<
  typeof useGetActiveRegionStatesQuery
>;
export type GetActiveRegionStatesLazyQueryHookResult = ReturnType<
  typeof useGetActiveRegionStatesLazyQuery
>;
export type GetActiveRegionStatesSuspenseQueryHookResult = ReturnType<
  typeof useGetActiveRegionStatesSuspenseQuery
>;
export type GetActiveRegionStatesQueryResult = Apollo.QueryResult<
  Types.GetActiveRegionStatesQuery,
  Types.GetActiveRegionStatesQueryVariables
>;
export const GetTenantByKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTenantByKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Key' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantByKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CurrentTenant' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentTenant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Tenant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetTenantByKeyQuery__
 *
 * To run a query within a React component, call `useGetTenantByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetTenantByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetTenantByKeyQuery,
    Types.GetTenantByKeyQueryVariables
  > &
    (
      | { variables: Types.GetTenantByKeyQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetTenantByKeyQuery,
    Types.GetTenantByKeyQueryVariables
  >(GetTenantByKeyDocument, options);
}
export function useGetTenantByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetTenantByKeyQuery,
    Types.GetTenantByKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetTenantByKeyQuery,
    Types.GetTenantByKeyQueryVariables
  >(GetTenantByKeyDocument, options);
}
export function useGetTenantByKeySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetTenantByKeyQuery,
    Types.GetTenantByKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.GetTenantByKeyQuery,
    Types.GetTenantByKeyQueryVariables
  >(GetTenantByKeyDocument, options);
}
export type GetTenantByKeyQueryHookResult = ReturnType<
  typeof useGetTenantByKeyQuery
>;
export type GetTenantByKeyLazyQueryHookResult = ReturnType<
  typeof useGetTenantByKeyLazyQuery
>;
export type GetTenantByKeySuspenseQueryHookResult = ReturnType<
  typeof useGetTenantByKeySuspenseQuery
>;
export type GetTenantByKeyQueryResult = Apollo.QueryResult<
  Types.GetTenantByKeyQuery,
  Types.GetTenantByKeyQueryVariables
>;
export const CustomerAdminSignUpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CustomerAdminSignUp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressLine2' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'agencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'coords' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PointInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referral' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ReferralEnum' },
          },
          defaultValue: { kind: 'EnumValue', value: 'OTHER' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'neighborhood' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerSignup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressLine2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressLine2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'agencyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coords' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'coords' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referral' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'referral' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'neighborhood' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'neighborhood' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CustomerAdminSignUpMutationFn = Apollo.MutationFunction<
  Types.CustomerAdminSignUpMutation,
  Types.CustomerAdminSignUpMutationVariables
>;

/**
 * __useCustomerAdminSignUpMutation__
 *
 * To run a mutation, you first call `useCustomerAdminSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAdminSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAdminSignUpMutation, { data, loading, error }] = useCustomerAdminSignUpMutation({
 *   variables: {
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      agencyId: // value for 'agencyId'
 *      city: // value for 'city'
 *      coords: // value for 'coords'
 *      customerName: // value for 'customerName'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      phoneNumber: // value for 'phoneNumber'
 *      referral: // value for 'referral'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      neighborhood: // value for 'neighborhood'
 *   },
 * });
 */
export function useCustomerAdminSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CustomerAdminSignUpMutation,
    Types.CustomerAdminSignUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CustomerAdminSignUpMutation,
    Types.CustomerAdminSignUpMutationVariables
  >(CustomerAdminSignUpDocument, options);
}
export type CustomerAdminSignUpMutationHookResult = ReturnType<
  typeof useCustomerAdminSignUpMutation
>;
export type CustomerAdminSignUpMutationResult =
  Apollo.MutationResult<Types.CustomerAdminSignUpMutation>;
export type CustomerAdminSignUpMutationOptions = Apollo.BaseMutationOptions<
  Types.CustomerAdminSignUpMutation,
  Types.CustomerAdminSignUpMutationVariables
>;
export const CheckDuplicateCustomerNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckDuplicateCustomerName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerNameDuplicate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientName' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCheckDuplicateCustomerNameQuery__
 *
 * To run a query within a React component, call `useCheckDuplicateCustomerNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDuplicateCustomerNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDuplicateCustomerNameQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useCheckDuplicateCustomerNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CheckDuplicateCustomerNameQuery,
    Types.CheckDuplicateCustomerNameQueryVariables
  > &
    (
      | {
          variables: Types.CheckDuplicateCustomerNameQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CheckDuplicateCustomerNameQuery,
    Types.CheckDuplicateCustomerNameQueryVariables
  >(CheckDuplicateCustomerNameDocument, options);
}
export function useCheckDuplicateCustomerNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CheckDuplicateCustomerNameQuery,
    Types.CheckDuplicateCustomerNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CheckDuplicateCustomerNameQuery,
    Types.CheckDuplicateCustomerNameQueryVariables
  >(CheckDuplicateCustomerNameDocument, options);
}
export function useCheckDuplicateCustomerNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.CheckDuplicateCustomerNameQuery,
    Types.CheckDuplicateCustomerNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.CheckDuplicateCustomerNameQuery,
    Types.CheckDuplicateCustomerNameQueryVariables
  >(CheckDuplicateCustomerNameDocument, options);
}
export type CheckDuplicateCustomerNameQueryHookResult = ReturnType<
  typeof useCheckDuplicateCustomerNameQuery
>;
export type CheckDuplicateCustomerNameLazyQueryHookResult = ReturnType<
  typeof useCheckDuplicateCustomerNameLazyQuery
>;
export type CheckDuplicateCustomerNameSuspenseQueryHookResult = ReturnType<
  typeof useCheckDuplicateCustomerNameSuspenseQuery
>;
export type CheckDuplicateCustomerNameQueryResult = Apollo.QueryResult<
  Types.CheckDuplicateCustomerNameQuery,
  Types.CheckDuplicateCustomerNameQueryVariables
>;
export const CheckExistingEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckExistingEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Email' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'existingUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCheckExistingEmailQuery__
 *
 * To run a query within a React component, call `useCheckExistingEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExistingEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExistingEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckExistingEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CheckExistingEmailQuery,
    Types.CheckExistingEmailQueryVariables
  > &
    (
      | { variables: Types.CheckExistingEmailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CheckExistingEmailQuery,
    Types.CheckExistingEmailQueryVariables
  >(CheckExistingEmailDocument, options);
}
export function useCheckExistingEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CheckExistingEmailQuery,
    Types.CheckExistingEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CheckExistingEmailQuery,
    Types.CheckExistingEmailQueryVariables
  >(CheckExistingEmailDocument, options);
}
export function useCheckExistingEmailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.CheckExistingEmailQuery,
    Types.CheckExistingEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Types.CheckExistingEmailQuery,
    Types.CheckExistingEmailQueryVariables
  >(CheckExistingEmailDocument, options);
}
export type CheckExistingEmailQueryHookResult = ReturnType<
  typeof useCheckExistingEmailQuery
>;
export type CheckExistingEmailLazyQueryHookResult = ReturnType<
  typeof useCheckExistingEmailLazyQuery
>;
export type CheckExistingEmailSuspenseQueryHookResult = ReturnType<
  typeof useCheckExistingEmailSuspenseQuery
>;
export type CheckExistingEmailQueryResult = Apollo.QueryResult<
  Types.CheckExistingEmailQuery,
  Types.CheckExistingEmailQueryVariables
>;
export const UserResendTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserResendToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userResendToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UserResendTokenMutationFn = Apollo.MutationFunction<
  Types.UserResendTokenMutation,
  Types.UserResendTokenMutationVariables
>;

/**
 * __useUserResendTokenMutation__
 *
 * To run a mutation, you first call `useUserResendTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserResendTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userResendTokenMutation, { data, loading, error }] = useUserResendTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserResendTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UserResendTokenMutation,
    Types.UserResendTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UserResendTokenMutation,
    Types.UserResendTokenMutationVariables
  >(UserResendTokenDocument, options);
}
export type UserResendTokenMutationHookResult = ReturnType<
  typeof useUserResendTokenMutation
>;
export type UserResendTokenMutationResult =
  Apollo.MutationResult<Types.UserResendTokenMutation>;
export type UserResendTokenMutationOptions = Apollo.BaseMutationOptions<
  Types.UserResendTokenMutation,
  Types.UserResendTokenMutationVariables
>;
