import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import NotificationDateTimePicker from '@/components/NotificationDateTimePicker';
import Stack from '@/components/Stack';
import { GAEvent } from '@/constants/gaevents';
import Form from '@/form';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import {
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
} from '@/graphql';
import { NotificationTypeEnum } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import dayjs from 'dayjs';
import timzezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { NotificationType } from '../constants';

type Props = {
  hideModal: () => void;
  updateList: () => void;
  notificationToEdit: any | null;
};

type FormValues = NotificationType;

dayjs.extend(timzezone);
dayjs.extend(utc);
const CreateNotificationModal = ({
  updateList,
  hideModal,
  notificationToEdit,
}: Props) => {
  const [error, setError] = useState({
    active: false,
    data: {},
  });
  const { logEvent } = useAnalytics();
  const datTimeFormat = 'MM/DD/YYYY hh:mm:ss aa';
  const [initialValues, setInitialValues] = useState<FormValues>({
    id: notificationToEdit?.id,
    message: notificationToEdit?.message || '',
    link: notificationToEdit?.link || '',
    notificationType:
      notificationToEdit?.notificationType ||
      NotificationTypeEnum.RELEASE_NOTES,
    publishAt: notificationToEdit?.publishAt,
    title: notificationToEdit?.title || '',
  });

  const [addNotification, { loading: addNotificationloading }] =
    useCreateNotificationMutation({
      onError: (err) => {
        setError({ active: true, data: err });
      },
    });

  const [updateNotification, { loading: updateNotificationloading }] =
    useUpdateNotificationMutation({
      onError: (err) => {
        setError({ active: true, data: err });
      },
    });

  function validateURL(url: string): boolean {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z0-9-]+)\\.)+[a-zA-Z]{2,})' + // domain name
        '(\\:[0-9]{1,5})?' + // port
        '(\\/.*)?$', // path
      'i', // case insensitive
    );

    return urlPattern.test(url);
  }

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        if (values.link && !validateURL(values.link)) {
          setError({
            active: true,
            data: { message: 'Link is not valid' },
          });
          return;
        }
        if (
          values.message &&
          values.notificationType &&
          initialValues.publishAt &&
          values.title
        ) {
          if (notificationToEdit?.id) {
            await updateNotification({
              variables: {
                id: notificationToEdit.id,
                link: values.link,
                message: values.message,
                notificationType: values.notificationType,
                publishAt: initialValues.publishAt,
                title: values.title,
              },
            });
            logEvent(GAEvent.ReleaseNoteEdited);
          } else {
            await addNotification({
              variables: {
                link: values.link,
                message: values.message,
                notificationType: values.notificationType,
                publishAt: initialValues.publishAt,
                title: values.title,
              },
            });
            logEvent(GAEvent.ReleaseNoteAdded);
          }
          await updateList();
        } else {
          setError({
            active: true,
            data: { message: 'All fields are required' },
          });
        }
        hideModal();
      } catch (err) {
        setError({ active: true, data: err });
      }
    },
    [initialValues.publishAt],
  );

  const notificationType = (type) => {
    switch (type) {
      case 'RELEASE_NOTES':
        return 'Release Notes';
        return '';
    }
  };

  return (
    <Modal
      disableClickout
      size="xs"
      title={notificationToEdit?.message ? 'Edit Entry' : 'Add New Entry'}
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error.active && (
          <Alert
            description={error.data.message}
            icon={faExclamationTriangle}
            status="warning"
            title="Something went wrong"
          />
        )}
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <TextSelectField
            required
            callback={(fieldContext) => {
              setInitialValues({
                ...initialValues,
                notificationType: fieldContext.value as NotificationTypeEnum,
              });
            }}
            fieldId="notificationType"
            label="Type"
            options={Object.values(NotificationTypeEnum).map((value) => ({
              label: notificationType(value),
              value,
            }))}
            showRequiredIndicator
          />
          <TextField
            required
            autoComplete="title"
            fieldId="title"
            label="Title"
            placeholder="Title"
            showRequiredIndicator
            maxLength={40}
            showLengthIndicator
          />
          <TextField
            required
            autoComplete="message"
            fieldId="message"
            label="Description"
            placeholder="Add one-liner here"
            showRequiredIndicator
            maxLength={100}
            showLengthIndicator
          />
          <NotificationDateTimePicker
            name="Publish Date and Time"
            label="Publish Date and Time"
            placeholder={datTimeFormat}
            fieldId="publishAt"
            defaultValue={
              initialValues.publishAt
                ? dayjs.utc(initialValues.publishAt).tz('America/New_York')
                : undefined
            }
            onChange={(date) => {
              setInitialValues({
                ...initialValues,
                publishAt: date.toISOString(),
              });
            }}
            required
            showRequiredIndicator
          />
          <TextField
            autoComplete="link"
            fieldId="link"
            label="Link"
            placeholder="Add URL"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Close Add New Notiifaiton"
              appearance="outline"
              label="Cancel"
              type="button"
              onClick={hideModal}
            />
            <Button
              a11yLabel="Submit form"
              id="send-invite-btn"
              isLoading={addNotificationloading || updateNotificationloading}
              label={notificationToEdit?.message ? 'Save' : 'Add'}
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default CreateNotificationModal;
