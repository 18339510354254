import Version from '@/components/Version';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { privacyPolicyUrl } from '@/constants/urls';
import Link from '@/elements/Link';
import Text from '@/elements/Text';
import styled from '@/styles';
import { isWeb } from '@/util/platform';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useMediaQuery } from '@mui/material';
import Config from '@/config';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';

const FOOTER_HEIGHT = '82px';

const Wrapper = styled('footer', {
  alignItems: 'center',
  backgroundColor: '$neutralA4',
  display: 'flex',
  gridArea: 'footer',
  justifyContent: 'space-between',
  height: FOOTER_HEIGHT,
  padding: '0 32px',
  '@phoneOnly': {
    padding: '0 8px',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    gap: '5px',
  },
});

const InnerContainer = styled('footer', {
  alignItems: 'center',
  display: 'flex',
  gridArea: 'footer',
  justifyContent: 'center',
  paddingTop: '10px',
  width: '20%',
  gap: '10px',
  '@phoneOnly': {
    width: '60%',
    gap: '0px',
  },
});

const WrapperUpper = styled('footer', {
  alignItems: 'center',
  backgroundColor: '$neutralA4',
  display: 'flex',
  gridArea: 'footer',
  justifyContent: 'center',
  paddingTop: '10px',
  flexDirection: 'column',
  '@phoneOnly': {
    paddingTop: '10px',
    justifyContent: 'center',
    paddingBottom: '5px',
  },
});

const TextWrapper = styled('div', {
  width: '30%',
  '@phoneOnly': {
    width: 'stretch',
    textAlign: 'center',
  },
});

const TextWrapperRight = styled('div', {
  width: '30%',
  textAlign: 'right',
  '@phoneOnly': {
    width: 'stretch',
    textAlign: 'center',
  },
});

const Img = styled('img', {
  width: '100%',
  maxWidth: '150px',
  resizeMode: 'contain',
  '@phoneOnly': {
    width: '40%',
    resizeMode: 'contain',
  },
});

Wrapper.displayName = 'stitches(AppFooter.Wrapper)';

const Footer = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const openAppLink = useFeatureValue(FEATURE_TOGGLE.OpenAppLink, false);
  const { logEvent } = useAnalytics();
  const isIOSDevice = /iPad|iPhone|iPod|Mac/.test(navigator.userAgent);

  const renderDownloadButtons = () => {
    if (phoneOnly) {
      return (
        <>
          {isIOSDevice ? (
            <Img
              onClick={() => {
                logEvent(GAEvent.OpenInAppClickedFromWeb);
                window.open(Config.APP_STORE_REDIRECT_URL, '_blank');
              }}
              style={{ cursor: 'pointer' }}
              src={require('../assets/img/apple.png')}
            />
          ) : (
            <Img
              onClick={() => {
                logEvent(GAEvent.OpenInAppClickedFromWeb);
                window.open(Config.PLAY_STORE_REDIRECT_URL, '_blank');
              }}
              style={{ cursor: 'pointer' }}
              src={require('../assets/img/google.png')}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          <Img
            onClick={() => {
              logEvent(GAEvent.OpenInAppClickedFromWeb);
              window.open(Config.PLAY_STORE_REDIRECT_URL, '_blank');
            }}
            style={{ cursor: 'pointer' }}
            src={require('../assets/img/google.png')}
          />
          <Img
            onClick={() => {
              logEvent(GAEvent.OpenInAppClickedFromWeb);
              window.open(Config.APP_STORE_REDIRECT_URL, '_blank');
            }}
            style={{ cursor: 'pointer' }}
            src={require('../assets/img/apple.png')}
          />
        </>
      );
    }
  };

  return (
    <div>
      {openAppLink && isWeb() && (
        <WrapperUpper>
          <Text color="light" weight="bold" size="sm">
            Take GravyWork on the go - download the app!
          </Text>
          <InnerContainer>{renderDownloadButtons()}</InnerContainer>
        </WrapperUpper>
      )}
      <Wrapper>
        <TextWrapper>
          <Version size="sm" />
        </TextWrapper>
        <Link
          target="_self"
          to={{ pathname: `/` }}
          onClick={() => {
            window.open(privacyPolicyUrl, '_self');
          }}
        >
          <Text color="lighter" size="sm">
            Privacy Policy
          </Text>
        </Link>
        <TextWrapperRight>
          <Text color="lighter" size="sm" padding="rightSm">
            &copy; {new Date().getFullYear()} Gravy Workforce Technologies
          </Text>
        </TextWrapperRight>
      </Wrapper>
    </div>
  );
};

export { FOOTER_HEIGHT };
export default Footer;
