import { PartialRouteObject } from 'react-router';
import { Navigate, useRoutes } from 'react-router-dom';

import InappChat from './Agency/Chat/Pubnub';
import PayInvoice from './Agency/PayInvoice';
import AppLogin from './AppLogin';
import LinkExpirePage from './LinkExpire';
import MobileIntro from './MobileIntro';
import RequestAppAccess from './RequestAppAccess';
import ValidationAccount from './ValidationAccount';
import Waitlist from './Waitlist';

import Config from '@/config';
import Agency from '@/routes/Agency';
import AgencyCustomerAccountDetail from '@/routes/Agency/Account';
import AgencyChat from '@/routes/Agency/Chat/Chat';
import AgencyContacts from '@/routes/Agency/Contacts';
import AgencyCreateGigOrder from '@/routes/Agency/CreateGigOrder';
import AgencyCustomerDetail from '@/routes/Agency/Customer';
import AgencyCustomerAdminDetail from '@/routes/Agency/CustomerAdmin';
import AgencyCustomers from '@/routes/Agency/Customers';
import AgencyDashboard from '@/routes/Agency/Dashboard';
import InvoiceGenerator from '@/routes/Agency/InvoiceGenerator';
import Invoices from '@/routes/Agency/Invoices';
import InvoiceDetail from '@/routes/Agency/Invoices/InvoiceDetailsRevamp';
import InvoicePay from '@/routes/Agency/Invoices/InvoicePay';
import AgencyOrderJobDetail from '@/routes/Agency/Job';
import JobMonitor from '@/routes/Agency/JobMonitor';
import AgencyOrderJobDetailRevamp from '@/routes/Agency/JobOld';
import MyTeam from '@/routes/Agency/MyTeam';
import AgencyOrderDetail from '@/routes/Agency/Order';
import AgencyOrders from '@/routes/Agency/Orders';
import AgencyPayrolls from '@/routes/Agency/Payrolls';
import AgencyPayrollDetail from '@/routes/Agency/Payrolls/PayrollDetail';
import AgencyReports from '@/routes/Agency/Reports';
import RestrictedAccess from '@/routes/Agency/RestrictedAccess';
import AgencyWorkerDetail from '@/routes/Agency/Worker';
import AgencyWorkers from '@/routes/Agency/Workers';
import Authenticated from '@/routes/Authenticated';
import ForgotPassword from '@/routes/ForgotPassword';
import Login from '@/routes/Login';
import PrivateRoute from '@/routes/PrivateRoute';
import ResetPassword from '@/routes/ResetPassword';
import Root from '@/routes/Root';
import SignUp from '@/routes/SignUp';
import Signout from '@/routes/Signout';
import ROUTES from '@/routes/routes';
import { UserRoleEnum } from '@/types/graphql';
import NotificationHub from './Agency/NotificationHub';

const privateRoutes: PartialRouteObject[] = [
  {
    path: '*',
    element: <Navigate to={ROUTES.basePath} />,
  },
  {
    path: ROUTES.basePath, // "/"
    element: (
      <PrivateRoute
        element={<AgencyDashboard />}
        redirectTo={ROUTES.orders}
        requiredRoles={[
          UserRoleEnum.TENANT_ADMIN,
          UserRoleEnum.HELPDESK_ADMIN,
          UserRoleEnum.CUSTOMER_ADMIN,
        ]}
      />
    ),
  },
  {
    path: ROUTES.inappchat,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            element={<InappChat />}
            redirectTo="/restricted-access"
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.HELPDESK_ADMIN,
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.orders,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            element={<AgencyOrders />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.HELPDESK_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ]}
          />
        ),
      },
      {
        path: ROUTES.gigOrderCreate,
        element: (
          <PrivateRoute
            element={<AgencyCreateGigOrder />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ]}
          />
        ),
      },
      {
        path: ROUTES.orderDetail,
        element: (
          <PrivateRoute
            element={<AgencyOrderDetail />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ]}
          />
        ),
      },
      {
        path: ROUTES.orderJobDetail,
        element: (
          <PrivateRoute
            element={<AgencyOrderJobDetail />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.HELPDESK_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ]}
          />
        ),
      },
      {
        path: ROUTES.orderJobDetailOld,
        element: (
          <PrivateRoute
            element={<AgencyOrderJobDetailRevamp />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.HELPDESK_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.clients,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            element={<AgencyCustomers />}
            requiredRoles={[UserRoleEnum.TENANT_ADMIN]}
          />
        ),
      },
      {
        path: ROUTES.customerDetail,
        element: (
          <PrivateRoute
            element={<AgencyCustomerDetail />}
            requiredRoles={[UserRoleEnum.TENANT_ADMIN]}
          />
        ),
      },
      {
        path: ROUTES.customerAccountDetail,
        element: (
          <PrivateRoute
            element={<AgencyCustomerAccountDetail />}
            requiredRoles={[UserRoleEnum.TENANT_ADMIN]}
          />
        ),
      },
      {
        path: ROUTES.customerAdminDetail,
        element: (
          <PrivateRoute
            element={<AgencyCustomerAdminDetail />}
            requiredRoles={[UserRoleEnum.TENANT_ADMIN]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.contacts,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            element={<AgencyContacts />}
            requiredRoles={[UserRoleEnum.TENANT_ADMIN]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.invoices,
    children: [
      {
        path: ROUTES.basePath,
        element: (
          <PrivateRoute
            element={<Invoices />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ]}
          />
        ),
      },
      {
        path: ROUTES.invoicesDetail,
        element: (
          <PrivateRoute
            element={<InvoiceDetail />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ]}
          />
        ),
      },
      {
        path: ROUTES.invoicesPay,
        element: (
          <PrivateRoute
            element={<InvoicePay />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.workers,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            element={<AgencyWorkers />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.HELPDESK_ADMIN,
            ]}
          />
        ),
      },
      {
        path: ROUTES.workerDetail,
        element: (
          <PrivateRoute
            element={<AgencyWorkerDetail />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.HELPDESK_ADMIN,
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.myTeam,
    children: [
      {
        path: ROUTES.basePath,
        element: (
          <PrivateRoute
            element={<MyTeam />}
            requiredRoles={[UserRoleEnum.CUSTOMER_ADMIN]}
          />
        ),
      },
      {
        path: ROUTES.myTeamAdminDetail,
        element: (
          <PrivateRoute
            element={<AgencyCustomerAdminDetail />}
            requiredRoles={[UserRoleEnum.CUSTOMER_ADMIN]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.myCompany,
    children: [
      {
        path: ROUTES.basePath,
        element: (
          <PrivateRoute
            element={<AgencyCustomerDetail />}
            requiredRoles={[UserRoleEnum.CUSTOMER_ADMIN]}
          />
        ),
      },
      {
        path: ROUTES.myCompanyAccountDetail,
        element: (
          <PrivateRoute
            element={<AgencyCustomerAccountDetail />}
            requiredRoles={[UserRoleEnum.CUSTOMER_ADMIN]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.chat,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            element={<AgencyChat />}
            requiredRoles={[
              UserRoleEnum.TENANT_ADMIN,
              UserRoleEnum.HELPDESK_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.myProfile,
    element: (
      <PrivateRoute
        element={<AgencyCustomerAdminDetail />}
        requiredRoles={[UserRoleEnum.CUSTOMER_ADMIN]}
      />
    ),
  },
  {
    path: ROUTES.payrolls,
    children: [
      {
        path: ROUTES.basePath,
        element: (
          <PrivateRoute
            element={<AgencyPayrolls />}
            requiredRoles={[UserRoleEnum.TENANT_ADMIN]}
          />
        ),
      },
      {
        path: ROUTES.payrollDetail,
        element: (
          <PrivateRoute
            element={<AgencyPayrollDetail />}
            requiredRoles={[UserRoleEnum.TENANT_ADMIN]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.reports,
    children: [
      {
        path: ROUTES.basePath,
        element: (
          <PrivateRoute
            element={<AgencyReports />}
            requiredRoles={[UserRoleEnum.TENANT_ADMIN]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.jobmonitor,
    element: (
      <PrivateRoute
        element={<JobMonitor />}
        requiredRoles={[UserRoleEnum.TENANT_ADMIN, UserRoleEnum.HELPDESK_ADMIN]}
      />
    ),
  },
  {
    path: ROUTES.invoiceGenerator,
    element: (
      <PrivateRoute
        element={<InvoiceGenerator />}
        requiredRoles={[UserRoleEnum.TENANT_ADMIN]}
      />
    ),
  },
  {
    path: ROUTES.notificationHub,
    element: (
      <PrivateRoute
        element={<NotificationHub />}
        requiredRoles={[UserRoleEnum.TENANT_ADMIN]}
      />
    ),
  },
  {
    path: ROUTES.restrictedAccess,
    element: (
      <PrivateRoute
        element={<RestrictedAccess />}
        requiredRoles={[
          UserRoleEnum.TENANT_ADMIN,
          UserRoleEnum.HELPDESK_ADMIN,
          UserRoleEnum.CUSTOMER_ADMIN,
        ]}
      />
    ),
  },
];

const publicRoutes: PartialRouteObject[] = [
  { path: ROUTES.mobileIntro, element: <MobileIntro /> },
  { path: ROUTES.appLogin, element: <AppLogin /> },
  { path: ROUTES.requestAppAccess, element: <RequestAppAccess /> },
  { path: ROUTES.waitlist, element: <Waitlist /> },

  { path: ROUTES.login, element: <Login /> },
  { path: ROUTES.signUp, element: <SignUp /> },
  { path: ROUTES.signOut, element: <Signout /> },
  { path: ROUTES.forgotPassword, element: <ForgotPassword /> },
  { path: ROUTES.resetPassword, element: <ResetPassword /> },
  { path: ROUTES.validationAccount, element: <ValidationAccount /> },
  { path: ROUTES.linkExpire, element: <LinkExpirePage /> },
  { path: ROUTES.payInvoice, element: <PayInvoice /> },
];

const routes: PartialRouteObject[] = [
  {
    path: Config.BASEPATH,
    children: [
      {
        element: <Root />,
        children: [
          {
            element: <Authenticated />,
            children: [{ element: <Agency />, children: [...privateRoutes] }],
          },
          { children: [...publicRoutes] },
        ],
      },
    ],
  },
];

const AppRouter = () => {
  const routing = useRoutes(routes);

  return routing;
};

export default AppRouter;
