import styled from '@/styles';
import { vh } from '@/util/dimensions';
import {} from '@/styles';
import { keyframes } from '@emotion/react';

export const Container = styled('div', {
  position: 'fixed',
  top: '50px',
  right: '50px',
  width: '25%',
  backgroundColor: 'white',
  zIndex: '1000',
  borderRadius: '4px',
  background: '#FFF',
  boxShadow: '0px 4.05px 4.05px 0px rgba(0, 0, 0, 0.05)',
  maxHeight: vh * 0.5,
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
});

export const ContainerBottomSheet = styled('div', {
  position: 'fixed',
  bottom: '0',
  left: '0',
  width: '100%',
  backgroundColor: 'white',
  zIndex: '9999',
  borderRadius: '16px 16px 0 0',
  background: '#FFF',
  boxShadow: '0px 4.05px 4.05px 0px rgba(0, 0, 0, 0.05)',
  height: vh * 0.7,
  overflowY: 'scroll',
});
const slideDown = keyframes({
  from: { transform: 'translateY(0)' },
  to: { transform: 'translateY(100%)' },
});

export const ContainerBottomSheetAnimated = styled(ContainerBottomSheet, {
  '&.closing': {
    animation: `${slideDown} 0.3s forwards`,
  },
});

export const Header = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  '@media (max-width: 559px)': {
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderBottom: '1px solid #F2F4F7',
  },
});

export const HeaderTitle = styled('p', {
  color: '#1A1918',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '22px',
  '@media (max-width: 559px)': {
    color: '#1E293B',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '150%' /* 30px */,
  },
});

export const RightIconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
});

export const Subtitle = styled('p', {
  color: '#37352F',
  fontSize: '12.5px',
  fontWeight: '400',
  lineHeight: '16px' /* 128% */,
  cursor: 'pointer',
});

export const CenterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
});

export const Divider = styled('div', {
  height: '1px',
  backgroundColor: '#E5E5E5',
  width: '100%',
});
